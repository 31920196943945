<template>
    <div class="rates-main-wrapper" :key="'rates-main-wrapper' + mobileView">
      <div class="rates-wrapper m-b-20" v-for="(rate, rateIndex) in getDistinctRateCodes" :key="rateIndex">
        <div class="flexed p-20" >
          <div class="flex_1 title" >
            <div class="flexed">
              <h4 class="m-t-0 m-b-10">{{ rate.display_code ? translateValues(objectCode(rate), rate.display_code, objectType(rate), objectId(rate), 'display_code')  : translateValues(objectCode(rate), rate.name, objectType(rate), objectId(rate), 'name') }}</h4>
              <i v-if="!rate.package_description && !rate.additional_details" class="el-icon-info font-large m-l-5" @click="openRateModal(rate)" ></i>
            </div>

            <div class="flexed rate-desc" :class="mobileView ? 'justify-end' : 'justify-between'">


              <div>
                <div class="flexed w-100">
                  <div v-if="!mobileView" style="width: 85%; word-break: break-all; " class="flexed align-center">
                    <div style="height: 80px;">
                      <div v-if="rate.package_description" style="margin-bottom: 20px;" v-html="renderMarkdown(rate.package_description?.length > 160 ? `${translateValues(objectCode(rate), rate.package_description, objectType(rate), objectId(rate), 'description')?.slice(0, 159)}...` : translateValues(objectCode(rate), rate.package_description, objectType(rate), objectId(rate), 'description'))"></div>
                      <div v-else v-html="renderMarkdown(rate.additional_details?.length > 160 ? `${translateValues(objectCode(rate), rate.additional_details, objectType(rate), objectId(rate), 'additional_details')?.slice(0, 159)}...` : translateValues(objectCode(rate), rate.additional_details, objectType(rate), objectId(rate), 'additional_details'))"></div>
                    </div>
                    <!-- <div v-else>
                      <div v-if="rate.package_description" style="margin-bottom: 20px;" v-html="renderMarkdown(rate.package_description)"></div>
                      <div v-html="renderMarkdown(rate.additional_details)"></div>
                    </div> -->
                    <i v-if="rate.package_description || rate.additional_details" class="el-icon-info font-large" @click="openRateModal(rate)" ></i>
                  </div>
                </div>
                <!-- <span class="show-more pointer" @click.stop="toggleShowMore(rate)">{{ rate.showMore ? 'Show Less' : 'Show More' }}</span> -->
              </div>

              <div class="lowest-rate w-40">
                <span class="small" style="display: block; text-align: end;">{{ getRoomsByRate(rate).length }} {{ getRoomsByRate(rate).length === 1 ? `${$t('lbl_room')}` : `${$t('lbl_rooms')}` }} {{ $t("lbl_available_starting_from") }}</span>
                <div class="flexed justify-end">
                  <!-- <span class="sub-title" v-if="mobileView"> {{ room.room_type_name }}  </span> -->
                  <div v-if="ifRateCodeParamIsGoogle" class="flexed-column justify-end text-right" style="width: 145px;" :class="{'justify-end': mobileView}">
                    <div class="price sub-title">{{ findLowestRoomRate(rate).total_amount_after_tax | currency  }}</div>
                    <div class="small-font">{{ $t("lbl_including_taxes_and_fees") }}</div>
                  </div>
                  <div v-else-if="findLowestRoomRate(rate).total_points && enable_book_with_points" class="flexed-column justify-end text-right" style="width: 135px;" :class="{'justify-end': mobileView}">
                    <div class="price sub-title">
                      {{ findLowestRoomRate(rate).total_points | formatBigNumber}} {{ $t("lbl_points") }}
                    </div>
                  </div>

                  <!-- Discount lowest -->
                  <div v-else class="flexed-column m-t-5" style="width: 145px;">
                    <div v-if="discount_applied" class="flexed align-center text-right"
                         :class="{'justify-end': mobileView}">
                      <div class="discount-price  m-r-5" :class="{'flex_1': !mobileView}">
                        <div v-if="findLowestRoomRate(rate).total_inclusive_tax_amount === 0">{{findLowestRoomRate(rate).conversion_total_amount_after_discount ? findLowestRoomRate(rate).conversion_total_amount_after_discount :  findLowestRoomRate(rate).avg_amount_before_tax | currency }}</div>
                        <div v-if="findLowestRoomRate(rate).total_exclusive_tax_amount === 0"> {{findLowestRoomRate(rate).conversion_total_net_amount_after_discount ? findLowestRoomRate(rate).conversion_total_net_amount_after_discount :  findLowestRoomRate(rate).avg_amount_after_tax | currency }}</div>   
                      </div>

                      <div>
                        <div class="price sub-title" v-if="findLowestRoomRate(rate).total_inclusive_tax_amount === 0">{{findLowestRoomRate(rate).conversion_total_amount_after_discount ? findLowestRoomRate(rate).conversion_total_amount_after_discount : findLowestRoomRate(rate).avg_amount_after_discount | currency }}</div>
                        <div class="price sub-title" v-if="findLowestRoomRate(rate).total_exclusive_tax_amount === 0"> {{findLowestRoomRate(rate).conversion_total_amount_after_discount ? findLowestRoomRate(rate).conversion_total_amount_after_discount : findLowestRoomRate(rate).avg_net_amount_after_discount | currency }}</div>   
                      </div>

                      <!-- <div class="price sub-title">
                        {{ findLowestRoomRate(rate).conversion_avg_net_amount_after_discount ? findLowestRoomRate(rate).conversion_avg_net_amount_after_discount : findLowestRoomRate(rate).avg_net_amount_after_discount | currency }}
                      </div> -->
                    </div>
                    <div v-else-if="findLowestRoomRate(rate).avg_inclusive_tax_amount === 0
                    || findLowestRoomRate(rate).avg_inclusive_tax_amount === null 
                    || !findLowestRoomRate(rate).avg_inclusive_tax_amount" class="sub-title flexed-end w-100 align-center">
  <!--                    {{  getRatePriceSummary(rate, room).avg_amount_before_tax | currency }}-->
                      {{ (findLowestRoomRate(rate).conversion_avg_amount_before_tax || findLowestRoomRate(rate).avg_amount_before_tax) | currency}}
                    </div>
                    <div v-else class="sub-title flexed-end w-100 align-center">
  <!--                    {{  getRatePriceSummary(rate, room).avg_amount_before_tax | currency }}-->
                      {{ (findLowestRoomRate(rate).conversion_avg_amount_after_tax_and_fee || findLowestRoomRate(rate).avg_amount_after_tax_and_fee) | currency}}
                    </div>
                    <div class="small-font text-right">{{ $t('lbl_per_night_avg')  }}</div>
                    <div class="small-font" v-if="findLowestRoomRate(rate).avg_inclusive_tax_amount === 0 || findLowestRoomRate(rate).avg_inclusive_tax_amount === null || !findLowestRoomRate(rate).avg_inclusive_tax_amount">{{ $t("lbl_excluding_taxes_and_fees") }}</div>
                    <div v-else class="small-font" style="text-align: right">{{ $t("lbl_including_taxes_and_fees") }}</div>
                  </div>
                </div>

                <div class="flexed justify-end">
                    <el-button :plain="rate.showRoomCodes" :type="!rate?.showRoomCodes ? 'primary' : 'primary'" @click.native="showRooms(rate)">{{
                        !rate?.showRoomCodes ? $t("lbl_view_prices") : $t("lbl_hide_prices") }}
                        <i
                            :class="{ 'el-icon-arrow-down': !rate?.showRoomCodes, 'el-icon-arrow-up': rate?.showRoomCodes }"></i>
                    </el-button>
                </div>

              </div>
            </div>

          <div v-if="applicablePackage && applicablePackage.id === rate.package_id" class="preferred-tag">{{ $t("lbl_preferred_package") }}</div>
          <!-- <div v-if="group_rate_plan && group_rate_plan === rate.id" class="m-l-10 preferred-tag">{{$t("lbl_preferred_group_rate_plan")}}</div> -->
          </div>
          <!-- <span class="flex_2 title" v-if="!mobileView">Available Rooms</span> -->

        </div>
        <!-- <div class=" title m-t-10" v-if="mobileView">Available Rooms</div> -->
        <div   class="rates-room-wrapper" v-if="rate.showRoomCodes">
          <div class="wrapper-item"
               v-for="(room, rIndex) in getRoomsByRate(rate)" :key="rIndex">

            <div class="carousel-container">
              <image-carousel :images="roomImages(room)" :additional_details="roomAdditionalDetails(room)" :roomIndex="rIndex" :room="room"/>
            </div>
            <div class=" flexed-column justify-between flex_2"
                 :class="[mobileView ? 'flexed-wrap' : 'flexed',mobileView ? 'gap-10' : 'gap-20']">
              <div class="flexed-column font-size-14 rate-info" :style="{width: !mobileView ? '100%' : '100%'}">
                <div class="sub-title flexed-wrap gap-10 align-center" v-if="!mobileView"> {{ translateValues(roomCode(room), room.room_type_name, 'ROOM_TYPES', room.room_type_id, 'name')}}
                  <span v-if="preferredRoom(room)"
                        class="preferred-tag">{{ $t("lbl_selected_room") }}</span>
                </div>
                <room-amenities class="amenities-mobile" :room="room"></room-amenities>
              <div class="desc m-b-10">
                  {{ description(room) }}
                  <i v-if="description(room)?.length > 65"  class="el-icon-info font-large" @click="openModal(room)"></i>

                </div>
                <div class="m-t-10" v-if="packageChargeTypes.length > 0">
                  <div class="flexed justify-between" v-for="(item, index) in packageChargeTypes" :key="index">
                    <div>{{ item.name }}</div>
                    <div>{{ packageCharges(item.charges) | currency }}</div>
                  </div>
                </div>
              </div>
              <div class="flexed-column" :class="mobileView ? 'w-100' : ''">

                <div class="flexed justify-end">
                  <span class="sub-title" v-if="mobileView"> {{ room.room_type_name }}  </span>
                  <div v-if="ifRateCodeParamIsGoogle" class="flexed-column justify-end text-right" style="width: 145px;" :class="{'justify-end': mobileView}">
                    <div class="price sub-title">{{ getRatePriceSummary(rate, room).total_amount_after_tax | currency  }}</div>
                    <div class="small-font">{{ $t("lbl_including_taxes_and_fees") }}</div>
                  </div>
                  <div v-else-if="getRatePriceSummary(rate, room).total_points && enable_book_with_points" class="flexed-column justify-end text-right" style="width: 135px;" :class="{'justify-end': mobileView}">
                    <div class="price sub-title">
                      {{ getRatePriceSummary(rate, room).total_points | formatBigNumber}} Points
                    </div>
                  </div>

                  <!-- Discount -->
                  <div v-else class="flexed-column" style="width: 145px;">
                    <div v-if="discount_applied" class="flexed align-center text-right"
                         :class="{'justify-end': mobileView}">
                      <div class="discount-price  m-r-5" :class="{'flex_1': !mobileView}">
                        {{ getRatePriceSummary(rate, room).conversion_total_amount_after_tax ? getRatePriceSummary(rate, room).conversion_total_amount_after_tax : getRatePriceSummary(rate, room).total_amount_after_tax | currency }}
                      </div>
                      <div class="price sub-title">
                        {{ getRatePriceSummary(rate, room).conversion_avg_net_amount_after_discount ? getRatePriceSummary(rate, room).conversion_avg_net_amount_after_discount : getRatePriceSummary(rate, room).avg_net_amount_after_discount | currency }}
                      </div>
                    </div>
                    <div v-else-if="getRatePriceSummary(rate, room).avg_inclusive_tax_amount === 0
                    || getRatePriceSummary(rate, room).avg_inclusive_tax_amount === null 
                    || !getRatePriceSummary(rate, room).avg_inclusive_tax_amount" class="sub-title flexed-end w-100 align-center">
  <!--                    {{  getRatePriceSummary(rate, room).avg_amount_before_tax | currency }}-->
                      {{ (getRatePriceSummary(rate, room).conversion_avg_amount_before_tax || getRatePriceSummary(rate, room).avg_amount_before_tax) | currency}}
                    </div>
                    <div v-else class="sub-title flexed-end w-100 align-center">
  <!--                    {{  getRatePriceSummary(rate, room).avg_amount_before_tax | currency }}-->
                      {{ (getRatePriceSummary(rate, room).conversion_avg_amount_after_tax_and_fee || getRatePriceSummary(rate, room).avg_amount_after_tax_and_fee) | currency}}
                    </div>
                    <div class="small-font text-right">{{ $t('lbl_per_night_avg')  }}</div>
                    <div class="small-font" v-if="getRatePriceSummary(rate, room).avg_inclusive_tax_amount === 0 || getRatePriceSummary(rate, room).avg_inclusive_tax_amount === null || !getRatePriceSummary(rate, room).avg_inclusive_tax_amount">{{ $t("lbl_excluding_taxes_and_fees") }}</div>
                    <div v-else class="small-font" style="text-align: right">{{ $t("lbl_including_taxes_and_fees") }}</div>
                  </div>
                </div>

                <div class="m-t-20" style="min-width: 148px">
                  <el-button
                    v-if="showRemoveBtn(room, rate, rIndex, rateIndex)  && !bookingInProgress"
                    type="danger"
                    plain
                    :style="mobileBtn"
                    style="width: 100%"
                    @click="removeRoom(room, rate, rIndex, rateIndex)"
                  >{{ $t("lbl_remove") }}
                  </el-button>
                  <el-button
                    v-else
                    type="primary"
                    :style="mobileBtn"
                    style="width: 100%"
                    @click="bookRoom(room, rate, rIndex, rateIndex, 'book_room')"
                    :disabled="bookingInProgress"
                    :loading="selectedData && selectedData.roomIndex === rIndex && selectedData.rateIndex === rateIndex && bookingInProgress && !booking_room_unit"
                  >{{ $t("lbl_book_room") }}
                  </el-button>
                  <template v-if="enable_room_unit_booking">
                    <div class="or-divider">
                      <span>{{ $t("lbl_or") }}</span>
                    </div>
                    <el-button
                      v-if="showRemoveBtn(room, rate, rIndex, rateIndex)  && !bookingInProgress"
                      type="danger"
                      plain
                      :style="mobileBtn"
                      style="width: 100%"
                      @click="removeRoom(room, rate, rIndex, rateIndex)"
                    >{{ $t("lbl_remove") }}
                    </el-button>
                    <el-button
                      v-else
                      type="primary"
                      :style="mobileBtn"
                      style="width: 100%"
                      @click="bookRoom(room, rate, rIndex, rateIndex, 'book_room_unit')"
                      :disabled="bookingInProgress"
                      :loading="selectedData && selectedData.roomIndex === rIndex && selectedData.rateIndex === rateIndex && bookingInProgress && booking_room_unit"
                    >{{ $t("lbl_book_room_unit") }}
                    </el-button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal
        v-if="dialogVisible"
        size="45%"
        @close="dialogVisible = false"
        >
        <div slot="modal-title"> <h4 class="m-t-0 m-b-0">{{ roomData.roomName }}</h4> </div>
        <div slot="content">

            <div v-if="!mobileView" style="padding-top: 6px; font-weight: 400" class="description">
                <div >
                    <div>{{ roomData.description }}</div>
                </div>
            </div>
        </div>
        <div slot="modal-footer">
            <el-button type="danger" @click="dialogVisible = false">{{ $t("lbl_close") }}</el-button>
        </div>
    </modal>
      <modal
        v-if="rateDialogVisible"
        size="45%"
        @close="rateDialogVisible = false"
        style="margin-bottom: 50px;"
        >
        <div slot="modal-title"> <h3 class="m-t-0 m-b-0">{{rateModalData.name}}</h3> </div>
        <div slot="content">
          <price-details-popup :rateModalData="rateModalData"></price-details-popup>
        </div>
        <div slot="modal-footer">
            <el-button type="danger" @click="rateDialogVisible = false">{{ $t("lbl_close") }}</el-button>
        </div>
    </modal>
      <BookRoomUnit v-if="showRoomUnitModal" @close="closeRoomUnitModal" :selectedRoomPayload="selectedRoomPayload"/>
    </div>
  </template>

  <script>
  import {mapMutations, mapState} from 'vuex'
  import { InfoIcon } from "vue-feather-icons";
  import CommonUtils from "@/utils";
  import BookRoomUnit from "./BookRoomUnit.vue";
  import ImageCarousel from '../component/ImageCarousel.vue';
  import RoomAmenities from "@/views/booking-v2/component/RoomAmenities.vue";
  import {marked} from 'marked'
  import Modal from "@/components/Modal.vue";
  import dayJs from "dayjs";
  import PriceDetailsPopup from './PriceDetailsPopup.vue';
  import { translateDynamicContent } from "@/services/utilities.service";





  export default {
    name: "Rates",
    props: ["getFilteredRooms", "roomTypes", "packageChargeTypes", "roomIndex", 'selectedData', 'bookingInProgress'],
    components: { InfoIcon, BookRoomUnit, ImageCarousel, RoomAmenities, Modal, PriceDetailsPopup},
    data() {
      return {
        rooms: [],
        descMaxLength: 202,
        showRoomUnitModal: false,
        selectedRoomPayload: {},
        booking_room_unit: false,
        dialogVisible: false,
        rateDialogVisible: false,
        roomData: null,
        ratesClickedForBooking: [],
        rateModalData: null
      }
    },
    computed: {
      ...mapState({
        mobileView: state => state.mobileView,
        sessions: state => state.profile.sessions,
        discount_applied: state => state.discount_applied,
        selected_room_details: state => state.selected_room_details,
        rate_codes: state => state.rate_codes,
        allFilters: state => state.allFilters,
        applicablePackage: state => state.applicablePackage,
        rwa_data: (state) => state.rwa_data,
        enable_book_with_points: state => state.enable_book_with_points,
        enable_room_unit_booking: state => state.profile.restrictions.booking_profile.allow_room_unit_booking,
        clicked_rates: state => state.clicked_rates,
        property_currency: state => state.property.property_currency,
        property: state => state.property.details,
        packages: state => state.packages,
        multi_lingual_dynamic_fields: state => state.multi_lingual_dynamic_fields,
        property_language_code: state => state.property.details.language
      }),
      ifRateCodeParamIsGoogle(){
        return this.$route.query?.rate_code === 'GOOGLE'
      },
      group_rate_plan() {
        return this.rwa_data?.group_block?.group_rate_plan;
      },
      mobileBtn() {
        return this.mobileView ? {'height': '50px'} : ''
      },
      getDistinctRateCodes(){
        let result = []
        this.getFilteredRooms.forEach(rooms => {
          rooms?.rate_codes?.forEach(rateCode => {
            if(rateCode.hasOwnProperty('package_id')){
              rateCode.package_description = `${(this.packages[this.roomIndex]?.find(pack => pack.id === rateCode.package_id).description || '')}`
              rateCode.additional_details = `${(this.rate_codes[this.roomIndex]?.find(rate => rate.id === rateCode.id).additional_details || '')}`
              rateCode.package_code = this.packages?.[this.roomIndex]?.find(pack => pack.id == rateCode.package_id)?.code

              if(!result?.some(r=>r.package_id === rateCode.package_id && r.id === rateCode.id)){
                result.push(rateCode)
                console.log({rateCode})

              }
            } else{
              if(!result?.some(r=>r.id === rateCode.id && !r.hasOwnProperty('package_id'))){
                let rate = this.getRateCode(rateCode.id);
                if (rate) {
                  let selectedRate = this.clicked_rates?.find(r => r.id == rate.id)
                    result.push({...rate, type: 'rateCode', showRoomCodes: selectedRate?.showRoomCodes || false});
                }

              }
            }
          });
        });
        this.getRateCode(result)
        return result
      }
    },
    beforeMount(){
    this.clicked_rates?.forEach(rate => {
      if(rate?.clicked) {
       rate.showRoomCodes = true
      }
  });
    },
    methods: {
      ...mapMutations({
        SET_CLICKED_RATES: 'SET_CLICKED_RATES'
      }),
      openRateModal(rate){
        this.rateDialogVisible = true
        let room = null
        if (rate.package_id)
          room =  this.getFilteredRooms.find(r => r?.rate_codes?.some(rc => rc.id === rate.id && rc.package_id === rate.package_id))
        else
          room = this.getFilteredRooms.find(r => r?.rate_codes?.some(rc => rc.id === rate.id && !rc.package_id))

        let rateDetails = room.rate_codes?.find(r_code => r_code.id === rate.id )
        let cancellation_policy = this.rwa_data?.cancellation_policies?.find(item => item?.id === rate?.cancellation_policy_id)


        rateDetails.additional_details = this.translateValues(this.objectCode(rate), rate?.additional_details, this.objectType(rate), this.objectId(rate), 'additional_details') 
        rateDetails.package_description = this.translateValues(this.objectCode(rate), rate?.package_description, this.objectType(rate), this.objectId(rate), 'description') 
        rateDetails.name = this.translateValues(this.objectCode(rate), rate?.display_code, this.objectType(rate), this.objectId(rate), 'display_code')  || this.translateValues(this.objectCode(rate), rate?.name, this.objectType(rate), this.objectId(rate), 'name'),
        rateDetails.cancellation_policy =  this.translateValues(cancellation_policy?.code, cancellation_policy?.description, 'CANCELLATION_POLICY', cancellation_policy?.id, 'description'),


        this.rateModalData = rateDetails
      },
      closePopover(id) {
        // console.log(document.getElementsByClassName("el-popper"))
        //   var divsToHide = document.getElementsByClassName("el-popper"); //divsToHide is an array
        // for(var i = 0; i < divsToHide.length; i++){
        //   console.log(divsToHide[i].style.display)
        //   //  if (divsToHide[i].style.display != 'none') divsToHide[i].style.display = "none";
        // }
        // console.log(document.getElementById(id))
      },
      getRateCode(id) {

        if(this.rate_codes[this.roomIndex]?.find(rt => rt.id == id)) return this.rate_codes[this.roomIndex]?.find(rt => rt.id == id)
        else  return null
      },
      getRatePriceSummary(rate, room) {
        if(rate.hasOwnProperty('package_id'))
          return room.rate_codes.find(rt => rt.package_id === rate.package_id && rt.id == rate.id).price_summary
        else
          return room.rate_codes.find(rt => rt.id === rate.id && !rt.package_id).price_summary
      },
      description(room) {
        let description = this.translateValues(this.roomDetails(room).code, this.roomDetails(room)?.description, 'ROOM_TYPES', this.roomDetails(room)?.id, 'description') 
        return description && description.length > 65 ? description.slice(0, 64).concat("...") : description
      },
      roomCode(room) {
        return this.roomDetails(room)?.code
      },
      showRemoveBtn(room, rate) {
        let currentRoom = this.selected_room_details.find(room => room.room_no === this.roomIndex)
        // return currentRoom?.room_type_id === room.room_type_id && currentRoom?.rate_code_id === rate.id
        return currentRoom?.hasOwnProperty('package_id') ?
          currentRoom?.room_type_id === room.room_type_id && currentRoom?.rate_code_id === rate.id && currentRoom.package_id === rate?.package_id :
          currentRoom?.room_type_id === room.room_type_id &&
          currentRoom?.rate_code_id === rate.id &&
          !rate.hasOwnProperty('package_id')
      },
      preferredRoom(room) {
        if (this.$route.query.hasOwnProperty('rn_code')) {
          return this.$route.query.rn_code === this.roomDetails(room).code
        }
      },
      removeRoom(room) {
        this.$emit('removeRoom', room)
      },
      bookRoom(room, rate, roomIndex, rateIndex, source) {
        if(source === "book_room_unit" && this.enable_room_unit_booking) {
          this.booking_room_unit = true // required for the loader

          this.selectedRoomPayload = {room, rate, activeTab: this.roomIndex, rateIndex}
          this.showRoomUnitModal = true
        }
        else {
          this.booking_room_unit = false // required for the loader
          if (!this.ratesClickedForBooking.includes(rate)) {
              this.ratesClickedForBooking.push({...rate, clicked: true});
              this.SET_CLICKED_RATES(this.ratesClickedForBooking)
            }
          this.goNext({room, rate, roomIndex, rateIndex})
        }
      },
      closeRoomUnitModal(payload){
        // if there was any room selected, its id will come as payload, otherwise we just close the modal
        if(payload){
          this.showRoomUnitModal = false
          this.selectedRoomPayload.room.room_id = payload.room_id
          this.selectedRoomPayload.room.room_unit_number = payload.room_unit_number
          this.goNext(this.selectedRoomPayload)
        }

        this.showRoomUnitModal = false
        this.selectedRoomPayload = {}
      },
      goNext({room, rate, roomIndex, rateIndex, room_id}){
        let findRoomRate = {}
        if (rate.package_id)
          findRoomRate =  room.rate_codes.find(rc => rc.id === rate.id && rc.package_id === rate.package_id)
        else
          findRoomRate = room.rate_codes.find(rc => rc.id === rate.id && !rc.package_id)
        let args = {room, rate: findRoomRate, roomIndex, rateIndex}
        if(this.getRatePriceSummary(rate, room).total_points && this.enable_book_with_points) {
          let points = this.getRatePriceSummary(rate, room).total_points
          args = {room, rate: findRoomRate, roomIndex, rateIndex, points}
        }
        if(room_id){
          args.room_id = room_id
        }
        this.$emit('bookRoom', args)
      },
      packageCharges(charges) {
        return charges.reduce((b, a) => b + a.net_value_excl_taxes, 0);
      },
      roomDetails(item) {
        // return this.roomTypes[this.roomIndex].find(room => room.id === item.room_type_id)
        if (item.hasOwnProperty('package_id')) {
          return item
        }
        return this.roomTypes[this.roomIndex]?.find(room => room.id === item.room_type_id)
      },
      roomImages(room){
        let room_details = this.roomDetails(room)
        if(!room_details)
          return
        let arr =  [room_details.cover_photo, ...room_details.images.map(i => i.url)]
        return arr
      },
      getRoomsByRate(rate) {
        let result = []
        if (rate.package_id)
          result =  this.getFilteredRooms.filter(r => r?.rate_codes?.some(rc => rc.id === rate.id && rc.package_id === rate.package_id))
        else
          result = this.getFilteredRooms.filter(r => r?.rate_codes?.some(rc => rc.id === rate.id && !rc.package_id))

        let sortByPrice = this.allFilters[this.roomIndex]?.sortByPrice
        return CommonUtils.sortFunction(result, sortByPrice, "highest_rate");
        // return result
      },
      showRooms(rate) {
        rate.showRoomCodes = !rate.showRoomCodes
        this.$forceUpdate();
      },
      renderMarkdown(markedText) {
        if (markedText) {
          return marked(markedText, {sanitize: true})
        }
        return '';
      },
      findLowestRoomRate(rate) {
        let mappedRooms = this.getRoomsByRate(rate)?.map(room => {
          if (rate.hasOwnProperty('package_id'))
            return room?.rate_codes?.find(rt => rt.package_id === rate.package_id && rt.id == rate.id)
          else
            return room?.rate_codes?.find(rt => rt.id === rate.id && !rt.package_id)
        })
        let lowestRate = mappedRooms?.find(rate => {
          return Math.min(rate.rate_amount)
        })
        return lowestRate?.price_summary
      },
      openModal(room) {
            this.dialogVisible = true
            this.roomData =  {
                roomName: this.translateValues(this.roomDetails(room)?.code, this.roomDetails(room)?.name, 'ROOM_TYPES', this.roomDetails(room)?.id, 'name'),
                description: this.translateValues(this.roomDetails(room)?.code, this.roomDetails(room)?.description, 'ROOM_TYPES',this.roomDetails(room)?.id, 'description')
            }
        },
        rateCodeDetails(item) {
            if (item.hasOwnProperty('package_id')) {
                item.package_description = `${(this.packages[this.roomIndex]?.find(pack => pack.id === item.package_id).description || '')}`
                item.additional_details = `${(this.rateCodes[this.roomIndex]?.find(rate => rate.id === item.id).additional_details || '')}`
                return item
            }
            return this.rateCodes[this.roomIndex]?.find(rate => rate.id === item.id)
        },
        objectCode(rate) {   
            if(rate.package_code) {
                return rate.package_code
            }
            return rate?.code
        },
        objectType(rate) {   
            if(rate.package_code) {
                return 'PACKAGE_RATES'
            }
            return 'RATE_CODES'
        },
        objectId(rate) {
            if(rate?.package_code) {
                return rate?.package_id
            }
            return rate?.id
        },
        roomAdditionalDetails(room) {
          return this.translateValues(this.roomDetails(room)?.code, this.roomDetails(room)?.additional_details, 'ROOM_TYPES', this.roomDetails(room)?.id, 'additional_details' ) 
        },
        translateValues(object_code, value, object_type,object_id, field_name) {
           return translateDynamicContent(this.multi_lingual_dynamic_fields, object_code, value, object_type, this.property_language_code,object_id, field_name)
        }
        // toggleShowMore(rate) {
        //   if (!('showMore' in rate)) {
        //       this.$set(rate, 'showMore', true);
        //   }
        //   rate.showMore = !rate.showMore;
        //   this.$forceUpdate();
        // },

  }
}
  </script>

  <style lang="scss" scoped>
  @import "@/assets/sass/config/mixins";
  @import "@/assets/sass/config/variables";


  .show-more{
    color: $hk-primary-color;
    font-size: 14px;
  }

  :deep(.rate-desc ul) {
  margin-left: 30px !important;
  list-style: disc !important;
}

:deep(.list-desc ul) {
    margin-bottom: 20px;
    margin-left: 30px !important;
  list-style: disc !important;
}

:deep(.description p) {
 margin-top: 0px;
}
  .discount-price {
    font-size: 10px;
    margin-right: 10px;
    text-decoration: line-through red;
  }
  .wrapper-item{
    display: flex;
    flex-direction: column;
    border: solid #dadada 1px;
    border-radius: 5px;
    padding: 10px 20px 20px 20px;


  }
  .or-divider{
    display: flex;
    justify-content: space-around;
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .desc {
    padding: 0 2px;
    // width: 90%;
    // text-align: justify;
    // overflow: auto;
    // max-height: 150px;
    font-size: 14px;
    max-width: 400px;
    // overflow-y: auto;
    overflow-wrap: break-word;
  }

  .amenities-mobile {
      margin-top: 0px;
  }
  .rates-main-wrapper {
    // border: solid #dadada 1px;
    border-radius: 5px;
    padding: 16px;
    margin-top: 10px;
    padding: 10px;




    .rates-wrapper {
      border: solid #dadada 1px;
      border-radius: 5px;
      // padding: 16px;
      .rates-room-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        // border: solid #dadada 1px;
        border-radius: 5px;
        padding: 16px;
        margin-top: 10px;
      }

      .title {
        font-size: 16px;
        // font-weight: bold;
        color: #4E4E4E;
        .el-icon-info{
          font-size: 20px !important;
        }
      }

      .sub-title {
        font-size: 16px;
        font-weight: bold;
        color: #4E4E4E;
      }

      .small-font {
        font-size: 12px;
        font-weight: 400;
      }

      .carousel-container{
        width: 100%;
        height: 140px;
        margin-bottom: 20px;
      }
    }
  }
  @include media('<=desktop') {
    .discount-price {
      font-size: 10px;
      margin-right: 10px;
      text-decoration: line-through red;
    }

    .amenities-mobile {
      margin-top: 40px;
    }
    .wrapper-item{
      flex-direction: column;
      border: solid #dadada 1px;
      border-radius: 5px;
      padding: 10px;

    }

     .carousel-container{
        width: 100% !important;
        height: 140px;
        margin-bottom: 40px;
      }

      .desc {
        margin-bottom: 0px !important;

        max-height: 150px;
        margin-top: 10px;
      }

      .rates-main-wrapper {
        width: 78% !important;
        margin: 0 auto;
        margin-top: 10px;
      }

  }
  .preferred-tag {
    background-color: $hk-blue;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 11px;
    font-weight: normal;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

:deep(.el-carousel) {
        width: 100% !important;
     }
.rate-info {
  height: 120px;
  // overflow: auto;
}

.description {
  max-height: 250px;
  // font-size: 14px;
  overflow: auto;
}

@include media('<=600px') {

  .rates-main-wrapper .rates-wrapper .rates-room-wrapper {
    display: grid;
    grid-template-columns: 1fr !important;

  }

}
  </style>
