<template>
    <div class="select-dates flexed-column gap-10 align-center">
        <div class="filters">
            <div class="date-filter">
                <DateFilter :searchData="searchData" @onDateSelected="onDateSelected"/>
            </div>
            <div class="rooms">
                <RoomCountPicker @selectRoomCount="selectRoomCount"/>
            </div>
            <div class="guests">
                <GuestsFilter :searchData="searchData" @guestsChanged="guestsChanged" :key="guestKey" />
            </div>
        </div>
        <div class="flexed justify-end w-100 m-t-20">
            <el-button :loading="btnLoading" type="primary">
                {{ $t("lbl_search") }}
            </el-button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { mapState } from 'vuex';
import GuestsFilter from "../component/filters/GuestsFilter";
import RoomCountPicker from "../component/filters/RoomCountPicker";
import DateFilter from "../component/filters/date-filter";
    export default {
        name: 'SelectDates',
        components: {
            GuestsFilter, RoomCountPicker, DateFilter
        },
        data() {
            return {
                searchData: {
                    rooms: [
                    {
                        persons: {adults: 1, children: 0},
                        checkIn: moment().toDate(),
                        checkOut: moment().add(1, 'd').toDate()
                    },
                    ],
                    checkIn: moment().toDate(),
                    checkOut: moment().add(1, 'd').toDate()
                },
                guestKey: 0
            }
        },
        ...mapState({
            mobileView: state => state.mobileView,
            business_date: state => state.abs.property.business_day.date.split('T')[0],
        }),
        created () {
            this.searchData.checkIn = moment(this.business_date).toDate()
            this.searchData.checkOut = moment(this.business_date).add(1, 'd').toDate()
        },
        methods: {
            onDateSelected() {
                
            },
            guestsChanged() {

            }, 
            selectRoomCount(){

            }
        },
    }
</script>

<style lang="scss" scoped>
.select-dates {
    width: 70%;
    margin: 0 auto;
    .filters {
        width: 100%;
        border: solid #dadada 1px;
        border-radius: 5px;
        padding: 10px;
        
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      
        .date-filter {
          flex: 3;
        }
      
        .rooms {
          flex: 1;
          // margin-left: 10px;
        }
      
        .guests {
          flex: 2;
        }
      }
}


</style>