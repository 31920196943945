import {isBefore, isEqual} from 'date-fns';
import * as CryptoJS from 'crypto-js';

export default class CommonUtils {
  static orderMyImages(imagesArr) {
    return imagesArr.sort((a, b) => {
      let a_created = a.created_at.slice(0, 10);
      let b_created = b.created_at.slice(0, 10);

      if (isEqual(a_created, b_created)) {
        return 0;
      } else {
        isBefore(a_created, b_created) ? -1 : 1;
      }
    });
  }

  static getCookie(name) {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=')
      return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
  }

  static hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
  }

  static sortFunction(arrayToSort, sortType, sortBy) {
    return arrayToSort.sort(function (a, b) {
      if (a[sortBy] < b[sortBy]) {
        return sortType === 'asc' ? -1 : 1;
      }
      if (a[sortBy] > b[sortBy]) {
        return sortType === 'asc' ? 1 : -1;
      }
      return 0;

    })
  }

  static isNumber($event) {
    let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

    // only allow number
    if (keyCode < 48 || keyCode > 57) {
      $event.preventDefault();
    }
  }

  static generateToken(secManager) {

    let SecurityManager = secManager;
    if (!SecurityManager.key) {
      throw 'Key not present';
    }

    let token, hash, timestamp, message, info, plain;

    timestamp = new Date().getTime()

    // Construct the hash body by concatenating the userAgent, username, app_id, app_version.
    message = [
      navigator.userAgent.replace(/:/g, ""),
      process.env.app_id,
      process.env.version,
      SecurityManager.username,
      timestamp,
    ].join(':');

    // Hash the message, using the key.
    hash = CryptoJS.HmacSHA256(message, SecurityManager.key);

    // Base64-encode the hash to get the resulting token.
    token = CryptoJS.enc.Base64.stringify(hash);
    plain = [
      navigator.userAgent.replace(/:/g, ""),
      process.env.app_id,
      process.env.version,
      SecurityManager.username,
      timestamp,
      '',
      '',
      '',
      '',
    ].join(':');

    info = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(plain));

    return {
      token: token,
      info: info,
      message: message,
      plain: plain,
      hashed_password: SecurityManager.key
    };
  }


static isInt (evt) {
  evt = (evt) ? evt : window.event;

  const keyCode = [0, 8, 9, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
  const charCode = evt.which;

  if (!keyCode.includes(charCode)) {
    evt.preventDefault();
  } else {
    return true;
  }
}
}
