<style lang="scss" scoped>
  @import '~css_vars';

  .error-box {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($black, .4);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .wrap-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: white;
      align-items: center;
      width: 90vw;
      max-width: 500px;
      height: 60vh;
      border-radius: 4px;
      overflow: hidden;
    }

    .sign {
      margin-top: auto;

      svg {
        stroke: $red;
        width: 80px;
        height: 80px;
      }
    }

    p {
      margin: 0px;
      margin-top: 20px;
    }

    .buttons {
      margin-top: auto;
      display: flex;
      border-top: solid 1px $grey;
      padding: .5rem 0px;
      width: 100%;
      justify-content: flex-end;

      svg {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }

      button {
        text-align: center;
        text-decoration: none;
        font-weight: 400;
        font-size: .8rem;
        padding: .5rem 1rem;
        border-radius: 4px;
        background-color: whitesmoke;

        &:hover {
          background-color: darken(whitesmoke, 10);
        }

        &:last-child {
          margin-right: 10px;
        }
      }
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    .error-message {
      margin-top: 10px;
      font-size: 12pt;
      font-family: Monaco, Consolas, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New;
      letter-spacing: 0;
      padding: 0px 20px;
      text-align: center;
    }
  }

</style>
<template>
  <div class="error-box">
    <div class="wrap-box">
      <span class="sign">
        <alert-octagon></alert-octagon>
      </span>
      <p v-if="api_fail.status !== '900'">Oops! {{ $t('lbl_something_wrong') }}.</p>
      <p class="error-message">
        <span v-if="api_fail.status !== '900'">{{ $t('lbl_error_code') }}: {{api_fail.status}}</span>
        <br> {{!!api_fail.message.data ? api_fail.message.data : $t('lbl_error_processing_data')}}
      </p>
      <div class="buttons">
        <a @click="revert_to()">
          <button type="button">
            <home></home>
            {{ $t('lbl_home') }}
          </button>
        </a>
<!--        <a @click="revert_to()" v-if="api_fail.status !== '900'">-->
<!--          <button type="button">-->
<!--            <rotate-cw></rotate-cw>-->
<!--            {{ $t('lbl_ok') }}-->
<!--          </button>-->
<!--        </a>-->
      </div>
    </div>
  </div>
</template>
<script>
  import {AlertOctagonIcon, HomeIcon} from 'vue-feather-icons';
  import {mapMutations, mapState} from 'vuex'

  export default {
    components: {
      AlertOctagon: AlertOctagonIcon,
      Home: HomeIcon
    },
    data: () => ({}),
    computed: {
      ...mapState({
        'origin': state => state.configs.origin
      }),
      api_fail() {
        return this.$store.state.api_fail;
      },
    },
    methods: {
      ...mapMutations({
        set_error: 'SET_ERROR'
      }),
      go_home() {
        if (this.api_fail.create_reservation) {
          window.location.reload();
        } else {
          if (this.$route.query.hasOwnProperty('url')) {
            window.location.href = (this.$route.query.url)
          } else if (this.$route.query.tk) {
            let decrypted = atob(this.$route.query.tk);
            let userInfo = JSON.parse(decrypted);
            window.location.href = userInfo.url
          } else {
            window.location.reload();
          }
        }
      },
      revert_to() {
        window.location.reload()
        this.set_error(false)
      }
    }
  };

</script>
