import states from "./states";
import raw_steps from './stepsv2';

export default {
  rate_content(){
    return states.rate_content
  },
  allSteps() {
    return raw_steps['steps'];
  },
}
