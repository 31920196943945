import Vue from 'vue'
import { ChevronRightIcon, XIcon } from 'vue-feather-icons'

export default {
  mounted() {
    this.currentSlide = 0;
    this.transform = `translateX(-${((this.currentSlide - 1) * 100)}%)`;
  },
  components: {
    ChevronIcon: ChevronRightIcon,
    X: XIcon
  },
  props: {
    imgs: {
      type: Array,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: 'full',
    },
    accessStatus: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    currentSlide: null,
    transform: null,
    srcNgaClicku: null,
    showLightbox: false
  }),
  computed: {
    imgsCount() {
      return this.imgs.length;
    },
   },
  methods: {
    transformStyle(val) {
      const transform = `translateX(-${((val - 1) * 100)}%)`;
      this.transform = transform;
    },
    sanitizeUrl(url) {
      return url !== undefined && url !== null && url.replace(/ /g, '%20');
    },
    next() {
      if (this.currentSlide < this.imgsCount) {
        this.transformStyle(this.currentSlide += 1);
      }
    },
    previous() {
      if (this.currentSlide > 1) {
        this.transformStyle(this.currentSlide -= 1);
      }
    },
    triggerLightbox(value) {
      this.srcNgaClicku= value;
      this.showLightbox= true;
      this.$refs.esclistener.focus();
    },
    previousImage() {
      let index = this.imgs.findIndex(img => img.id === this.srcNgaClicku.id);

      if(index > 0 && index < this.imgs.length) {
        this.srcNgaClicku = this.imgs[index - 1];
      }
      this.$refs.esclistener.focus();

    },
    nextImage() {
      let index = this.imgs.findIndex(img => img.id === this.srcNgaClicku.id);
      if(index >= 0 && index < this.imgs.length-1) {
        this.srcNgaClicku = this.imgs[index + 1];
      }
      this.$refs.esclistener.focus();
    },
    setImageFromDots(image){
      // let index = this.imgs.findIndex(img => img.id === image.id);
      this.$refs.esclistener.focus();
      this.srcNgaClicku = image;
    }
  },
};
