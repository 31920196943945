export default {
  SET_PROPERTY_DETAILS(state, payload) {
      state.propertyDetails = payload;
  },
  SET_CURRENCY(state, payload){
    state.propertyDetails.currency = payload
  },
  SET_EVENT_CONFIG(state, payload){
    state.eventConfig = payload
  },
  SET_SELECTED_EVENT(state, payload){
    state.selectedEvent = payload
  },
  SET_EVENT_TIME(state, payload){
      state.eventTime = payload
  },
  ADD_SELECTED_PACKAGE(state, payload){
    state.selected_packages = [...state.selected_packages, payload]
  },
  SET_SELECTED_PACKAGES(state, payload){
    state.selected_packages = payload
  },
  SET_GUEST_DATA(state, payload) {
    state.guest_data = payload
  },
  SET_SUMMARY_DETAILS(state, payload){
    state.summary_details = payload
  },
  SET_EVENT_DETAILS(state, payload) {
    state.eventDetails = payload
  }
}
