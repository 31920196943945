import Vue from 'vue';
import Vuex from 'vuex';

import property from './modules/property';
import configs from './modules/configs';
import events from './modules/events';
import activities from './modules/activities';
import abs from './modules/abs';
import states from './states';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex);

export default new Vuex.Store({
  state: states,
  mutations: mutations,
  actions: actions,
  getters: getters,

  modules: {
    property,
    configs,
    events,
    abs,
    activities
  },
});
