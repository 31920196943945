import axios from 'axios'
import store from '../store'
export default {
	get(apiUrl, params, baseUrl) {
		let url = baseUrl === 'baseAppUrl' ? `${this.getBaseUrl(baseUrl)}${apiUrl}` : `${this.getBaseUrl(baseUrl)}/${apiUrl}`;
		return axios.get(url, {params});
	},
    post(apiUrl, payload, params, baseUrl) {
		let url = `${this.getBaseUrl(baseUrl)}/${apiUrl}`;
        return axios.post(url, payload || {}, {params});
    },
    put(apiUrl, payload, params, baseUrl) {
        let url = `${this.getBaseUrl(baseUrl)}/${apiUrl}`;
        return axios.put(url, payload || {}, {params});
    },
    getBaseUrl(baseUrl){
        const base_url = store.state.property?.details?.region?.url

        const enterpriseUrl = `${base_url}${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}`;
        const propertyUrl = `${enterpriseUrl}/properties`;
        const base_core_url = `${base_url}${process.env.VUE_APP_CORE_API}/${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}`;
        const base_app_url = `${base_url}${process.env.VUE_APP_BASE_URL}`;
        const base_app_url_properties = `${base_url}${process.env.VUE_APP_BASE_URL}properties`;
        let url = ''
        switch (baseUrl) {
            case 'enterpriseUrl':
                url = enterpriseUrl
                break;
            case 'baseCoreUrl':
                url = base_core_url
                break;
            case 'baseAppUrl':
                url = base_app_url
                break;
          case 'baseAppUrlProperties':
                url = base_app_url_properties
                break;
            default:
                url = propertyUrl
                break;
        }
        return url
    }


}
