import Vue from 'vue'
import VueLogger from 'vuejs-logger';
import VueCarousel from 'vue-carousel';
import {i18n} from './utils/i18n';
import CommonUtils from '@/utils/index';
import router from './routes/router';
import '@/directives/index';
import './filters/filters';
import './plugins/validate';

const isProduction = process.env.NODE_ENV === 'production';

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};

Vue.use(VueLogger, options);

import App from './App.vue'
import store from './store';
import VeeValidate from 'vee-validate';
import './assets/sass/app.scss'
import './axios_initalization'


import '@/plugins/element.js'


//Vue.config.productionTip = false;
Vue.use(VeeValidate);
Vue.use(VueCarousel);


import {Validator} from 'vee-validate';

let language = CommonUtils.getCookie('hk_language').substring(0, 2) || 'en'

import(`vee-validate/dist/locale/${language}`).then(locale => {
  Validator.localize(language, locale);
});

// import * as VueGoogleMaps from './../node_modules/vue2-google-maps/src/main';
//
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAVHklv9R4x00LvMoFkk0wAMVWEcKZLAxw',
//     libraries: 'places',
//   }
// });

import flatPicker from 'flatpickr'

if (language !== 'en') {
  import(`flatpickr/dist/l10n/${language}.js`).then(locale => {
    flatPicker.localize(locale[Object.keys(locale)[0]]);
  })
}

Vue.config.debug = process.env.NODE_ENV === 'development';
Vue.config.productionTip = false

Vue.directive('click-outside', {
  bind() {
    if (this) {
      this.event = event => this.vm.$emit(this.expression, event)
      this.el.addEventListener('click', this.stopProp)
      document.body.addEventListener('click', this.event)
    }
  },
  unbind() {
    if (this) {
      this.el.removeEventListener('click', this.stopProp)
      document.body.removeEventListener('click', this.event)
    }
  },

  stopProp(event) {
    event.stopPropagation()
  }
})

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')


