import Vue from 'vue'

Vue.directive('disabled', (el, bind) => {
  if(bind.value) {
    el.classList.add('clickable-disable')
  } else {
    el.classList.remove('clickable-disable');
  }

  el.onmousedown = () => {
    if(bind.value) {
      el.classList.add('disable-click')
      setTimeout(() => {
        el.classList.add('clickable-disable')
        el.classList.remove('disable-click')
      }, 300);
    } else {
      el.classList.remove('clickable-disable');
    }
  }
})
