<template>
 <component
    :is="element" ref="element"
    title=""
    :before-close="close"
    :visible="true"
    direction="ttb"
    size="100%"
    :show-close="false"
    class="reservation-info-comp">
    <div class="header flexed justify-between p-20 m-b-20 border-bottom " v-if="mobileView">
        <div >
          {{ $t("lbl_reservation_details") }}
        </div>
        <div >
            <x-icon @click="close"></x-icon>
        </div>
    </div>
    <div class="reservation-info">
        <div>{{ $t("lbl_confirmation_number") }}:</div><div class="bold500">{{searchReservationResult.conf_num}}</div>
         <div >{{ $t("lbl_check_in_date") }}:</div><div  class="bold500">{{reservationDetails.check_in_date | formatDate}}</div>
        <div >{{ $t("lbl_check_out_date") }}:</div><div  class="bold500">{{reservationDetails.check_out_date | formatDate }}</div>
        <div>{{ $t("lbl_current_room_type") }}:</div><div class="bold500">{{getCurrentRoomName}}</div>
        <div>{{ $t("lbl_current_rate_plan") }}:</div><div class="bold500">{{getCurrentRateName}}</div>
       <div >{{ $t("lbl_guest/children") }}:</div><div class="bold500">{{reservationDetails.adults + ' Adults,  ' + reservationDetails.children + ' Children'}}</div>
    </div>
 </component>
</template>

<script>
import { mapState } from 'vuex'
import { XIcon} from 'vue-feather-icons'
    export default {
        name: 'ReservationInfo',
        components: {
            XIcon,
        },
        computed: {
            ...mapState({
                searchReservationResult:  state => state.searchReservationResult,
                mobileView: state => state.mobileView,
                propertyDetails: (state) => state.property?.details,
            }),
            reservationDetails(){
                return this.searchReservationResult?.reservation_details || {}
            },
            getCurrentRoomName(){
                return this.reservationDetails?.room_type?.name
            },
            getCurrentRateName(){
                return this.reservationDetails?.rate_code?.name
            },
            element() {
                return this.mobileView ? 'el-drawer' : 'div';
            },
        },
        methods: {
            close() {
                this.$emit('close')
            }
        },
    }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/config/mixins";
@import "@/assets/sass/config/variables";
    .reservation-info-comp{
        width: 100%;
         .reservation-info{
            font-size: 14px;
            background-color: whitesmoke;
            border-radius: 5px;
            display: grid;
            grid-template-columns: 145px 1.3fr 120px 1.2fr 110px 0.9fr;
            grid-gap: 5px 5px;
            margin-bottom: 10px;
            padding: 15px;
        }
    }
    ::v-deep .el-drawer__header{
        display: none;
    }
     @include media('<=1380px') {
         .reservation-info-comp{
            .reservation-info{
                grid-template-columns: 145px 1.3fr 120px 1.2fr 110px 1.1fr;
            }
         }
     }
   @include media('<=tablet') {
        .reservation-info-comp{
            width: 100%;
            .reservation-info{
                width: 100%;
                background-color: white;
                display: grid;
                grid-template-columns: 145px 1fr;
                grid-gap: 10px 5px;
                margin-bottom: 10px;
                padding: 20px;
                padding-top: 0px;
                div:nth-child(2n) {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
   }
</style>
