<template>
  <normal-popup :width="!mobileView ? '50%' : '95%'" :noBorder="true">
     <div slot="header" class="flexed justify-between">
       <span class="bold">{{ $t("lbl_confirm_method_of_payment") }}</span>
          <span class="close pointer" @click="$emit('close')"><i class="el-icon-close"></i></span>
     </div>
   <div>
        {{$t('lbl_mop_warning')}}
   </div>
   <div slot="bottom" class="flexed-end m-20">
        <el-button type="primary" @click="$emit('onConfirm', 'GUEST')">{{$t('lbl_guest_will_pay')}}</el-button>
        <el-button type="primary" @click="$emit('onConfirm', 'COMPANY')">{{$t('lbl_company_will_pay')}}</el-button>
   </div>
  </normal-popup>
</template>

<script>
import NormalPopup from "@/components//NormalPopup.vue"
import { mapState } from 'vuex'

export default {
    name: 'HotelDetailsPopup',
    components:{NormalPopup},
    computed: {
         ...mapState({
            mobileView: state => state.mobileView
         })
    },
}
</script>

<style lang="scss" scoped>

</style>
