<template>
  <div class="card-container" :class="{
  'success': paymentProcessStatus === 'Success',
  'error': paymentProcessStatus !== 'Success',
  }">
    <!--    <el-button :style="cssProps" :disabled="buttonDisabled" :loading="addingPayment"-->
    <!--               icon="el-icon-bank-card"-->
    <!--               style="max-width: 215px"-->
    <!--               @click.native="addPayment()"-->
    <!--               type="primary">{{buttonText}}-->
    <!--    </el-button>-->
    <div v-if="loading" class="loader loading_whole">
      <loading></loading>
    </div>
    <iframe
      style="border: #dadada 1px solid; padding: 10px; border-radius:5px"
      :style="{'margin': iFrameMargin || '0 0 0 15px'}"
      v-show="!loading"
      class="payment"
      frameborder="0"
      :width="iFrameWidth || '92%'"
      :height="'350px'"
      ref="myIframe"
      id="myIframe"
      :src="iFrameSrc"
      @load="frameLoaded"
    ></iframe>

    <div v-if="paymentProcessStatus === 'Success' && !addingPayment & !hideCardNumber" class="status-loader">
      <img width="25px"
           src="@/assets/credit-card.svg" alt="">
      <span class="text">{{ encryptToken(tokenizedCardDetails.masked_card_number) }}</span>
    </div>
    <span class="text" v-if="paymentProcessStatus === 'Error' && !addingPayment">{{
        $t('lbl_payment_failed_message_booking_web')
      }}</span>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import axios from 'axios'
import * as Security from '@/utils/security_manager'
import loading from '@/components/common/loading.vue';

export default {
  name: "credit-card-component",
  props: ['guestEmail', 'iFrameWidth', 'iFrameMargin', 'hideCardNumber'],
  mounted() {
    this.getPaymentData()
  },
  data: () => ({
    windowTab: null,
    interval: null,
    requestId: null,
    pollTimer: null,
    paymentProcessStatus: null,
    tokenizedCardDetails: null,
    addingPayment: false,
    paymentData: false,
    loading: false,
    Oid: null,
  }),
  components: {
    loading
  },
  computed: {
    ...mapState({
      'booking_color': state => state.booking_color,
      'app_id': state => state.property.details.website_config.app_id
    }),
    buttonText() {
      if (this.paymentProcessStatus === null || this.paymentProcessStatus === 'Error') {
        return this.$i18n.t('lbl_add_payment_details');
      }
      return this.$i18n.t('lbl_payment_details_added');
    },
    buttonDisabled() {
      return this.paymentProcessStatus === 'Success'
    },
    cssProps() {
      return {
        '--bg-color': `rgba(${this.booking_color}, 1)`,
        '--bg-color-lighten': `rgba(${this.booking_color}, 0.8)`,
      }
    },

    iFrameSrc() {
      if (this.paymentData) {
        return this.paymentData;
      } else {
        return '';
      }
    }
  },
  methods: {
    ...mapMutations({
      SET_ERROR: 'SET_ERROR'
    }),
    frameLoaded() {
      this.loading = false

    },
    handler({data}) {
      // console.log(data);
    },
    getPaymentData() {
      this.loading = true
      this.requestId = this.uuidv4();
      let url = `${process.env.VUE_APP_TOKENIZER_URL}pci/secure/resource/index.html?appId=${this.app_id}&requestId=${this.requestId}&embedded=true`
      axios.get(url)
        .then(res => {
          this.paymentData = res.request?.responseURL
          this.pollTimer = setInterval(() => {
            this.refreshStatus()
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        })
    },
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    encryptToken(value) {
      let last_four = value.slice(value.length - 4, value.length)
      return "XXXX XXXX XXXX " + last_four;
    },
    refreshStatus() {
      if(!this.pollTimer) window.clearInterval(this.pollTimer);
      
      let apiClient
      let username = process.env.VUE_APP_TOKENIZER_USERNAME
      let password = process.env.VUE_APP_TOKENIZER_PASSWORD

      let tokenObject = Security.getToken(username, password)

      if (!this.requestId) {
        return;
      }

      apiClient = axios.create({
        baseURL: `${process.env.VUE_APP_TOKENIZER_URL}pci/secure/status?requestId=${this.requestId}`,
        headers: {
          'hk-token': tokenObject.token,
          'hk-info': tokenObject.info,
          // 'hk-app-id': 'HK_BOOKING_WEB',
          // 'hk-app-version': process.env.VUE_APP_VERSION,
        }
      })
      apiClient.get('')
        .then(res => {
          window.clearInterval(this.pollTimer);
          if (res.data.status.toLowerCase() === 'success') {
            this.paymentProcessStatus = 'Success';
            this.tokenizedCardDetails = res.data;
            this.tokenizedCardDetails.card_type = this.tokenizedCardDetails.card_brand;
            this.$emit('updateCC', {status: 'success', ccDetails: this.tokenizedCardDetails});
          } else {
            // this.paymentProcessStatus = 'Error';
            window.clearInterval(this.pollTimer);
            this.$emit('updateCC', {status: 'error', ccDetails: null});
          }
        }).catch(err => {
        console.log(err);
        // this.paymentProcessStatus = 'Error';
        // this.SET_ERROR(false)
      }).finally(() => {
        this.addingPayment = false;
      })
    }
  },
  beforeDestroy() {
    window.clearInterval(this.pollTimer);
    this.pollTimer = null
  }
}
</script>

<style scoped lang="scss">

@import "~mixins";

.text {
  margin-left: 15px;
  font-size: 15px
}

.card-container {
  display: flex;

  &.success {
    flex-direction: column;
  }

  &.error {
    align-items: center;
  }
}

.error-position {
  position: absolute;
  bottom: -25px;
}

.status-loader {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 15px;
}

button {
  background: var(--bg-color) !important;
  border-color: var(--bg-color) !important;
  border-radius: 5px;

  &:focus {
    box-shadow: var(--bg-color) !important;
  }

  &:hover {
    color: white !important;
    background: var(--bg-color) !important;
  }

  &.is-disabled {
    background-color: var(--bg-color-lighten) !important;
  }
}

@include media('<=tablet') {
  .card-container {

    &.error, .success {
      flex-direction: column;
      align-items: flex-start;

      .text {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}
</style>
