<template>
  <div class="package-item card-border">
    <div v-if="package_data.image">
      <img :src="package_data.image" alt="" width="298" height="180">
    </div>
    <div v-else class="list-card-pic" style="width: 298px; height: 185px">
      <div class="no-img">
        <i class="el-icon-picture-outline no-img-icon"></i>
        <span>{{ $t("lbl_no_image_found") }}</span>
      </div>
    </div>
    <div class="package-item-content">
      <div class="package-item-text">
        <h3 class="title">{{ package_data.name}}</h3>
        <h4 class="title2">{{ $t("lbl_package_includes") }}</h4>
        <ul class="list">
          <li v-for="item in package_data.package_details" :key="item.id">{{ item.name }}</li>
        </ul>
      </div>
      <div class="flexed">
        <el-input-number
          v-model="num" :min="1" :max="999"
          class="capacity-input"
          :disabled="isSelected"
          style="width: 50%;"
        ></el-input-number>
        <p class="price_tag">{{ totalPrice | currency(currency)}}</p>
    </div>

      <el-button v-if="isSelected"
                 type="danger"
                 class="select-package-button"
                 @click="removePackage()"
      >{{ $t("lbl_remove") }}</el-button>
      <el-button v-else
                 type="primary"
                 class="select-package-button"
                 @click="addPackage()"
      >{{ $t("lbl_select_package") }}</el-button>
    </div>

  </div>
</template>

<script>
import CurrencyData from '@/assets/currencies/currencies';
import {mapState} from "vuex";

export default {
  name : 'PackageItem',
  props: {
    package_data : {},
    data: null
  },
  data(){
    return{
      num: 1,
      isSelected : false,
      currencyData: CurrencyData
    }
  },
  mounted() {
    if(this.data) {
      this.num = this.data.capacity
      this.isSelected = true
    }

  },
  methods:{
    removePackage(){
      this.isSelected = false
      this.$emit('removePackage', this.package_data.id)
    },
    addPackage(){
      this.isSelected = true
      if(!this.num)
        this.num = 1
      this.$emit('selectPackage', {package_data: this.package_data, capacity: this.num})
    },
  },
  computed: {
    ...mapState({
      currency: state => state.events.propertyDetails.currency
    }),
    totalPrice(){
      return parseInt(this.package_data.calendars[0].price) * this.num
    }
  }
}
</script>

<style scoped lang="scss">
.package-item{
  width: 300px;
  font-size: 14px;
  img{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .package-item-content{
    padding: 0 20px 20px;

    .package-item-text {
      height: 200px;
      overflow: auto;
    }
    .title{
      margin: 15px 0 0;
      font-size: 16px;
    }
    .price_tag{
      width: 50%;
      text-align: end;
      font-weight: bold;
      margin: 15px 0 0;
      font-size: 16px;
      align-content: center;
      padding: 10px 0;
    }
    .title2{
      margin: 15px 0 5px;
    }
    .list{
      list-style-type: disc;
      padding: 0 20px;
      color: #4E4E4E;
    }
    .select-package-button, .capacity-input{
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
