<template>
    <div class="drawer-class">
    <el-drawer
      title="Hotel Details"
      :visible="true"
      :before-close="close"
      direction="ttb"
      size="100%">

      <div class="drawer-container">
          <hotel-details />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import hotelDetails from './HotelDetails.vue'
    export default {
  components: { hotelDetails },
        name: 'HotelDetailsDrawer',
    methods: {
        close() {
            this.$emit('close')
        }
    },
    }

</script>

<style lang="scss" scoped>
.drawer-class {
  ::v-deep .el-drawer__header {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    //   font-weight: bold;
      color: #2c3e50;
    //   font-size: 20px;
    padding: 10px 20px;
    border-bottom: 1px solid #d4d4d4;
    button{
    min-width: auto !important;
    }
    .el-drawer__close-btn {
        padding: 0px;
    }
  }
::v-deep .el-drawer__body {
    padding: 20px;
}

}
</style>