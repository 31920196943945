import Vue from 'vue'
import CurrencyData from '../assets/currencies/currencies';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
// import { getCookie } from '../common/services/utilities.service';
// import { COOKIE_LANG } from '../utils/constants';
import numeral from 'numeral';
import store_property from '@/store/modules/property';


Vue.filter('capitalize', (value) => {
    if (value === undefined) return '';
    value = value.toString().toLowerCase();
    return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter('toFixed', function (value, decimalPlaces) {
    return `${parseFloat(value).toFixed(decimalPlaces)}`
});
Vue.filter('formatDate', function (value, arg1, withTime) {
    dayjs.extend(utc)
    if (value) {
        let formater = arg1 ? arg1 : 'MMM DD, YYYY';
        if(withTime) {
            formater = `${formater} hh:mm a`
        }
        else {
            if(String(value).includes('Z'))
                return dayjs(String(value)).utc().format(formater);
        }

        return dayjs(String(value)).format(formater);
    }
});
Vue.filter('json', value => {
    return JSON.stringify( JSON.parse(atob(value)), null, 2);
})
Vue.filter('currency', function (value, code, toFixedValue, pos) {

    if (!value && value !== 0) {
      return '';
    }

    let symbol;
    let position = pos || 'left';
    let return_value = value || 0;
    let symbol_param = code || store_property.state.details.currency;
    return_value = return_value.toString();

    if (CurrencyData) {
      symbol = CurrencyData.find(item => item.code === symbol_param).symbol;
    } else {
      symbol = '$';
    }

    if (toFixedValue) {
      let parsedValue = `${parseFloat(return_value)
        .toFixed(toFixedValue)}`;

      if (parsedValue.charAt(0) === '-') {
        return position === 'left' ? `-${symbol}${numeral(parsedValue)
          .format('0,0.00[0000]')
          .slice(1)}` : `-${numeral(parsedValue)
          .format('0,0.00[0000]')
          .slice(1)}${symbol}`;
      }
      return position === 'left' ? `${symbol}${numeral(parsedValue)
        .format('0,0.00[0000]')}` : `${numeral(parsedValue)
        .format('0,0.00[0000]')}${symbol}`;
    }

    if (return_value.charAt(0) === '-') {
      return position === 'left' ? `-${symbol}${numeral(return_value)
        .format('0,0.00')
        .slice(1)}` : `-${numeral(return_value)
        .format('0,0.00')
        .slice(1)}${symbol}`;
    }
    return position === 'left' ? `${symbol}${numeral(return_value)
      .format('0,0.00')}` : `${numeral(return_value)
      .format('0,0.00')}${symbol}`;
  });
Vue.filter('formatBigNumber', function (value) {
  if (!value && value !== 0) {
    return '';
  }

  return value.toLocaleString('en-us');
});
Vue.filter('currencySymbol', function (value, code) {

    let symbol_param = code || store_property.state.details.currency;
    let symbol;

    if (CurrencyData) {
      symbol = CurrencyData.find(item => item.code === symbol_param)?.symbol || '$';
    } else {
      symbol = '$';
    }
    return symbol
});

Vue.filter('shortNumber', (number) => {
    if (isNaN(number)) return null; // will only work value is a number
        if (number === null) return null;
        if (number === 0) return null;
        let abs = number//Math.abs(number);
        // const rounder = Math.pow(10, 1);
        const isNegative = number < 0; // will also work for Negetive numbers
        let key = '';

        const powers = [
            {key: 'Q', value: Math.pow(10, 15)},
            {key: 'T', value: Math.pow(10, 12)},
            {key: 'B', value: Math.pow(10, 9)},
            {key: 'M', value: Math.pow(10, 6)},
            {key: 'K', value: 1000}
        ];

        for (let i = 0; i < powers.length; i++) {
            let reduced = abs / powers[i].value;
            reduced = +reduced.toFixed(1);
            if (reduced >= 1) {
                abs = reduced;
                key = powers[i].key;
                break;
            }
        }

        let result;
           if(abs < 1){
            result = 0 + key
           }else if(key == ''){
             result = (isNegative ? '-' : '') + Math.floor(abs) + key
           }else{
            result = (isNegative ? '-' : '') + abs + key;
           }

        return result;
});


Vue.filter('canBeNegative',(value) => {
    if(value.includes('-')) {
        const splits = value.split('-');
        return`(${splits[0]}${splits[1]})`;
    }
    return value;
})

Vue.filter('formatDateFromNumber', function (value, arg1, withTime) {
    dayjs.extend(utc)
    if (value) {
        let formater = arg1 ? arg1 : 'MMM DD, YYYY';
        if(withTime) {
            formater = `${formater} hh:mm a`
        }
        return dayjs(value).format(formater);


    }
});

Vue.filter('formatTime', function (value, withTime) {
  dayjs.extend(utc);
  if (value) {
    let formater = withTime ? 'hh:mm a' : '';
    if (String(value).includes('Z')) {
      // Return only the time portion of the UTC date
      return dayjs.utc(String(value)).format('hh:mm a');
    }
    return dayjs(String(value)).format(formater);
  }
});


// Vue.filter('dateFormat' , (date, format) => {
//     if(date){
//       return moment(date).format(format)
//     }
//     return date
// });
