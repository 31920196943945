
export const getResultsFromPhoneNumber = (phoneNumber, countryCode, callback) => {
  const parsing = phoneNumber ? callback(phoneNumber, countryCode) : null

  let results = {
    countryCode,
    isValid: false
  }

  if (parsing) {
    results = {
      ...results,
      countryCode: parsing.country,
      countryCallingCode: parsing.countryCallingCode,
      nationalNumber: parsing.nationalNumber,
      isValid: parsing.isValid(),
      type: parsing.getType(),
      formatInternational: parsing.formatInternational(),
      formatNational: parsing.formatNational(),
      uri: parsing.getURI(),
      e164: parsing.format('E.164')
    }
  }

  return results
}

export const getModelNumber = (phoneNumber, callback) => {
  if (!phoneNumber) return null
  return callback(phoneNumber, 'US')
}
