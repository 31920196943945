<template>

  <div class="guest-information-main">
    <div class="guest-information-container">
<!--      <h3>Guest Information</h3>-->
      <GuestForm ref="guest-form" ></GuestForm>
    </div>
    <Summary></Summary>
    <div class="navigation">
      <button @click="previousStep()" >{{ $t('lbl_previous') }}</button>
      <el-button class="next" type="primary" :loading="loading"
                 @click="nextStep()">
        {{ $t('lbl_next') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import GuestForm from "./GuestForm";
import Summary from "../../components/Summary";
import apiCallsEventsService from "../../../../services/events/api-calls-events.service";
import {mapState, mapMutations} from "vuex";
import loading from '@/components/common/loading.vue';

export default {
  name: 'GuestInformation',
  components: {GuestForm, Summary, loading},
  data() {
    return {
      loading: false,
      countryCode: '',
      guestFormRules: null,
    }
  },
  methods: {
    ...mapMutations({
      SET_GUEST_DATA: 'events/SET_GUEST_DATA'
    }),
    async previousStep() {
      this.$router.push({name: 'select-package'}).catch(()=>{})
    },
    async nextStep() {
      let payload = await this.$refs['guest-form'].validateForm()
     
      if(payload){
        if(payload.po_number === null) {
          payload.po_number = ''
        }
        try{
          let result = await apiCallsEventsService.post(`${this.property.id}/web/new-event/${this.eventConfig.event_id}/contact-information/${this.eventConfig.session_id}`, payload, {cache:false}, 'eventManagementApi')
          this.SET_GUEST_DATA(payload)
          this.$router.push({name: 'payments'}).catch(()=>{})
        }catch (e) {
          this.$notify.error({
            title: this.$t('lbl_error'),
            message: this.$t('lbl_something_went_wrong')
          })
        }
      }
    }
  },
  computed:{
    ...mapState({
      property: state => state.events.propertyDetails,
      eventConfig: state => state.events.eventConfig
    })
  },
  async beforeMount() {
    this.loading = true

    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
@import "~css_vars";
@import '~mixins';

.guest-information-main{
  margin: 30px 80px 30px;
  display: flex;
  gap: 50px;
  padding-bottom: 50px;
  .guest-information-container{
    flex: 1;
  }
}

.navigation button {
  border: 1px solid $lightgrey-2;
  border-radius: 5px;
}
</style>
