<template>
  <div class="event-list m-t-20">

    <div v-if="spaces">
      <EventItem v-for="space in filteredSpaces"
                 :key="space.space_id"
                 :space="space"
                 @bookEvent="book"
      ></EventItem>
    </div>
    <div class="flexed justify-center align-center" v-else>
      <loading></loading>
    </div>

  </div>
</template>

<script>
import EventItem from "./EventItem";
import loading from '@/components/common/loading.vue';
import {mapState} from "vuex";
export default {
  name: 'EventList',
  components: {EventItem, loading},
  props:{
    spaces : null
  },
  methods:{
    book(item){
      this.$emit('bookEvent', item)
    }
  },
  computed:{
    ...mapState({
      eventTime: state => state.events.eventTime
    }),
    filteredSpaces(){
      if(this.spaces){
        let filteredSpaces = this.spaces.sort((a,b) => a.space_name.localeCompare(b.space_name)).filter((item) => {
          return item.space_capacity >= this.eventTime.capacity
        })
        return filteredSpaces
      }
      return null;
    }
  }
}
</script>
