<template>
  <div class="policy-box" v-if="getDepositSchedules(room.room_type_id, room.rate.id) && getDepositSchedules(room.room_type_id, room.rate.id).length">
    <span class="label-no-height">{{ $t("lbl_deposit_policy") }}</span>
    <table class="table m-t-10">
      <thead>
        <th class="label-no-height">{{ $t("lbl_date") }}</th>
        <th class="label-no-height">{{ $t("lbl_amount") }}</th>
      </thead>
      <tbody>
        <tr v-for="(schedule, index) in getDepositSchedules(room.room_type_id, room.rate.id)" :key="index">
          <td>{{schedule.date | formatDate}}</td>
          <td>{{schedule.amount | currency}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'DepositPolicy',
  props: ['room'],
  computed: {
    ...mapState({
      propertyDetails: state => state.property.details,
      rwa_data: state => state.rwa_data
    }),
  },
  methods: {
    selectedRateDetails(room_type_id,rateId){
      return this.rwa_data.rate_details.find(rd=>rd.room_type_id === room_type_id).rate_codes.find(r=> r.id === rateId)
    },
    getDepositSchedules(room_type_id,rateId){
      return this.selectedRateDetails(room_type_id,rateId).deposit_schedules
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/sass/config/mixins";
@import "src/assets/sass/config/variables";
.policy-box {
  font-size: 14px;
    border: solid #dadada 1px;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    .table{
      width: 250px;
      border-spacing: 0;
      th{
        border-bottom: 1px solid #d4d4d4;
      }
      th:not(:last-child), td:not(:last-child){
        text-align: left;
      }
      th:last-child, td:last-child{
        text-align: right;
      }
      th{
        padding-bottom: 5px;
      }
      td{
        padding-top: 5px;
      }
    }
}
@include media('<=tablet') {
  .policy-box {
    .table{
      width: 100%;
    }
  }
}
</style>
