<template>
  <hk-dropdown v-if="!hideDropDown">
    <div class="dropdown-custom"
         type="primary"
         slot="activator">
      <users style="width:15px;font-size: 14px;" class="user-icon"></users>
      <span style="padding-left: 10px">{{ adultsAndChildren }}</span>
    </div>
    <div slot="dropdown" class="guests-dropdown" v-for="(item, index) in rooms" :key="index">
      <div class="room-number"> {{ $t("lbl_room") }} {{ (roomIndex || index) + 1 }}</div>
      <div class="rooms-chosen-dropdown" style="font-size: 14px; font-weight: 400">
        <div>
          <span>{{ $t("lbl_adults") }}</span>
          <stepper
            stepper
            @increase="item.persons.adults++; guestsChanged(item, (roomIndex || index) || 0)"
            @decrease="item.persons.adults--; guestsChanged(item, (roomIndex || index) || 0)"
            :min="1"
            :max="maxGuests(item, item.persons.adults)"
            :model.sync="item.persons.adults"></stepper>
        </div>
        <div>
          <span>{{ $t("lbl_children") }}</span>
          <stepper
            @increase="pushChildrenAges(item, (roomIndex || index) || 0); item.persons.children++;guestsChanged(item, (roomIndex || index) || 0)"
            @decrease="popChildrenAges(item, (roomIndex || index) || 0); item.persons.children--;guestsChanged(item, (roomIndex || index) || 0)"
            :min="0"
            :max="maxGuests(item, item.persons.children)"
            :model.sync="item.persons.children"></stepper>
        </div>
        <div class="children-age" v-if="item.persons.children > 0 && enable_children_ages">
          <div class="font-small m-b-5">{{ $t("lbl_add_age_of_children") }}</div>
          <div class="flexed gap-10" style="width: 198px; flex-wrap: wrap">
            <div v-for="(child, i) in item.persons.children_ages" :key="'age_of_children_' + i" >
              <el-input style="width:42px" size="mini" v-model="item.persons.children_ages[i]"
                        @input="childAgeInput($event, index, i, item)"></el-input>
            </div>
          </div>
        </div>
      </div>

    </div>
  </hk-dropdown>
  <div v-else>
    <div class="guests-dropdown" v-for="(item, index) in rooms" :key="index">
      <div class="room-number"> Room {{ +(roomIndex || index) + 1 }}</div>
      <div class="rooms-chosen-dropdown" style="font-size: 14px; font-weight: 400">
        <div>
          <span>{{ $t("lbl_adults") }}</span>
          <stepper
            stepper
            @increase="item.persons.adults++; guestsChanged(item, (roomIndex || index) || 0)"
            @decrease="item.persons.adults--; guestsChanged(item, (roomIndex || index) || 0)"
            :min="1"
            :max="maxGuests(item, item.persons.adults)"
            :model.sync="item.persons.adults"></stepper>
        </div>
        <div>
          <span>{{ $t("lbl_children") }}</span>
          <stepper
            @increase="pushChildrenAges(item, (roomIndex || index) || 0); item.persons.children++;guestsChanged(item, (roomIndex || index) || 0);"
            @decrease="popChildrenAges(item, (roomIndex || index) || 0); item.persons.children--;guestsChanged(item, (roomIndex || index) || 0)"
            :min="0"
            :max="maxGuests(item, item.persons.children)"
            :model.sync="item.persons.children"></stepper>
        </div>
        <div class="children-age" v-if="item.persons.children > 0 && enable_children_ages">
          <div class="font-small m-b-5">Add Age of Children</div>
          <div class="flexed gap-10" style="width: 198px; flex-wrap: wrap">
            <div v-for="(child, i) in item.persons.children_ages" :key="'age_of_children_' + i" >
              <el-input style="width:42px" size="mini" v-model="item.persons.children_ages[i]"
                        @input="childAgeInput($event, index, i, item)"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HkDropdown from "../../../../components/common/hk-dropdown";
import stepper from "../../../../components/stepper";
import {UsersIcon} from "vue-feather-icons";
import Clone from 'clone'
import { mapState } from 'vuex';
import moment from "moment";

export default {
  name: "GuestsFilter",
  props: ['searchData', 'hideDropDown', 'roomIndex'],
  components: {HkDropdown, stepper, users: UsersIcon},
  data() {
    return {
      roomCount: null,
      guestCount: null,
      childrenCount: null
    }
  },
  computed: {
    ...mapState({
      propertyDetails: state => state.property?.details,
      search_data: (state) => state.search_data,
      children_ages: state => state.children_ages
    }),
    adultsAndChildren() {
      if (!isNaN(this.roomIndex)) {
        let allRoomsAdults = this.searchData.rooms[this.roomIndex].persons.adults
        let allRoomsChildren = this.searchData.rooms[this.roomIndex].persons.children
        return `${allRoomsAdults} ${this.$t('lbl_adults')}, ${allRoomsChildren} ${this.$t('lbl_children')}`
      } else {
        let allRoomsAdults = this.searchData.rooms.reduce((b, a) => b + a.persons.adults, 0)
        let allRoomsChildren = this.searchData.rooms.reduce((b, a) => b + a.persons.children, 0)
        return `${allRoomsAdults} ${this.$t('lbl_adults')}, ${allRoomsChildren} ${this.$t('lbl_children')}`
      }
    },
    rooms() {
      const roomIndex = this.roomIndex ? this.roomIndex : 0
      if (!isNaN(this.roomIndex)) {
        return [this.searchData.rooms[roomIndex]];
      } else {
        return this.searchData.rooms
      }
    },
    maxNumberOfGuests(){
      return this.propertyDetails?.website_config?.max_guests_allowed || 6
    },
    enable_children_ages(){
      return this.propertyDetails?.application_configuration?.enable_children_ages
    }
  },
  methods: {
    guestsChanged(item, index) {
      let payload = {
        guests: item,
        guestIndex: index,
        // children_ages: this.children_ages
      }
      // this.$emit('emitRoomsFromGuest', this.rooms)
      this.$emit('guestsChanged', payload)
    },
    childAgeInput($event, i , j, item){
      let num = $event.replace(/\D+/g, '')
      num = Math.trunc(num)
      if(num>99)
        num = Math.trunc(num%100)

      this.searchData.rooms[i].persons.children_ages[j] = num

      setTimeout(() => {
        this.guestsChanged(item, (this.roomIndex || i) || 0)
      }, 1000)

    },
    maxGuests(item, val){
      return  (item.persons.adults + item.persons.children) >= this.maxNumberOfGuests ? val : this.maxNumberOfGuests
    },
    pushChildrenAges(item, index) {
      if(this.enable_children_ages && this.searchData?.rooms[index]?.persons?.children_ages) {
        this.searchData?.rooms[index]?.persons?.children_ages.push(0)
      }
    },
    popChildrenAges(item, index) {
      if(this.enable_children_ages && this.searchData?.rooms[index]?.persons?.children_ages) {
        this.searchData?.rooms[index]?.persons?.children_ages.pop()
      }
    },
    childrenAgesFromURL() {
      if (this.$route.query.children && this.$route.query.children > 0 && this.enable_children_ages) {
        const childrenCount = Number(this.$route.query.children);
        const roomCount = Number(this.$route.query.room_count) || 1;

        this.searchData.rooms.forEach(room => {
          room.persons.children_ages = [];
        });

        let remainingChildren = childrenCount;
        for (let i = 0; i < roomCount; i++) {
          const childrenForRoom = Math.min(Math.ceil(remainingChildren / (roomCount - i)), remainingChildren);
          for (let j = 0; j < childrenForRoom; j++) {
            this.searchData.rooms[i].persons?.children_ages?.push(0);
          }
          remainingChildren -= childrenForRoom;
        }
      }
    }


  },
  mounted() {
    this.$nextTick(() => {
      this.childrenAgesFromURL();
    });
  },









}
</script>

<style lang="scss" scoped>
@import "../../../../assets/sass/config/variables";
@import "../../../../assets/sass/config/mixins";

.dropdown-custom {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 53.5px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  // margin-left: 10px;

  .user-icon {
    color: $hk-primary-color;
  }
}

:deep(.list-scroll) {
  max-height: 470px !important;
  overflow: auto !important;
}

.guests-dropdown {
  padding: 20px 20px 0 20px;
  width: 250px;

  .room-number {
    display: flex;
    margin-bottom: 5px;
    color: #b1b1b1;
    font-size: 12px;
  }

  .add-another-room {
    font-size: 11px;
    margin-top: 3px;
    cursor: pointer;
  }

  .rooms-chosen-dropdown {
    margin-bottom: 10px;
    border-bottom: 1px solid #dadada;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .room-number {
      display: flex;
      margin-bottom: 5px;
      color: #b1b1b1;
    }

    .remove {
      color: #409eff;
      cursor: pointer;
    }
  }
}

.children-age {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  border: none;
  background: white;
  padding: 0px 10px;

  :deep(.el-input__inner) {
    padding: 0px 10px !important;
  }
}
.children-age:last-of-type{
  padding-bottom: 10px;
}

@include media('<=tablet') {
  .dropdown-custom {
    // line-height: 40px;
  }
  .guests-dropdown {
    padding: 10px 10px 0 10px;
    width: 100%;
  }
}


</style>
