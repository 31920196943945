<template>
  <div class="stepper-container">
    <div class="step"  @click="decrease">
      <minus></minus>
    </div>

    <span>{{model}}</span>

    <div class="step"  @click="increase">
      <plus></plus>
    </div>
  </div>
</template>

<script>

  import {MinusCircleIcon, PlusCircleIcon} from 'vue-feather-icons'

  export default {
    name: "stepper",
    props: ['min', 'max', 'model'],
    components: {
      minus: MinusCircleIcon,
      plus: PlusCircleIcon
    },
    methods: {
      increase() {
        if(this.model < this.max) {
          this.$emit('increase')
        }
      },
      decrease() {
        if(this.model > this.min) {
          this.$emit('decrease')
        }
      }
    }
  }
</script>

<style scoped lang="scss">
@import '~css_vars';
  .stepper-container {
    height: 30px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: white;
    div {
      display: flex;
      align-items: center;
    }
  }
  .step {
    cursor: pointer;
    color: $hk-primary-color;
  }
</style>
