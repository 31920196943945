<template>
  <div>
    <div>
      <header class="card flexed align-center w-100 justify-between p-10">
        <div class="flexed align-center">
          <img style="width: 40px; height: 40px" :src="brandCodeLogo" alt="" />
          <h3 class="m-10">{{ brandCodeName }}</h3>
        </div>
        <div>
          <el-button type="primary">Login</el-button>
        </div>
      </header>

      <main class="p-20">
        <div>
          <div class="w-100 flexed gap-20 m-b-20 mobile-search">
            <div class="w-50 search-destinations">
              <div>
                <label for="">Search Destinations</label>
                <el-input
                  v-model="searchText"
                  @input="performSearch"
                  placeholder="Search by Property Name or Property Code..."
                >
                </el-input>
                <div
                  class="search-container"
                  v-if="searchText.length > 0 && showDropdowns"
                >
                  <div class="card-border m-t-5 m-b-10 p-10 search-result">
                    <!-- <div class="small border-bottom w-100 ">Search by Property</div> -->
                    <ul>
                      <li
                        class="pointer properties-divider"
                        v-for="property in propertyResults"
                        @click="selectedItem(property)"
                        :key="property.id"
                      >
                        {{ property.name }}
                      </li>
                    </ul>
                  </div>
                  <!-- <div class="card-border p-10">
                    <div class="small border-bottom">Search by Destinations</div>
                    <ul>
                      <li class="pointer"
                        v-for="destination in destinationResults"
                        :key="destination.id"
                        @click="selectedItem(destination)"
                      >
                        {{ destination.city }}
                      </li>
                    </ul>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="w-50 flexed justify-between gap-20 mobile-search search-inputs">
              <div class="flexed-column">
                <label for="">Check In</label>
                <el-date-picker
                  v-model="check_in_date"
                  type="date"
                  placeholder="Check In"
                  :picker-options="datePickerOptions('check_in')"
                >
                </el-date-picker>
              </div>

              <div class="flexed-column">
                <label for="">Check Out</label>
                <el-date-picker
                  v-model="check_out_date"
                  type="date"
                  placeholder="Check Out"
                  :picker-options="datePickerOptions('check_out')"
                >
                </el-date-picker>
              </div>

              <div class="flexed-column">
                  <label for="">Adults</label>
                  <el-input-number
                    class="input-number"
                    size="custom"
                    v-model="number_of_person"
                    @change="handleChange"
                    :min="1"
                    :max="6"
                  >
                  </el-input-number>
              </div>
              <div style="margin-top: 24px">
                <el-button
                  class="mobile-btn"
                  @click="callLowestApi"
                  type="primary"
                  >Search</el-button
                >
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading" style="margin: 300px auto">
          <div v-loading="loading"></div>
        </div>
        <div v-else :class="['flexed', 'justify-between', 'gap-20', 'mobile-content', searchText && showDropdowns ? 'main-content': '']">
          <section v-if="hotels" class="w-50 card fit-content ">
            <div v-for="(hotel, index) in filteredHotels" :key="index" class="divider">
              <div class="flexed gap-20 justify-between p-t-10 p-b-10">
                <div class="images">
                  <div v-if="hotel.image">
                    <img
                      style="width: 200px; height: 150px"
                      :src="hotel.image"
                      alt=""
                    />
                  </div>
                  <div class="list-card-pic" v-else>
                    <div class="no-img">
                      <i class="el-icon-picture-outline no-img-icon"></i>
                      <span>No image found</span>
                    </div>
                  </div>
                </div>

                <div class="brand-content w-100">
                  <h4 class="brand-name">{{ hotelName(hotel) }}</h4>
                  <div v-if="!mobileView" class="small">
                    {{ shortDesc(hotel.property.additional_details) }}
                  </div>
                  <div class="flexed amenity" v-if="hotel.property.amenities">
                    <p
                      :class="amt.icon ? 'm-5' : ''"
                      v-for="(amt, index) in hotel.property.amenities"
                      :key="index"
                    >

                      <el-popover
                        placement="top-start"
                        width="150"
                        :trigger="mobileView ? 'click' : 'hover'"
                        :content="amt.name"
                      >
                        <img
                          v-if="amt.icon"
                          style="width: 14px; height: 14px"
                          :src="amt.icon"
                          class="amenitie-icon"
                          slot="reference"
                          alt=""
                        />
                      </el-popover>

                    </p>
                  </div>
                </div>

                <div class="prices">
                  <div class="flexed-column prices-content">
                    <div class="">
                      <div v-if="hotel.lowest_rate">
                        <div><em class="start-from">Starts from</em></div>
                        <strong
                          >{{ hotel.lowest_rate | currency(currency) }}*</strong
                        >
                      </div>
                      <div v-else class="sold-out flexed-end">Sold Out</div>
                      <div style="font-size: 12px; color: #ccc"></div>
                    </div>
                    <div class="book-box">

                      <el-button
                      class="book-btn"
                      :disabled="!hotel.lowest_rate"
                      @click="goToSearch(hotel)"
                      style="margin-top: auto;"
                      type="primary"
                      >Book</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

<!--          <section-->
<!--            v-if="!mobileView"-->
<!--            class="w-50 card p-t-20 p-b-20 fit-content"-->
<!--          >-->
<!--            <gmap-map :center="center" :zoom="zoom" style="height: 600px">-->
<!--              <gmap-marker-->
<!--                v-for="(marker, index) in markers"-->
<!--                :key="index"-->
<!--                :position="marker.position"-->
<!--                :clickable="marker.clickable"-->
<!--                @mouseover="showByIndex = index"-->
<!--                @mouseout="showByIndex = null"-->
<!--              >-->
<!--              <gmap-info-window :opened="showByIndex === index">-->
<!--                <div>-->
<!--                  <p>{{ getPropertyName(marker.position.lat, marker.position.lng ) }}</p>-->
<!--                </div>-->
<!--            </gmap-info-window>-->
<!--            </gmap-marker>-->
<!--            </gmap-map>-->
<!--          </section>-->
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// import * as VueGoogleMaps from "vue2-google-maps";
import { formatDate } from "@/services/utilities.service.js";
import commonServices from "@/services/common.services";
// import { InfoWindow } from "vue2-google-maps";
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  name: "Search-v2",
  components: {
    // "gmap-map": VueGoogleMaps.Map,
    // "gmap-marker": VueGoogleMaps.Marker,
    // "gmap-info-window": InfoWindow,
  },
  data() {
    return {
      brand_code: {},
      hotels: null,
      searchText: "",
      check_in_date: null,
      check_out_date: null,
      number_of_person: null,
      propertyResults: [],
      destinationResults: [],
      showDropdowns: false,
      zoom: 13,
      center: [37.7749, -122.4194],
      markers: null,
      selectedProperty: null,
      loading: false,
      baseUrlVariable: "",
      showByIndex: null
    };
  },
  methods: {
    goToSearch(hotel) {
      const bookingPath = `/booking/select-rooms?pc=${hotel.property.code}&property_id=${hotel.property.id}&from=${this.formattedCheckInDate}&to=${this.formattedCheckOutDate}&guest=${this.number_of_person}&room_count=1&skip_search=true`;

      // local
      // const url =
      //   this.baseUrlVariable + "hk-booking-web/index.html#" + bookingPath;

        //dev
      const url =
        this.baseUrlVariable + "/index.html#" + bookingPath;

      window.location.href = url;
    },
    handleChange(event) {
      console.log(event);
    },
    getPropertyName(lat, lng) {
      let propertyObj = this.hotels?.find(hotel => {
        return (hotel?.property?.latitude == lat &&
          hotel?.property?.longitude == lng)
      })
      return propertyObj?.property?.name
    },
    shortDesc(desc) {
      if (desc) {
        return desc.length > 70 ? `${desc.slice(0, 69)}...` : desc;
      } else {
        return "";
      }
    },
    hotelName(hotel) {
      return hotel?.property?.name;
    },
    datePickerOptions(type) {
      let cbd = dayjs(formatDate(String(this.check_in_date), "YYYY-MM-DD"));
      let lastAvailableDate;
      if (type === "check_in") {
        lastAvailableDate = cbd.add(729, "day");
      } else if (type === "check_out") {
        lastAvailableDate = cbd.add(730, "day");
      }

      return {
        disabledDate(date) {
          return date < cbd || date > lastAvailableDate;
        },
      };
    },
    performSearch() {
      const searchTerm = this.searchText.toLowerCase();
      const filteredProperties = this.brand_code?.properties?.filter(property => property?.property_type === 'HOTEL');
      this.propertyResults = filteredProperties?.filter(
        (property) =>
          property?.name.toLowerCase().includes(searchTerm) ||
          property?.code.toLowerCase().includes(searchTerm)
      );

      //   this.destinationResults = this.destinations?.filter((destination) =>
      //     destination?.city.toLowerCase().includes(searchTerm)
      //   );

      this.showDropdowns = this.searchText.length > 0;
    },

    selectedItem(item) {
      this.searchText = item.name || item.city;
      this.selectedProperty = item;
      console.log("selected property", item);
      this.showDropdowns = false;
    },
    updateMarkersAndCenter(data) {
      this.markers = [];

      data.forEach((property) => {
        const latitude = parseFloat(property?.property?.latitude);
        const longitude = parseFloat(property?.property?.longitude);

        this.markers.push({
          position: { lat: latitude, lng: longitude },
          clickable: true,
        });
      });

      const firstProperty = data?.[0]?.property;
      if (firstProperty) {
        this.center = {
          lat: parseFloat(firstProperty.latitude),
          lng: parseFloat(firstProperty.longitude),
        };
      }
    },
    async callLowestApi() {
    if(this.searchText) {
      const routeQueries = this.$route.query;
      const brand_code = routeQueries.brand_code;
      try {
        this.loading = true;
        // let isPropertyCode =
        //   this.selectedProperty.name === this.searchText ||
        //   this.selectedProperty.code === this.searchText;

        // let isCity = this.selectedProperty?.city === this.searchText;

        let payload = {
          property_codes: [this.selectedProperty.code],
          location: {
            brand_codes: [brand_code],
            radius: null,
            lat: null,
            long: null,
          },
          check_in_date: this.formattedCheckInDate,
          check_out_date: this.formattedCheckOutDate,
          number_of_person: this.number_of_person,
          include_default_rate_plan_strikethrough: true,
          enable_auto_promo_selection: true,
          number_of_rooms: 1,
          app_id: "BOOKING_ENGINE",
        };

        // if (isPropertyCode) {
        //   payload.property_codes = [this.selectedProperty.code];
        // } else if (isCity) {
        //   payload.city = this.selectedProperty?.city;
        // }

        const res = await commonServices.postBrandApi(payload);

        const data = await res.data;

        if (data && data.length > 0) {
          this.updateMarkersAndCenter(data);
        }

        this.hotels = data;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    }
    else {
      this.fetchAndPostBrandCode()
    }
    },
    async postBrandCode() {
      const routeQueries = this.$route.query;
      const brand_code = routeQueries.brand_code;
      try {
        this.loading = true;

        this.check_in_date = new Date();

        const nextDay = new Date(this.check_in_date);
        nextDay.setDate(this.check_in_date.getDate() + 1);
        this.check_out_date = nextDay;

        let property_codes = this.brand_code?.properties?.filter(property => property?.property_type === 'HOTEL')?.map(
          (property) => property?.code
        );

        const payload = {
          property_codes: property_codes,
          brand_codes: [brand_code],
          check_in_date: this.formattedCheckInDate,
          check_out_date: this.formattedCheckOutDate,
          number_of_person: 1,
          include_default_rate_plan_strikethrough: true,
          enable_auto_promo_selection: true,
          number_of_rooms: 1,
          app_id: "BOOKING_ENGINE",
        };

        const res = await commonServices.postBrandApi(payload);

        const data = await res.data;

        if (data && data.length > 0) {
          this.updateMarkersAndCenter(data);
        }

        this.hotels = data;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async fetchAndPostBrandCode() {
      const routeQueries = this.$route.query;
      const brand_code = routeQueries.brand_code;

      // let params = code ? { code } : {code: 'HK'};
      let params = {
        code: brand_code
      }

      try {
        this.loading = true;
        const res = await commonServices.getBrandCodes(params);
        const data = res.data;
        this.brand_code = data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }

      this.postBrandCode();
    }
  },

  computed: {
    ...mapState({
      mobileView: (state) => state.mobileView,
    }),
    currency() {
      return this.hotels?.[0]?.property?.currency;
    },
    formattedCheckInDate() {
      return formatDate(String(this.check_in_date), "YYYY-MM-DD");
    },
    formattedCheckOutDate() {
      return formatDate(String(this.check_out_date), "YYYY-MM-DD");
    },
    brandCodeLogo() {
      return this.brand_code?.image_url;
    },
    brandCodeName() {
      return this.brand_code?.name;
    },
    filteredHotels() {
      if (this.searchText) {
      const searchTextLower = this.searchText.toLowerCase();

      return this.hotels?.filter(hotel => {
        const nameMatch = hotel?.property?.name?.toLowerCase()?.includes(searchTextLower);
        const codeMatch = hotel?.property?.code?.toLowerCase()?.includes(searchTextLower);
        const cityMatch = hotel?.property?.city?.toLowerCase()?.includes(searchTextLower);

        return nameMatch || codeMatch || cityMatch;
      });
    }
    return this.hotels;
    }
  },
  watch: {
    searchText(newValue) {
      this.searchText = newValue;
    },
  },
  async beforeMount() {
    this.fetchAndPostBrandCode()
  },
  mounted() {
    let getUrl = window.location;
    let baseUrl =
      getUrl.protocol +
      "//" +
      getUrl.host +
      "/" +
      getUrl.pathname.split("/")[1];

    this.baseUrlVariable = baseUrl;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/config/mixins";
@import "@/assets/sass/config/variables";
.prices-content {
  min-width: 148px;
  justify-content: space-between;
  height: 100%;
}


.book-btn {
  width: 100%;
}

div :deep(.gm-ui-hover-effect) {
  display: none !important;
}

.el-popover {
  max-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-card-pic {
  background-color: lightgray;
  max-width: 250px;
  min-width: 200px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  height: 150px;
  .no-img-icon {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-bottom: 5px;
  }
}

.sold-out {
  color: #be2827;
}

.start-from {
  font-size: 12px;
  color: #003d0a;
}

.properties-divider {
  padding: 8px;
}

.divider:not(:last-child) {
  border-bottom: 1px solid #d4d4d4;
}

:deep(.el-date-editor.el-input) {
  width: auto;
}

.fit-content {
  height: fit-content;
}

label {
  color: #333333;
}

.card {
  margin-bottom: 0px;
}

.brand-name {
  margin: 0;
}

.search-card {
  box-shadow: 0 0 5px #ccc;
  padding: 5px;
  border-radius: 5px;
}

.search-container {
  position: relative;
}

.search-result {
  position: absolute;
  z-index: 9999999;
  width: 100%;
  background-color: #fff;
  height: auto;
  max-height: 250px;
  overflow: auto;
}

.map-container {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.map-container l-map {
  height: 100%;
}

.input-number {
  height: 39px;
  width: 150px;
}

.amenity {
  display: flex;
  flex-wrap: wrap;
  max-width: 195px;
  height: 60px;
  overflow: auto;
}
.amenity::-webkit-scrollbar {
  display: none;
}

.main-content {
  margin-top: 100px;
}

@include media("<=desktop") {
  .mobile-search {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }
  .mobile-search div {
    width: 100%;
    padding: 0px;
  }

  .mobile-btn {
    width: 100%;
  }

  .mobile-content {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }

  .mobile-content section {
    width: 100%;
  }

  :deep(.el-date-editor.el-input) {
    width: 100%;
  }

  .main-content {
    margin-top: 30px;
  }
}

@include media("<=tablet") {
  .mobile-search {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }
  .mobile-search div {
    width: 100%;
  }

  .mobile-btn {
    width: 100%;
  }

  .mobile-content {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }

  .mobile-content section {
    padding: 0px 10px;
  }
  .images img {
    width: 50px !important;
    height: 50px !important;
  }

  .list-card-pic {
    max-width: 100px;
    min-width: 100px;
    height: 80px;
  }

  .no-img span {
    font-size: 10px;
  }

  :deep(.el-date-editor.el-input) {
    width: 100%;
  }

  .amenity {
    max-width: 80px;
  }

  .book-btn {
    width: fit-content;
  }

  .sold-out {
    margin: 0px;
  }

  .book-box {
    display: flex;
    justify-content: end;
  }

  .prices-content {
    min-width: 0px;
  }

  .main-content {
    margin-top: 30px;
  }
}

.empty {
  width: 24px;
  height: 24px;
  margin-right: 2rem;
}

@media screen and (max-width: 1285px) and (min-width: 1025px){
  .fit-content {
    width: 60%;
  }

  // .search-destinations {
  //   width: 56% !important;
  // }

  // .search-inputs {
  //   width: 42% !important;
  // }

  .input-number {
    width: 120px;
  }
}

</style>
