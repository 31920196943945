<template>
  <hk-dropdown v-if="!hideDropDown">
    <div class="dropdown-custom"
         type="primary"
         slot="activator">
      <users style="width:15px;font-size: 14px;" class="user-icon"></users>
      <span style="padding-left: 10px">{{ adultsAndChildren }}</span>
    </div>
    <div slot="dropdown" class="guests-dropdown" v-for="(item, index) in rooms" :key="index">
      <div class="room-number"> {{ $t("lbl_room") }} {{ (roomIndex || index) + 1 }}</div>
      <div class="rooms-chosen-dropdown" style="font-size: 14px; font-weight: 400">
        <div>
          <span>{{ $t("lbl_adults") }}</span>
          <stepper
            stepper
            @increase="item.persons.adults++; guestsChanged(item, (roomIndex || index) || 0)"
            @decrease="item.persons.adults--; guestsChanged(item, (roomIndex || index) || 0)"
            :min="1"
            :max="maxGuests(item, item.persons.adults)"
            :model.sync="item.persons.adults"></stepper>
        </div>
        <div>
          <span>{{ $t("lbl_children") }}</span>
          <stepper
            @increase="item.persons.children++;guestsChanged(item, (roomIndex || index) || 0)"
            @decrease="item.persons.children--;guestsChanged(item, (roomIndex || index) || 0)"
            :min="0"
            :max="maxGuests(item, item.persons.children)"
            :model.sync="item.persons.children"></stepper>
        </div>
      </div>
    </div>
  </hk-dropdown>
  <div v-else>
    <div class="guests-dropdown" v-for="(item, index) in rooms" :key="index">
      <div class="room-number"> Room {{ +(roomIndex || index) + 1 }}</div>
      <div class="rooms-chosen-dropdown" style="font-size: 14px; font-weight: 400">
        <div>
          <span>{{ $t("lbl_adults") }}</span>
          <stepper
            stepper
            @increase="item.persons.adults++; guestsChanged(item, (roomIndex || index) || 0)"
            @decrease="item.persons.adults--; guestsChanged(item, (roomIndex || index) || 0)"
            :min="1"
            :max="maxGuests(item, item.persons.adults)"
            :model.sync="item.persons.adults"></stepper>
        </div>
        <div>
          <span>{{ $t("lbl_children") }}</span>
          <stepper
            @increase="item.persons.children++;guestsChanged(item, (roomIndex || index) || 0)"
            @decrease="item.persons.children--;guestsChanged(item, (roomIndex || index) || 0)"
            :min="0"
            :max="maxGuests(item, item.persons.children)"
            :model.sync="item.persons.children"></stepper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HkDropdown from "../../../../components/common/hk-dropdown";
import stepper from "../../../../components/stepper";
import {UsersIcon} from "vue-feather-icons";
import { mapState } from 'vuex';

export default {
  name: "GuestsFilter",
  props: ['searchData', 'hideDropDown', 'roomIndex'],
  components: {HkDropdown, stepper, users: UsersIcon},
  data() {
    return {}
  },
  computed: {
    ...mapState({
      propertyDetails: state => state.abs.property
    }),
    adultsAndChildren() {
      if (!isNaN(this.roomIndex)) {
        let allRoomsAdults = this.searchData.rooms[this.roomIndex].persons.adults
        let allRoomsChildren = this.searchData.rooms[this.roomIndex].persons.children
        return `${allRoomsAdults} Adults, ${allRoomsChildren} Children`
      } else {
        let allRoomsAdults = this.searchData.rooms.reduce((b, a) => b + a.persons.adults, 0)
        let allRoomsChildren = this.searchData.rooms.reduce((b, a) => b + a.persons.children, 0)
        return `${allRoomsAdults} Adults, ${allRoomsChildren} Children`
      }
    },
    rooms() {
      const roomIndex = this.roomIndex ? this.roomIndex : 0
      if (!isNaN(this.roomIndex)) {
        return [this.searchData.rooms[roomIndex]];
      } else {
        return this.searchData.rooms
      }
    },
    maxNumberOfGuests(){
      return this.propertyDetails?.max_occupancy || 6
    }
  },
  methods: {
    guestsChanged(item, index) {
      let payload = {
        guests: item,
        guestIndex: index
      }
      this.$emit('guestsChanged', payload)
    },
    maxGuests(item, val){
      return  (item.persons.adults + item.persons.children) >= this.maxNumberOfGuests ? val : this.maxNumberOfGuests
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/sass/config/variables";
@import "../../../../assets/sass/config/mixins";

.dropdown-custom {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 53.5px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  // margin-left: 10px;

  .user-icon {
    color: $hk-primary-color;
  }
}

.guests-dropdown {
  padding: 20px 20px 0 20px;
  width: 250px;

  .room-number {
    display: flex;
    margin-bottom: 5px;
    color: #b1b1b1;
    font-size: 12px;
  }

  .add-another-room {
    font-size: 11px;
    margin-top: 3px;
    cursor: pointer;
  }

  .rooms-chosen-dropdown {
    margin-bottom: 10px;
    border-bottom: 1px solid #dadada;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .room-number {
      display: flex;
      margin-bottom: 5px;
      color: #b1b1b1;
    }

    .remove {
      color: #409eff;
      cursor: pointer;
    }
  }
}

@include media('<=tablet') {
  .dropdown-custom {
    // line-height: 40px;
  }
  .guests-dropdown {
    padding: 10px 10px 0 10px;
    width: 100%;
  }
}


</style>
