<template>
  <el-select
    v-model="select_rooms"
    :placeholder="'Select Room'">
    <el-option
      v-for="item in rooms"
      :key="item"
      :label="item + (item > 1 ? ` ${'Rooms'}` : ` ${'Room'}`)"
      :value="item">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "RoomCountPicker",
  created() {
    const route_queries = this.$route.query;
    if (route_queries.room_count) this.select_rooms = +route_queries.room_count
  },
  data() {
    return {
      select_rooms: 1,
      rooms: Array.apply(null, {length: 6 + 1}).map(Number.call, Number).slice(1)
    }
  },
  watch: {
    select_rooms(newValue) {
      this.$emit('selectRoomCount', newValue)
    }
  },
}
</script>

<style scoped>

</style>
