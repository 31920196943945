// import BookingPage from '@/views/booking/booking.vue';
import BookingPage from '../views/booking-v2/booking-engine';
import VueRouter from 'vue-router'
import Vue from 'vue';
import SearchReservationWrapper from '@/views/booking-v2/search-reservation/SearchReservationWrapper'
import SearchReservation from '@/views/booking-v2/search-reservation/SearchReservation'
import SearchReservationResult from '@/views/booking-v2/search-reservation/SearchReservationResult'
import CancelReservation from  '@/views/booking-v2/search-reservation/CancelReservation'
import GuestDetails from '@/views/booking-v2/search-reservation/guest-details/GuestDetails'
import AddPayment from '@/views/booking-v2/search-reservation/payment/Payment'
import ConfirmPaymentMethod from '@/views/booking-v2/search/ConfirmPaymentMethod'
import Search from  '@/views/booking-v2/search/Search'
import SelectRoom from  '@/views/booking-v2/select/SelectRoom'
import SelectAddons from '@/views/booking-v2/addons/SelectAddons'
import Information from '@/views/booking-v2/information/Information'
import PaymentV1 from  '@/views/booking-v2/payment/payment-v1'
import Confirmation from '@/views/booking-v2/confirmation/Confirmation'
import EventsApp from '@/views/events/EventsApp'
import BookingApp from '@/views/booking-v2/BookingApp'
import EventSteps from '@/views/events/EventSteps'
import SelectSpace from '@/views/events/events-steps/SelectSpace/SelectSpace'
import SelectPackage from '@/views/events/events-steps/SelectPackage/SelectPackage.vue'
import GuestInformation from '@/views/events/events-steps/GuestInformation/GuestInformation.vue'
import Payments from '@/views/events/events-steps/Payments/Payments.vue'
import EventConfirmation from '@/views/events/events-steps/Confirmation'
import AbsSelectDates from  '@/views/abs/abs-steps/SelectDates'
import AbsApp from '@/views/abs/AbsApp'
import AbsSteps from '@/views/abs/AbsSteps'
import PointsRedemption from '@/views/booking-v2/points-redemption/PointsRedemption'
import ActivitiesApp from '@/views/activities/ActivitiesApp'
import ActivitySteps from '@/views/activities/ActivitySteps'
import SelectActivity from '@/views/activities/activity-steps/SelectActivity/SelectActivity.vue'
import ActivityDetails from '@/views/activities/activity-steps/ActivityDetails/ActivityDetails.vue'
import ActivityGuestInformation from '@/views/activities/activity-steps/GuestInformation/GuestInformation'
import ActivityPayments from '@/views/activities/activity-steps/Payments/Payments'
import ActivityEventConfirmation from '@/views/activities/activity-steps/Confirmation/Confirmation'

import SearchV2 from "@/views/booking-v2/search-v2/Search.vue"



Vue.use(VueRouter);
const routes = [
  {
    path: '/brand',
    name: 'brand-page',  
    component: SearchV2,
  },
  {
    path: '/',
    name: '/',
    redirect: 'BookingPage',
    component: BookingApp,
    children: [
      {
        path: '/',
        name: 'BookingPage',
        component: BookingPage,
        children: [
          {
            path: 'booking/search',
            name: 'booking-search',
            component: Search ,
            children: [
              {
                path: 'booking/search/confirm-payment-method',
                name: 'confirm-payment-method',
                component: ConfirmPaymentMethod,
              },
            ]
          },
     
          {
            path: 'booking/select-rooms',
            name: 'booking-select-rooms',
            component: SelectRoom,
          },
          {
            path: 'booking/select-addons',
            name: 'booking-select-addons',
            component: SelectAddons,
          },
          {
            path:'booking/points-redemption',
            name:'booking-points-redemption',
            component: PointsRedemption
          },
          {
            path: 'booking/guest-information',
            name: 'booking-guest-information',
            component: Information,
          },
          {
            path: 'booking/payments',
            name: 'booking-payments',
            component: PaymentV1,
          },
          {
            path: 'booking/confirmation',
            name: 'booking-confirmation',
            component: Confirmation,
          },
        ]
      },
      {
        path: '/search-reservation-wrapper',
        name: 'search-reservation-wrapper',
        component: SearchReservationWrapper,
        redirect: {name: 'search-reservation'},
        children: [
          {
            path: '/search-reservation',
            name: 'search-reservation',
            component: SearchReservation,
          },
          {
            path: '/search-reservation-result',
            name: 'search-reservation-result',
            component: SearchReservationResult,
          },
          {
            path: '/cancel-reservation',
            name: 'cancel-reservation',
            component: CancelReservation,
          },
          {
            path: '/guest-details',
            name: 'guest-details',
            component: GuestDetails,
          },
          {
            path: '/add-payment',
            name: 'add-payment',
            component: AddPayment,
          }
        ]
      },
    ]
  },
  {
    path: '/events',
    name: 'events',
    component: EventsApp,
    redirect: 'events/event-steps',
    children: [
      {
        path: '/events/event-steps',
        name: 'event-steps',
        component: EventSteps,
        children: [
          {
            path: '/events/event-steps/select-space',
            name: 'select-space',
            component: SelectSpace,
          },
          {
            path: '/events/event-steps/select-package',
            name: 'select-package',
            component: SelectPackage,
          },
          {
            path: '/events/event-steps/guest-information',
            name: 'guest-information',
            component: GuestInformation,
          },
          {
            path: '/events/event-steps/payments',
            name: 'payments',
            component: Payments,
          },
          {
            path: '/events/event-steps/confirmation',
            name: 'confirmation',
            component: EventConfirmation,
          },
        ]
      },

    ]
  },
  {
    path:  '/activities',
    name: 'activities',
    component: ActivitiesApp,
    children: [
      {
        path: '/activities/activity-steps',
        name: 'activity-steps',
        component: ActivitySteps,
        children: [
          {
            path: '/activities/activity-steps/select-activity',
            name: 'select-activity',
            component: SelectActivity,
          },
          {
            path: '/activities/activity-steps/activity-details',
            name: 'activity-details',
            component: ActivityDetails,
          },
          {
            path: '/activities/activity-steps/guest-information',
            name: 'activity-guest-information',
            component: ActivityGuestInformation,
          },
          {
            path: '/activities/activity-steps/payments',
            name: 'activity-payments',
            component: ActivityPayments,
          },
          {
            path: '/activities/activity-steps/confirmation',
            name: 'activity-confirmation',
            component: ActivityEventConfirmation,
          }
        ]
      }
    ]
  },
  {
    path: '/abs',
    name: 'abs',
    component: AbsApp,
    redirect: 'abs/abs-steps',
    children: [
      {
        path: '/abs/abs-steps',
        name: 'abs-steps',
        component: AbsSteps,
        children: [
          {
            path: '/abs/abs-steps/select-dates',
            name: 'select-dates',
            component: AbsSelectDates,
          },
          
        ]
      },

    ]
  },
  {
    path: '/*',
    redirect: '/',
  }
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    let toWithQuery = Object.assign({}, to, {query: from.query});
    next(toWithQuery);
  } else {
    next()
  }
})

export default router;
