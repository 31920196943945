<template>
  <div v-if="getDisplayType === 'GRID_VIEW'">
    <div class="wrapper" v-if="getFilteredRooms && getFilteredRooms.length > 0 && getDisplayByFilter === 'byRooms'">
      <DisplayByRoomsV2
        :roomIndex="roomIndex"
        :getFilteredRooms="getFilteredRooms"
        :bookingInProgress="bookingInProgress"
        :selectedData="selectedData"
        @removeRoom="removeRoom"
        @bookRoom="bookRoom"
      />
    </div>
    <DisplayByRatesV2
      v-else-if="getFilteredRooms && getFilteredRooms.length > 0 && getDisplayByFilter === 'byRates' && rateCodes && rateCodes.length >0"
      ref="rates"
      :getFilteredRooms="getFilteredRooms"
      :roomTypes="roomTypes"
      :packageChargeTypes="packageChargeTypes"
      :roomIndex="roomIndex"
      :selectedData="selectedData"
      :packageRateCode="packageRateCode"
      :key="rateKey"
      :bookingInProgress="bookingInProgress"
      @removeRoom="removeRoom"
      @bookRoom="bookRoom($event)"
    />
    <div v-else class="m-t-20">
      <el-alert
        :title="$t('lbl_no_result_found_for_the_selected_dates')"
        type="info">
        </el-alert>
    </div>
  </div>
  <div v-else>
    <div class="wrapper" v-if="getFilteredRooms && getFilteredRooms.length > 0 && getDisplayByFilter === 'byRooms'">
      <DisplayByRooms
        :roomIndex="roomIndex"
        :getFilteredRooms="getFilteredRooms"
        :bookingInProgress="bookingInProgress"
        :selectedData="selectedData"
        @removeRoom="removeRoom"
        @bookRoom="bookRoom"
      />
    </div>
    <DisplayByRates
      v-else-if="getFilteredRooms && getFilteredRooms.length > 0 && getDisplayByFilter === 'byRates' && rateCodes && rateCodes.length >0"
      ref="rates"
      :getFilteredRooms="getFilteredRooms"
      :roomTypes="roomTypes"
      :packageChargeTypes="packageChargeTypes"
      :roomIndex="roomIndex"
      :selectedData="selectedData"
      :packageRateCode="packageRateCode"
      :key="rateKey"
      :bookingInProgress="bookingInProgress"
      @removeRoom="removeRoom"
      @bookRoom="bookRoom($event)"
    />
    <div v-else class="m-t-20">
      <el-alert
        :title="$t('lbl_no_result_found_for_the_selected_dates')"
        type="info">
        </el-alert>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import CommonService from '../../../services/common.services';
import dayJs from "dayjs";
import DisplayByRates from "./DisplayByRates.vue";
import DisplayByRatesV2 from "./DisplayByRatesV2.vue";
import DisplayByRooms from "./DisplayByRooms";
import DisplayByRoomsV2 from "./DisplayByRoomsV2.vue";
import ApiCalls from '@/services/api-calls.service.js'

export default {
  name: "Rooms",
  props: ["model", "rateCodes", "rateDetails", "roomTypes", "packageChargeTypes", "roomIndex", "packageRateCode"],
  components: {DisplayByRooms,DisplayByRoomsV2 , DisplayByRates, DisplayByRatesV2},
  data() {
    return {
      rooms: [],
      descMaxLength: 202,
      selectedData: null,
      bookingInProgress: false,
      rateKey: 0,
    }
  },
  computed: {
    ...mapState({
      mobileView: state => state.mobileView,
      sessions: state => state.profile.sessions,
      search_data: state => state.search_data,
      discount_applied: state => state.discount_applied,
      applicablePackage: state => state.applicablePackage,
      rate_content: state => state.rate_content,
      selected_room_details: state => state.selected_room_details,
      allFilters: state => state.allFilters,
      room_type_amenity_groups: state => state.room_type_amenity_groups,
      bed_type_groups: state => state.bed_type_groups,
      packages: state => state.packages,
      steps: state => state.steps,
      group_no: state => state.group_no,
      company_code: state => state.company_code,
      property: state => state.property.details,
      mop: state => state.mop,
      rwa_data: state => state.rwa_data,
      enable_book_with_points: state => state.enable_book_with_points,
      logged_in_user_details: state => state.logged_in_user_details,
      enable_multi_room_booking: state => state.property.details.website_config?.enable_multi_room_booking,
      number_of_rooms: state => state.number_of_rooms
    }),
    getDisplayByFilter() {
      return this.allFilters[this.roomIndex]?.displayBy
    },
    getFilteredRooms() {
      let result = this.rooms
      if (this.allFilters && this.allFilters[this.roomIndex]) {
        if (this.allFilters[this.roomIndex] && this.allFilters[this.roomIndex]?.bedType?.length > 0) {
          let roomTypeIds = this.bed_type_groups[this.roomIndex].filter(b => this.allFilters[this.roomIndex].bedType.find(af => b.object_id === af))
          roomTypeIds = [...new Set(roomTypeIds.flatMap(rids => rids.room_type_ids))]
          result = result.filter(r => roomTypeIds.includes(r.room_type_id))
        }
        if (this.allFilters[this.roomIndex].features && this.allFilters[this.roomIndex]?.features?.length > 0) {
          let roomTypeIds = this.room_type_amenity_groups[this.roomIndex].filter(b => this.allFilters[this.roomIndex].features.find(af => b.object_id === af))
          roomTypeIds = [...new Set(roomTypeIds.flatMap(rids => rids.room_type_ids))]
          result = result.filter(r => roomTypeIds.includes(r.room_type_id))
        }
        if (this.allFilters[this.roomIndex].roomTypes && this.allFilters[this.roomIndex]?.roomTypes?.length > 0) {
          let filteredRoomTypes = this.roomTypes[this.roomIndex]?.filter(r => this.allFilters[this.roomIndex].roomTypes.find(item => item === r.room_type_category?.code))
          let roomTypeIds = [...new Set(filteredRoomTypes.flatMap(rids => rids.id))]
          result = result.filter(r => roomTypeIds.includes(r.room_type_id))
          console.log({filteredRoomTypes})
          // let roomTypeIds = this.room_type_amenity_groups[this.roomIndex].filter(b => this.allFilters[this.roomIndex].roomTypes.find(af => b.object_id === af))
          // roomTypeIds = [...new Set(roomTypeIds.flatMap(rids => rids.room_type_ids))]
          // result = result.filter(r => roomTypeIds.includes(r.room_type_id))
        }
        if (this.allFilters[this.roomIndex].bedCountChecked && this.allFilters[this.roomIndex].bedCount > 0) {
          let filteredRoomTypes = this.roomTypes[this.roomIndex]?.filter(r => r.bed_count >= this.allFilters[this.roomIndex].bedCount)
          let roomTypeIds = [...new Set(filteredRoomTypes.flatMap(rids => rids.id))]
          result = result.filter(r => roomTypeIds.includes(r.room_type_id))
        }
        if (this.allFilters[this.roomIndex].smoking) {
          let filteredRoomTypes = this.roomTypes[this.roomIndex]?.filter(r => r.smoking)
          let roomTypeIds = [...new Set(filteredRoomTypes.flatMap(rids => rids.id))]
          result = result.filter(r => roomTypeIds.includes(r.room_type_id))
        }
        if (this.allFilters[this.roomIndex].accessible) {
          let filteredRoomTypes = this.roomTypes[this.roomIndex]?.filter(r => r.accessible)
          let roomTypeIds = [...new Set(filteredRoomTypes.flatMap(rids => rids.id))]
          result = result.filter(r => roomTypeIds.includes(r.room_type_id))
        }
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.rateKey++
      return result
    },
    getDisplayType(){
      return this.property?.website_config?.rate_display_type
    },
    enableMultiRoomBooking() {
      return this.property.website_config?.enable_multi_room_booking
    },
    getNights(){
        return dayJs(this.search_data.checkOut).utc().diff(dayJs(this.search_data.checkIn).utc(), 'days')
    },
  },
  beforeMount() {
    this.rooms = this.model.map(item => ({...item, showMore: true}))
  },
  methods: {
    ...mapMutations({
      CHANGE_STEP: 'CHANGE_STEP',
      SET_STAY_DETAILS: 'SET_STAY_DETAILS',
      SET_RATE_CONTENT: 'SET_RATE_CONTENT',
    }),
    ...mapActions(["updateSelectedRoom"]),
    removeRoom() {
      let payload = {action: "REMOVE", room_details: {room_no: this.roomIndex}}
      this.updateSelectedRoom(payload)
    },
    async companyReservation(session_id, rate_code_id) {
      let selectedRate = this.rwa_data?.rate_codes?.find(r => r.id == rate_code_id)
      try {
        const url = `${this.property.id}/booking-engine/new-booking/${session_id}/company-reservations`
        let payload = {
          reservation_company: {
            company_id: this.company_code, //Code of the local company
            mop: this.mop || "COMPANY",
            is_local: true,
            company_rates_applied: selectedRate?.is_company_rate_code || false
          }
        }
        await ApiCalls.post(url, payload, {}, 'propertyUrl')
      } catch (companyResrErrror) {
        console.log({companyResrErrror})
      }
    },
    async bookRoom({room, rate, roomIndex, rateIndex, points}) {
      this.selectedData = {
        roomIndex,
        rateIndex
      }
      let currentRoom = this.selected_room_details.find(room => room.room_no === this.roomIndex)
      if (currentRoom) {
        this.removeRoom()
      }

      let payload = this.buildPayload(room, rate)
      if (points) {
        payload.booked_with_points = true
        payload.total_redeemed_points = Math.min(points, this.logged_in_user_details.member_points.redeemable)
      }
      else {
        payload.booked_with_points = false
      }
      let session_id = this.sessions[this.roomIndex];

      this.bookingInProgress = true

      if (this.company_code)
        await this.companyReservation(session_id, rate.id)

      CommonService.setRate(payload, session_id)
        .then(async ({data}) => {
          let roomDetailsFromResponse = {
            action: "ADD",
            room_details: {...data.content, room_no: this.roomIndex, room_unit_number: room.room_unit_number}
          }

          roomDetailsFromResponse.room_details.room = this.roomDetails(room),
            roomDetailsFromResponse.room_details.rate = this.rateCodeDetails(rate),

            this.getProfileTaxes(data.session_id, room, roomDetailsFromResponse)

        })
        .catch((err) => {
          this.bookingInProgress = false
          console.log(err);
        })
        .finally(() => {
        })

      

          let gtagPayload = {
            item_list_id: this.property?.code, // property code 
            item_list_name: this.property?.name, // Property name 
            items: [
              {
                item_id: room?.room_type_code, // Room type code 
                item_name: room?.room_type_name, // Room type name 
                affiliation: "", // empty 
                coupon: this.discount_applied?.name ? this.discount_applied?.name : "",// Promo code if applied 
                discount: 0, // This can be 0
                index: roomIndex, // Order of room type
                item_brand: "", // empty 
                item_category: "", // empty 
                item_category2: "", // empty 
                item_category3: "", // empty 
                item_category4: "", // empty 
                item_category5: "", // empty 
                item_list_id: "", // empty 
                item_list_name: "", // empty
                item_variant: "", // Rate code code 
                location_id: null, // null
                price: rate?.price_summary?.total_amount,// amount we show on the screen  - not clear
                quantity: this.getNights // number of nights 
              }
            ]
          }

        if(window.dataLayer && typeof window.gtag === 'function') {
        // Google analityc on select room
          gtag("event", "select_item", gtagPayload);
          console.log('Event: select_item', gtagPayload)
         }

         if(window.dataLayer && this.property?.website_config?.google_analytics_ids?.startsWith("GTM")) {
          // GTM on select room
          gtagPayload.event = "select_item"
          window.dataLayer.push(gtagPayload)

          console.log('Event: select_item', gtagPayload)
         }
    },
    buildPayload(room, rate) {

      let roomSearchData = this.search_data.rooms[this.roomIndex];
      let payload = {
        check_in_date: dayJs(roomSearchData.checkIn).format('YYYY-MM-DD'),
        check_out_date: dayJs(roomSearchData.checkOut).format('YYYY-MM-DD'),
        rate_code_id: rate.id,
        room_type_id: room.room_type_id,
        package_id: null,
        number_of_adults: roomSearchData.persons.adults,
        number_of_children: roomSearchData.persons.children,
        number_of_rooms: !this.enable_multi_room_booking ? 1 :  (this.number_of_rooms || this.search_data.rooms.length), 
        children_ages: roomSearchData?.persons?.children_ages?.map(age => String(age)),
        rate_details: [],
      }
      if("room_id" in room)
        payload.room_id =  room.room_id || null

      if (rate.hasOwnProperty('package_id')) {
        payload.package_id = rate.package_id
      }

      if (this.discount_applied) {
        payload.discount_id = this.discount_applied.id;
      }
      if (this.group_no) {
        payload.group_no = this.group_no
        let selectedRate = this.rwa_data?.rate_codes?.find(r => r.id == rate.id)
        if (selectedRate?.exception) {
          payload.rate_details = this.rwa_data.rate_details.find(r => r.room_type_id === room.room_type_id)
            ?.rate_codes.find(rc => rc.id === rate.id)?.price_details
            .map(rd => ({date: rd.date, amount: rd.amount}))
        }
      }
      if (this.applicablePackage) {
        payload.package_id = this.applicablePackage.id;
      }

      return payload
    },
    async getProfileTaxes(session_id, room, roomDetailsFromResponse) {
      CommonService.getProfileFolioTaxes(session_id)
        .then(async ({data}) => {
          // data.room = room
          roomDetailsFromResponse.room_details.profileTaxes = data
          // this.SET_PROFILE_TAXES(data);
          this.updateSelectedRoom(roomDetailsFromResponse)
          if (this.roomIndex < this.search_data.rooms.length - 1) {
            this.$emit('changeRoomIndex', (this.roomIndex + 1).toString())
          }


          if (this.search_data.rooms.length === this.selected_room_details.length || this.enableMultiRoomBooking) {
            if (this.steps && this.steps['addons'] && !this.selected_room_details.some(s => s.package_id) && !this.enable_book_with_points)
              await this.CHANGE_STEP('addons');
            else if(this.enable_book_with_points)
              await this.CHANGE_STEP('pointsRedemption');
            else
              await this.CHANGE_STEP('information')
          }
          setTimeout(() => {
            this.bookingInProgress = false
          }, 200);

        })
        .catch((err) => {
          console.log(err);
        })
    },
    rateCodeDetails(item) {
      if (item.hasOwnProperty('package_id')) {
        item.additional_details = this.packages[this.roomIndex].find(pack => pack.id === item.package_id).description
        return item
      }
      return this.rateCodes[this.roomIndex]?.find(rate => rate.id === item.id)
    },
    roomDetails(item) {
      if (item.hasOwnProperty('package_id')) {
        return item
      }
      return this.roomTypes[this.roomIndex]?.find(room => room.id === item.room_type_id)
    }
  },
  mounted() {
      let roomItems = []
      this.getFilteredRooms?.forEach((room, index) => {

        let totalRoomPrice = room?.rate_codes?.reduce((sum, item) => sum + item?.price_summary?.total_amount, 0);

        roomItems.push({
            item_id: room?.room_type_code, // Room type code 
            item_name: room?.room_type_name, // Room type name 
            affiliation: "", // empty 
            coupon: this.discount_applied?.name ? this.discount_applied?.name : "",// Promo code if applied 
            discount: 0, // This can be 0
            index: index, // Order of room type
            item_brand: "", // empty 
            item_category: "", // empty 
            item_category2: "", // empty 
            item_category3: "", // empty 
            item_category4: "", // empty 
            item_category5: "", // empty 
            item_list_id: "", // empty 
            item_list_name: "", // empty
            item_variant: "", // Rate code code 
            location_id: null, // null
            price: totalRoomPrice,// total room rate for that room type and rate code
            quantity: this.getNights // number of nights 
        })
      })

      let gtagPayload = {
        item_list_id: this.property?.code, // property code 
        item_list_name: this.property?.name, // Property name 
        items: roomItems
      } 

    if(window.dataLayer && typeof window.gtag === 'function') {
      gtag("event", "view_item_list", gtagPayload);
      console.log('Event: view_item_list', gtagPayload)
    }

    if(window.dataLayer && this.property?.website_config?.google_analytics_ids?.startsWith("GTM")) {
      gtagPayload.event = "view_item_list"
      window.dataLayer.push(gtagPayload)
      console.log('Event: view_item_list', gtagPayload)
    }
  }

}
</script>
