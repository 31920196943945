import Vue from 'vue';
import CommonServices from "@/services/common.services";
import states from '../../states';



const GET_DISCOUNT = ({commit, rootState}, params) => {
  return CommonServices.getDiscount(params)
};

const getLocalizationKeys = ({commit}, langCode) => {
  const defaultLanguage = states?.property?.details?.property_languages?.find(lang => lang?.default)?.language_code
  const propertyLanguage = states?.property?.details?.language
  let locale = states.localization_language || defaultLanguage || propertyLanguage || getCookie('hk_language') || 'en_US' 

    let params = {
      app_id: 'HK_BOOKING_WEB',
      locale: langCode || locale
    };
  return CommonServices.getLocalizations(params)
}

export default {
  GET_DISCOUNT,
  getLocalizationKeys
}

const getCookie = (name) => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '')
};

