<template>
  <div class="modal-comp" v-if="showModal">
    <div class="modal" :style="{'width': '800px', 'height' : '650px'}">
      <section class="modal-card-body">
        <p class="text">{{ $t("lbl_payment_in_progress") }}...</p>
      </section>
    </div>
  </div>
</template>

<script>
import ApiCallsService from "../../../services/api-calls.service";

export default {
  name: "InstantPaymentHandler",
  props: {
    public_link: String
  },
  data(){
    return {
      request_id: '',
      showModal: false,
      pollTimer: null
    }
  },
  beforeMount() {
    if(this.public_link) {
      this.showModal = true

      const url = new URL(this.public_link.replace('#', ''))

      this.request_id = url.searchParams.get('request_id')
      this.createHandler()
    }
  },
  methods: {
    createHandler(){
      const payment_window = window.open(this.public_link)
      this.pollTimer = setInterval(async () => {
        try {
          let response = await ApiCallsService.get(`payment-links/${this.request_id}/status`, null, 'baseAppUrl')
          if(response.data.status === 'EXECUTED'){
            this.showModal = false
            payment_window.close()
            clearInterval(this.pollTimer)
          }
        } catch (error) {
          if(this.pollTimer)
            clearInterval(this.pollTimer)
          console.log("Error from payment: ", error)
        }
      }, 3000);
    }
  },
  beforeDestroy() {
    if(this.pollTimer)
      clearInterval(this.pollTimer)
  }
}
</script>

<style scoped lang="scss">

@import "~css_vars";
@import '~mixins';
@import "@/assets/sass/config/mixins";

.modal-card-body{
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-comp {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(#000, .3);
  display: flex;
  align-items: center;
  justify-content: center;


&.top-right {
   align-items: flex-start;
   justify-content: flex-end;
 }

&.top-left {
   align-items: flex-start;
   justify-content: flex-start;
 }

&.bottom-right {
   align-items: flex-end;
   justify-content: flex-end;
 }

&.bottom-left {
   align-items: flex-end;
   justify-content: flex-start;
 }

.modal {
  position: relative;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  height: auto;
  max-height: 90vh;
  min-width: 30vw;
  display: flex;
  flex-direction: column;
  margin: 10px 20px;

@include media("<=tablet") {
  height: 100%;
  max-height: 100%;
  width: 100%;
  min-height: 100%;
  margin: 0;
}
}

.modal-card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

&.blue-title {
   display: flex;
   align-items: center;
   background-color: #409eff;
   color: white;
 }
}

.blue-title > .close {
  color: white;
}

.modal-card-body {
  overflow: auto;
  margin: 0 10px;
  text-transform: initial;
  color: lighten(#000, 10);
  flex: 1;
}

.big {
  font-size: 25px;
}

.medium {
  font-size: 20px;
}
}

@media (max-width: 1024px) {
  .modal-comp {
  .modal {
    width: 450px;
  }
}
}
</style>
