var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._b({staticClass:"fd-form-group",class:{'is-disabled':_vm.disabled},style:({
  'border-top-right-radius': _vm.borderRadiusTopRight,
  'border-bottom-right-radius':_vm.borderRadiusBottomRight,
  'border-top-left-radius':_vm.borderRadiusTopLeft,
  'border-bottom-left-radius':_vm.borderRadiusBottomLeft,
  'border-right-width':_vm.borderRightWidth,
  'border-left-width':_vm.borderLeftWidth,
  'border-top-width':_vm.borderTopWidth,
  'border-bottom-width':_vm.borderBottomWidth,
  'justify-content': _vm.justifyContent,
  'border-color': _vm.borderColor,
  'padding': _vm.padding

})},'div',_vm.$attrs,false),[_vm._t("default",function(){return [_c('input',{staticStyle:{"position":"absolute","height":"0","width":"0","z-index":"-1","border":"0"},attrs:{"tabindex":"-1"},domProps:{"value":_vm.value}}),_c('textarea',_vm._g({directives:[{name:"disabled",rawName:"v-disabled",value:(_vm.disabled),expression:"disabled"}],staticClass:"form-field",class:{'is-danger': _vm.error},style:(_vm.inputStyle),attrs:{"type":_vm.type,"disabled":_vm.disabled,"autocomplete":"new-password","placeholder":_vm.placeholder,"name":_vm.controlName,"id":_vm.controlName,"maxlength":_vm.maxlength},domProps:{"value":_vm.value},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.click.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.$emit('tabPress')}}},_vm.inputListeners)),_c('label',{staticClass:"form-label",class:{'is-disabled':_vm.disabled},attrs:{"for":_vm.controlName}},[(_vm.showAsterisk)?_c('span',{staticClass:"asterisk"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),(_vm.error)?_c('span',{staticClass:"required",style:(_vm.requiredStyle)},[_vm._v(_vm._s(_vm.error))]):_vm._e(),(_vm.showButton)?_c('el-button',{staticClass:"postfix",style:(_vm.buttonStyle),attrs:{"type":"default","size":"mini","id":"search-button"},on:{"click":_vm.click}},[(_vm.loading)?_c('i',{staticClass:"el-icon-loading"}):_vm._e(),(_vm.buttonText && !_vm.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e(),(!_vm.buttonText && !_vm.loading)?_c('i',{class:_vm.buttonClassName}):_vm._e()]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }