import actions from './actions';
import getters from './getters';
import mutations from './mutations';


const state = {
    details: null,
    charge_types: null,
    amenities: null,
    room_types: null,
    property_currency: null
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
