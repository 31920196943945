import VueI18n from 'vue-i18n'
import Vue from 'vue'
import CommonUtils from '@/utils/index'

const messages = {}

Vue.use(VueI18n)

// Create VueI18n instance with options
export const i18n = new VueI18n({
    locale: CommonUtils.getCookie('hk_language').substring(0, 2), // set locale,
    fallbackLocale: 'en',
    messages, // set locale messages
})
