<template>
  <div >
    <div v-if="amenitiesInfo && getDisplayType == 'GRID_VIEW'" >
      <div class="flexed m-b-10">
        <el-tooltip class="item" effect="light" content="Number of persons allowed" placement="top-start">
        <span class="m-r-20 ">
            <i class="el-icon-user-solid"></i> {{ findRoomAddons.max_people }}
          </span>
        </el-tooltip>
        <span v-if="findRoomAddons.smoking">
          <el-tooltip class="item" effect="light" content="Smoking Room" placement="top-start">
            <i class="el-icon-smoking"></i>
        </el-tooltip>
        </span>
        <span v-if="!findRoomAddons.smoking">
          <el-tooltip class="item" effect="light" content="No Smoking Room" placement="top-start">
            <i class="el-icon-no-smoking"></i>
        </el-tooltip>
        </span>

      </div>
      <!-- <p style="margin: 0;"  class="text-left border-bottom p-b-10 m-t-0">Guest Room</p> -->
      <h4 style="margin: 0;" class="text-left border-top p-b-10">{{ $t("lbl_room_amenities") }}</h4>
    </div>
    <template v-if="!amenitiesInfo">
    <div class="flexed flexed-wrap">


      <div v-for="(amt, index) in amenities" :key="index">

        <el-popover
        placement="top-start"
          width="150"
          :trigger="mobileView ? 'click' : 'hover'"
          :content="translateValues(amt.object_type, amt.object_value, 'AMENITY', amt.object_id, 'object_value')"
        >
            <img class="amenitie-icon" slot="reference" :src="amt.icon" alt="" />
        </el-popover>

      </div>
    </div>
    </template>
    <template v-if="amenitiesInfo && getDisplayType == 'GRID_VIEW'" >
      <div class="amenities-list">

        <ul >
          <li v-for="(amt, index) in amenities" :key="index" class="amenities-info">
            <div class="flexed align-end">
              <span>
                <img class="amenitie-icon" slot="reference" :src="amt.icon" alt="" />

              </span>
              <span>{{translateValues(amt.object_type, amt.object_value, 'AMENITY', amt.object_id, 'object_value')}}</span>
            </div>
          </li>

        </ul>
      </div>
  </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { translateDynamicContent } from "@/services/utilities.service";


export default {
  name: "room-amenities",
  props: {
    room: {
      type: Object,
      required: true,
    },
    amenitiesInfo: {
      type: Boolean
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      property: (state) => state.property,
      mobileView: state => state.mobileView,
      roomTypes: state => state.room_types,
      room_type_amenity_groups: state => state.room_type_amenity_groups,
      multi_lingual_dynamic_fields: state => state.multi_lingual_dynamic_fields,
      property_language_code: state => state.property.details.language
    }),
    amenities() {
      let flattedAmenities = this.room_type_amenity_groups?.flatMap(group => group) || [];
      let enhancedAmenities = [];

      return this.roomsWithAmenities(this.room)?.amenities?.map(amenity => {
        let amenityObj = flattedAmenities?.find(item => item?.object_value == amenity?.name);
        let enhancedAmenity = { ...amenity, ...amenityObj };
        enhancedAmenities.push(enhancedAmenity);

        return enhancedAmenity;
      }) || [];
    },
    findRoomAddons() {
      if(this.room) {
        let currentRoom = this.roomTypes?.[0]?.find(item => item?.id == this.room?.room_type_id)
        return currentRoom
      }
    },
    getDisplayType(){
      return this.property?.details?.website_config?.rate_display_type
    }
  },
  methods: {
    roomsWithAmenities(room) {
      return this.property?.amenities?.find(
        (item) => item.name === room?.room_type_name
      );
    },
    translateValues(object_code, value, object_type, object_id, field_name) {
      return translateDynamicContent(this.multi_lingual_dynamic_fields, object_code, value, object_type, this.property_language_code, object_id, field_name)
    }

  },
  beforeMount() {
    this.findRoomAddons
  }
};
</script>

<style>

.amenities-list ul {
  list-style: disc !important;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  max-height: 80px;
  /* overflow: auto; */
}

/* .amenities-list li {
  list-style: disc !important;

  display: flex;
  align-items: center;
  margin-left: 20px;
} */

.amenitie-icon {
  width: 28px;
  height: 20px;
  padding-right: 5px;
}

.amenities-info div{
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto; */
  min-width: 100px;
}

.amenities-info {
   margin-left: 20px;
}

:deep(.amenities-info::marker) {
  margin-bottom: 40px;
}


.el-popover  {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px !important;
}
</style>
