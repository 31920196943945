<template>
  <div class="payment-frame m-t-20 card-border">
    <div class="flexed justify-center align-center m-l-30 m-t-20" v-if="loading">
      <loader></loader>
    </div>
    <iframe :src="frame_src"
            height="530px"
            width="550px"
            frameborder="0"
            @load="frameLoaded"
            v-show="!loading"
    ></iframe>
  </div>
</template>

<script>
import eventApiCall from '../../../../services/events/api-calls-events.service'
import {mapState} from "vuex";
import loader from '@/components/common/loading.vue';
import apiCallsEventsService from "../../../../services/events/api-calls-events.service";

export default {
  name: 'PaymentFrame',
  components:{
    loader
  },
  data(){
    return{
      loading: true,
      frame_src: '',
      session_key: ''
    }
  },
  methods:{
    async getData(){
      this.loading = true
      try{
        let result = await apiCallsEventsService.get(`${this.property.id}/payments/hpc/init`, null, 'baseAppUrlProperties')
        this.extractFrameUrl(result.data)
      }catch(e){
        console.log(e)
      }

    },
    extractFrameUrl(frame_src){
      let div = document.createElement('div')
      div.innerHTML = frame_src.body
      console.log(frame_src)
      this.frame_src = div.firstChild.src
      this.session_key = frame_src.session_key
    },
    frameLoaded() {
      this.loading = false
      window.addEventListener('message', this.handler, false);
    },
    handler(event) {
      // if(event.origin){
      //   if((new URL(this.extractFrameUrl())).hostname != (new URL(event.origin)).hostname) return
      // }
      // else return
      //
      let data = event.data
      if (data.data?.hasOwnProperty('id') && data.data.id === 'terms') {
        window.open("https://en.wikipedia.org/wiki/Terms_of_service");
      }
      if (data.data?.hasOwnProperty('id') && data.data.id === 'privacy') {
        window.open("https://en.wikipedia.org/wiki/Privacy_policy");
      }

      if (data.data?.hasOwnProperty('paymentKeys')) {
        let paymentKey = data.data.paymentKeys[0];
        this.postPayment(paymentKey);
      }
    },
    async postPayment(paymentKey) {
      this.loading = true
      try{
        let response = await this.getCardData(paymentKey)
        await this.savePayment(response)
      }catch (e) {}

    },
    async getCardData(paymentKey){
      let src = this.frame_src
      console.log({src})
      let payload = {
        payment_key: paymentKey,
        api_token: this.session_key
      }
      try {
        let response = (await eventApiCall.post(`${this.property.id}/payments/hpc/token`, payload, null, 'baseAppUrlProperties')).data
        return response
      } catch (error) {
        const errorMessage = error?.response?.data?.message || error?.data?.message || error?.message || error?.data
        this.$notify.error({ title: this.$t('lbl_error'), message: errorMessage, });
      }
    },
    async savePayment(response){
      let payload = {
        "guarantee_type": "FPCC",  //HARDCODED
        "payment_source": {
          "custom_field_1": response.masked_card_number,
          "custom_field_2": response. card_brand,
          "custom_field_4": response.card_expire_month + "/" +  response.card_expire_year,
          "custom_field_5": response.token,
        },
        "company_id": null , //HARDCODED
        "cut_off_date" : this.eventTime.start_time
      }
      await this.$emit('sendData', payload)
    },
    stopLoading(){
      this.loading = false
    }
  },
  computed: {
    ...mapState({
      property: state => state.events.propertyDetails,
      eventTime: state => state.events.eventTime
    }),
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handler, false)
  },
  async mounted() {
    await this.getData()
  }
}
</script>
<style scoped lang="scss">
.payment-frame{
  width: 550px;
  align-items: center;
  margin: auto;
  height: 530px;
}
</style>
