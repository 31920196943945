<template>
  <div class="m-t-10 points-redemption-main-screen " v-loading="loading">
    <div class="slider-box" v-if="!loading">

      <h3>{{ $t("lbl_use_loyalty_points") }}</h3>
      <!-- <el-checkbox
                  v-if="maxPoints % 10 !== 0"
                  class="m-t-10 m-b-10"
                  v-model="useAllPoints"
                  @change="useAllPointsChecked()"
      >{{ $t('lbl_use_all_points') }}</el-checkbox> -->
      <p>{{ $t("lbl_use_slider_to_apply_points") }}</p>
      <el-slider input-size="medium" :show-tooltip="false"  :min="1" :max="maxPoints" :step="1"
       v-model="slider" :disabled="useAllPoints"></el-slider>

      <div class="summary">
        <div class="flexed justify-between">
          <p>{{ $t("lbl_total_points") }}</p>
          <strong>{{ selectedPoints | formatBigNumber }} {{ $t("lbl_points") }}</strong>
        </div>
        <div class="flexed justify-between">
          <p>{{ $t("lbl_amount_to_be_paid") }}</p>
          <strong>{{ selectedAmount | currency }}</strong>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import apiCall from '@/services/api-calls.service'
import {mapMutations, mapState} from 'vuex';
import CommonServices from "../../../services/common.services";
import deepClone from 'clone';
import CommonUtils from "@/utils";
export default {
  name:'PointsRedemption',
  data() {
    return {
      slider: 1,
      payment_options: null,
      // selectedPoints: 0,
      // selectedAmount: 0,
      // maxPoints: 100,
      // minPoints: 0,
      loading: false,
      useAllPoints: false
    }
  },
  computed: {
    ...mapState({
      property: state => state.property.details,
      sessions: state => state.profile.sessions,
      selected_room_details: state => state.selected_room_details,
    }),
    maxPoints(){
      return this.payment_options?.length ? this.payment_options?.length  : 1
    },
    selectedPoints(){
      return  this.allPointsChecked ? this.payment_options[this.payment_options.length - 1]?.points : this.payment_options[this.slider - 1]?.points

    },
    selectedAmount(){
      return  this.allPointsChecked ? this.payment_options[this.payment_options.length - 1]?.amount : this.payment_options[this.slider - 1]?.amount

    }
  },
  methods: {
    ...mapMutations({
      SELECTED_ROOM_DETAILS: 'SELECTED_ROOM_DETAILS'
    }),
    async getPointsRedemptionData() {
      this.loading = true
      try{
        const apiUrl = `${this.property.id}/booking-engine/new-booking/${this.sessions[0]}/loyalty-points/slider-view`
        const params = {}
        const baseURL = 'enterpriseUrl'

        const response = await apiCall.get(apiUrl, params, '');

        this.payment_options = CommonUtils.sortFunction(response?.data?.payment_options, 'asc', "points");

        this.slider = this.payment_options?.length
        // this.selectedPoints = this.payment_options[0].points;

        // this.selectedAmount = this.payment_options[0].amount;
        // this.slider = this.selectedPoints

        this.loading = false
      }
      catch(error) {
        console.log(error)
      }
   },
    async save() {
      this.loading = true
      let payload = {total_redeemed_points: this.selectedPoints}
      const apiUrl = `${this.property.id}/booking-engine/new-booking/${this.sessions[0]}/loyalty-points`
      const params = {}
      try {
        await apiCall.post(apiUrl, payload, params, '');
        await this.getAllProfileTaxes()
        return true;
      }
      catch(error) {
        console.log(error);
        this.loading = false
      }
   },

    async getAllProfileTaxes(){
      let selectedRoomDetails = deepClone(this.selected_room_details)
      for (let roomDetails of selectedRoomDetails) {
        await CommonServices.getProfileFolioTaxes(roomDetails.session_id)
          .then(({data}) => {
            roomDetails.profileTaxes = data
          }) .catch(async (err) => {
            console.log(err)
            return false
          });
      }
      this.SELECTED_ROOM_DETAILS(selectedRoomDetails)
      return true
    },
    useAllPointsChecked(){
      if(this.useAllPoints){
        this.allPointsChecked = true;
        // this.selectedPoints = this.payment_options[this.payment_options.length - 1].points
        // this.selectedAmount = this.payment_options[this.payment_options.length - 1].amount

        // this.slider = this.selectedPoints
      }else{
        this.allPointsChecked = false;
        // this.selectedPoints = this.payment_options[0].points
        // this.selectedAmount = this.payment_options[0].amount
        // this.slider = this.selectedPoints
      }
    }
  },

  // watch: {
  //   slider(newValue, oldValue) {
  //     console.log({newValue})
  //       if (this.payment_options) {
  //         // const maxPoints = this.payment_options[this.payment_options.length - 1].points;
  //         // this.maxPoints = maxPoints;

  //         // const minPoints = this.payment_options[0].points;
  //         // this.minPoints = minPoints;

  //       //   const selectedPoints = Math.min(maxPoints, parseInt(newValue));
  //       //   const paymentOption = this.payment_options.find(option => option.points === selectedPoints);
  //       //  const selectedAmount = paymentOption ? paymentOption.amount : 0;

  //       //   this.selectedPoints = selectedPoints;
  //       //   this.selectedAmount = selectedAmount;
  //       }
    // }
  // },
  async beforeMount(){
    await this.getPointsRedemptionData();
  }

}
</script>

<style lang="scss" scoped>
.points-redemption-main-screen {
  width: 100%;
  height: 100%;
}
.slider-box {
  margin: 50px auto;
  width: 60%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 9px;
}
h3, p {
  margin: 0;
}


h3 {
  font-weight: 800;
}

</style>
