
import ApiCalls from '@/services/abs/api-calls-events.service.js'
export default {
    async getPropertyDetails({state, dispatch, commit}, params) {
        let propertyDetails = (await ApiCalls.getHotelDetails(params.property_id))?.data
        commit('SET_PROPERTY_DETAILS', propertyDetails);
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = propertyDetails.business_logo;
      document.getElementsByTagName('head')[0].appendChild(link);
      document.getElementsByTagName('title')[0].innerHTML = 'Booking | ' + propertyDetails.name;
    },

}
