<template>
    <div>
        <div v-for="(space_arrangment, index) in space_arrangments" :key="index" >
            <div class="flexed justify-between align-center gap-10 card item">
                <div>
                    <img :src="space_arrangment.image" width="80px" height="80px" alt="">
                </div>
                <div class="description card">
                    {{ space_arrangment.description }}
                </div>
                <div>
                    <el-button type="primary" @click="addSpaceArrangment(space_arrangment.id)">SELECT</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SelectSpaceArrangment',
        props: ['space_arrangments'],
        data() {
            return {
                
            }
        },
        methods: {
            addSpaceArrangment(id) {
                this.$emit('selectSpaceArrangement', id)
            }
        }
    }
</script>

<style lang="scss" scoped>
.description{
    width: 100%;
    height: 80px;
    overflow: auto;
    margin-bottom: 0px !important;
}

.item {
    border-left: 2px solid #4ad8a2;
}

</style>