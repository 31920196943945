<template>
    <div class="activity-item m-t-30 flexed card-border">
        <div class="image">
            <div class="img" v-if="image">
                <img :src="image" alt="" :height="mobileView ? '200px' : '196px'" :width=" mobileView ? '100%' : '320px'">
            </div>
            <div class="list-card-pic" v-else>
              <div class="no-img">
                <i class="el-icon-picture-outline no-img-icon"></i>
                <span>{{ $t("lbl_no_image_found") }}</span>
              </div>
          </div>
        </div>
        <div class="text-container">
            <p class="name m-b-5">{{name}}</p>
            <div v-if="primary_description == null || primary_description == '' " class="description" style="white-space: pre-line;">
              {{convertHtmlToText(description)?.length>33 ? convertHtmlToText(description)?.slice(0, 32)+'...' : convertHtmlToText(description) }}
              <!-- {{description?.length>80 ? description?.slice(0, 78)+'...' : description }} -->

            </div>
            <div v-else  class="description" style="white-space: pre-line;">
               {{convertHtmlToText(primary_description)?.length>33 ? convertHtmlToText(primary_description)?.slice(0, 32)+'...' : convertHtmlToText(primary_description) }}
               <!-- {{description?.length>80 ? description?.slice(0, 78)+'...' : description }} -->

            </div>
              <p class="capacity-left">{{ $t("lbl_capacity_left") }}: {{ capacity_left }}</p>
              <p class="from-to">{{ $t("lbl_from") }}: {{ from_time | formatTime }} To: {{ to_time  | formatTime}}</p>

        </div>
        <div class="price-and-button flexed-column justify-between">
          <div class="flexed-column  align-end">
            <div class="flexed-column justify-start start-from-price">
              <span class="starts-from">{{ $t("lbl_starts_from") }}</span>
              <span class="price">{{price | currency(currency)}}</span>
            </div>
          </div>
            <el-button
                type="primary"
                class="book-button"
                @click="bookNow()"
                :loading="loading"
            >Book Now</el-button>
        </div>
  </div>
</template>

<script>
import CurrencyData from '@/assets/currencies/currencies';
import {decode, encode} from 'html-entities';
import {mapState, mapMutations} from "vuex";
import htmlToFormattedText from "html-to-formatted-text";
const  {convert} = require('html-to-text');
export default {
    props:['name', 'primary_description','description', 'image', 'price', 'id', 'images',
    'capacity_left', 'from_time','to_time', 'selectedDate'],
    name:'ActivityItem',
    data(){
      return {
        currencyData: CurrencyData,
        loading: false
      }
    },
    computed:{
      ...mapState({
        currency: state => state.activities.propertyDetails.currency,
        mobileView: state => state.mobileView

      })
    },
    methods: {
      bookNow() {
        this.loading = true;

        let payload = {
          name: this.name,
          primary_description: this.primary_description,
          description: this.description,
          images: this.images,
          id: this.id,
          image: this.image,
          from_time: this.from_time,
          to_time: this.to_time,
          selectedDate: this.selectedDate
        }

        this.$emit('bookActivity', payload);
      },
      convertHtmlToText(html) {
        const options = {
          // wordwrap: 13, // Specify the line width for word wrapping
          // baseElements: { selectors: [] },
          itemPrefix: '',
        }
        const htmlString = html;

        const decodedString = decode(htmlString);

        const text = convert(decodedString, options);
        // Extract the list items
          const listItems = text.split('\n');

          // Format the list items with bullet points and newlines
          const formattedText = listItems.map(item => `${item}`).join('\n');
          console.log(formattedText)
        return formattedText;
      }
    },
    beforeMount() {
      this.convertHtmlToText(this.description);
    }
}
</script>


<style lang="scss" scoped>
@import "~css_vars";
@import '~mixins';
@import "@/assets/sass/config/mixins";

.activity-item{
  color: #4E4E4E;
  box-sizing: border-box;
  .image {
    min-width: 320px;
  }
  .img {
        height: 180px;
        // margin: 10px 10px;

        img, list-card-pic {
          // max-height: 180px;
          // min-height: 180px;
          max-width: 100%;
          min-width: 100%;
          border-radius: 5px;
        }
      }
      .list-card-pic{
        background-color: lightgray;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100% !important;
        border-radius: 5px;
        .no-img-icon{
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-content: center;
          padding-bottom: 5px;
        }
      }
  .text-container{
    flex: 1;
    font-size: 14px;
    padding: 5px 0 5px 20px;
    .name{
      margin: 3px 0 0 ;
      font-weight: bold;
      font-size: 16px;
    }
    .capacity{
      margin: 10px 0;
      color: #606266;
    }
    .description{
      margin: 0;
      // margin-top: 14px;
      // padding-bottom: 5px;
      overflow: hidden;
      margin-bottom: 5px;
      max-width: 520px;
      max-height: 80px;
    }

    .description ul {
      list-style: none !important;
    }
  }
  .price-and-button{
    padding: 20px 20px 20px 0;
    .price{
      // font-size: 1.1em;
      font-weight: bold;
    }
    .starts-from {
      font-weight: bold;
      font-size: 14px;
    }
    .book-button{
      margin-top: 60px;
      margin-bottom: 5px;
      margin-left: 10px;
      width: 140px;
      padding: 15px 0;
    }
  }
}

.capacity-left{
  margin-bottom: 0px;
  padding: 0px;
  font-size: 14px;
}

 .from-to {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
}

@include media("<=tablet") {
  .activity-item{
    flex-direction: column;
    margin-top: 40px!important;
    .img {
      img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
      }

      img, .list-card-pic {
          // max-height: 170px;
          // min-height: 170px;
          max-width: 100%;
          min-width: 100%;
          border-radius: 5px;
        }
    }
    .text-container{
      padding: 20px;
    }
    .price-and-button{
      width: calc(100vw - 60px);
      text-align: end;
      margin: 10px 15px 0;
      .book-button{
        margin-top: 10px;
        width: 100%;
        margin-left: 0px;
      }
    }

    .align-end {
      align-items: flex-start;
    }

    .price {
      text-align: start;
    }

    .image {
      height: 200px;
      min-width: 300px;

    }


  }
}
</style>
