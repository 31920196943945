<template>
    <div>
      <DefaultHeader></DefaultHeader>

      <StepsHeader></StepsHeader>

      <router-view class="route-container"></router-view>
    </div>
</template>

<script>
    import DefaultHeader from "./components/default-header";
    import StepsHeader from "./components/StepsHeader";

    export default {
      name: 'EventSteps',
      components: {
        DefaultHeader,
        StepsHeader
      },
      beforeMount () {

            this.$router.push({name: 'select-space'}).catch(()=>{})
        },
    }
</script>

<style lang="scss" scoped>

@import "~css_vars";
@import '~mixins';

    .navigation {
        position: fixed;
        width: 100vw;
        padding: .7rem 2rem;
        bottom: 0;
        left: 0;
        right: 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        background-color: white; //border-top: solid 1px #f4f4f4;
        box-shadow: 0px -3px 5px -5px black;
        z-index: 30;

        button {
          touch-action: manipulation;
          text-transform: uppercase;
          border: 1px solid $lightgrey-2;
          min-width: 140px;
          padding: 12px 30px;
          border-radius: 3px;
          position: relative;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0;
          will-change: box-shadow, transform;
          transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);

          &.next {
            background-color: $blue;
            margin-left: auto;
            color: white;
            box-shadow: 0 2px 2px 0 rgba($blue, 0.14), 0 3px 1px -2px rgba($blue, 0.2), 0 1px 5px 0 rgba($blue, 0.12);

            &:disabled {
              cursor: not-allowed;
              opacity: 0.6;
            }
          }
        }
      }

      .navigation > button:focus {
        box-shadow: 0 0 0 2px #008ad4 !important;
      }
</style>
