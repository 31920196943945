<template>
  <div class="modal-comp">
    <div class="modal" :style="{'width': '800px', 'height' : '650px'}">
      <section class="modal-card-body">
        <iframe :src="iframeUrl"
                height="650px"
                width="100%"
                @load="frameLoaded"
                frameborder="0"
        ></iframe>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: "DirectPaymentModal",
  props: ['frame_src'],
  computed: {
    iframeUrl(){
      return this.frame_src + '&embedded=true'
    }
  },
  methods: {
    frameLoaded() {
        window.addEventListener('message', this.handler, false);
    },
    handler(event) {
      if(event?.data?.status === 'OK' || event?.data?.status === 'EXECUTED'){
        setTimeout(() => {
          this.$emit('close')
        }, 1500)
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handler, false)
  }
}
</script>

<style scoped lang="scss">

@import "~css_vars";
@import '~mixins';
@import "@/assets/sass/config/mixins";

.modal-comp {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(#000, .3);
  display: flex;
  align-items: center;
  justify-content: center;


  &.top-right {
    align-items: flex-start;
    justify-content: flex-end;
  }

  &.top-left {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &.bottom-right {
    align-items: flex-end;
    justify-content: flex-end;
  }

  &.bottom-left {
    align-items: flex-end;
    justify-content: flex-start;
  }

  .modal {
    position: relative;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    height: auto;
    max-height: 90vh;
    min-width: 30vw;
    display: flex;
    flex-direction: column;
    margin: 10px 20px;

    @include media("<=tablet") {
      height: 100%;
      max-height: 100%;
      width: 100%;
      min-height: 100%;
      margin: 0;
    }
  }

  .modal-card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;

    &.blue-title {
      display: flex;
      align-items: center;
      background-color: #409eff;
      color: white;
    }
  }

  .blue-title > .close {
    color: white;
  }

  .modal-card-body {
    overflow: auto;
    margin: 0 10px;
    text-transform: initial;
    color: lighten(#000, 10);
    flex: 1;
  }

  .big {
    font-size: 25px;
  }

  .medium {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .modal-comp {
    .modal {
      width: 450px;
    }
  }
}
</style>
