<template>
  <div v-if="hasPricePlans" class="event-item m-t-30 flexed card-border">
    <div class="image">
      <div class="img" v-if="space.image">
        <img :src="space.image" alt="" :height="mobileView ? '200px' : '170px'" :width=" mobileView ? '100%' : '240px'">
      </div>
      <div class="no-img" v-else>
        <i class="el-icon-picture-outline no-img-icon"></i>
        <span>{{ $t("lbl_no_image_found") }}</span>
      </div>
    </div>
    <div class="text-container">
      <p class="name">{{ space.space_name }}</p>
      <p class="capacity"><i class="el-icon-user"></i> {{ space.space_capacity }}</p>
      <p class="description">{{
          space.space_description.length > 230 ? space.space_description.slice(0, 227) + '...' : space.space_description
        }}</p>
      <div class="price-and-button flexed-column justify-between">
        <div v-for="(pricePlan, index) in space.calendars[0].price_plans" :key="index" class="rate-container">
          <div class="rate-details">
            <span class="name">{{ pricePlan.name }}</span>
            <span class="price">{{ pricePlan.price | currency(currency) }} {{ priceUnits[index] }}</span>
          </div>
          <div class="desc">
            <span>{{ pricePlan.description }}</span>
          </div>
          <el-button
            style="float: right"
            type="primary"
            class="book-button"
            @click="bookNow(pricePlan, index)"
            :loading="loading"
          >{{ $t("lbl_book_now") }}
          </el-button>
        </div>
      </div>
    </div>

    <el-dialog
      :show-close="false"
      :visible.sync="dialogVisible"
      width="60%"
      >
      <span slot="title" class="dialog-header flexed justify-end">
        <el-button @click="skipSelection()">{{ $t("lbl_skip_selection") }}</el-button>
      </span>
      <div style="height: 300px; overflow: auto;">
        <select-space-arrangment @selectSpaceArrangement="bookWithSpaceArrangement" :space_arrangments="selectedSpaceArrangements"></select-space-arrangment>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CurrencyData from '@/assets/currencies/currencies';
import SelectSpaceArrangment from "./SelectSpaceArrangment.vue";

export default {
  name:'EventItem',
  components: {SelectSpaceArrangment},
  props:{
    space: {}
  },
  data(){
    return{
      currencyData: CurrencyData,
      loading: false,
      selectedSpaceArrangements: null,
      selectedPricePlan: null,
      dialogVisible: false,

    }
  },
  methods:{
    bookNow(pricePlan, index){
      // this.loading = true
      this.dialogVisible = true;
      this.selectedSpaceArrangements = this.space?.space_arrangements
      this.selectedPricePlan = { pricePlan: pricePlan, calendar_index: index }
      // this.$emit("bookEvent", { space: this.space, pricePlan, calendar_index: index });
    },
    skipSelection() {
      this.$emit("bookEvent", { space: this.space, pricePlan: this.selectedPricePlan.pricePlan, calendar_index: this.selectedPricePlan.calendar_index });
    },
    bookWithSpaceArrangement(id) {
      let payload = {
        space: this.space,
        pricePlan: this.selectedPricePlan.pricePlan,
        calendar_index: this.selectedPricePlan.calendar_index,
        event_setup_type_id: id
      }
      this.$emit("bookEvent", payload);
    }
  },
  computed:{
    ...mapState({
      currency: state => state.events.propertyDetails.currency,
      mobileView: state => state.mobileView
    }),
    hasPricePlans() {
      return this.space.calendars.some(calendar => calendar.price_plans.length > 0);
    },
    priceUnits() {
      return this.space.calendars[0].price_plans.map(pricePlan => {
        const numberOfMinutes = pricePlan.number_of_minutes || 0;
        return numberOfMinutes > 0 ? `Per ${numberOfMinutes} Minutes` : 'Per Day';
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~css_vars";
@import '~mixins';
@import "@/assets/sass/config/mixins";


:deep(.el-dialog) {
  border-radius: 20px;
}

.event-item {
  color: #4E4E4E;

  .img {
    margin-bottom: -6px;

    img {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .text-container {
    flex: 1;
    font-size: 14px;
    padding: 20px 0 5px 5px;

    .name {
      margin: 3px 0 0;
      padding-left: 10px;
      font-weight: bold;
      font-size: 16px;
    }

    .capacity {
      margin: 10px 0;
      padding-left: 10px;
      color: #606266;
    }

    .description {
      padding-left: 10px;
      margin: 0;
      max-width: 520px;
    }
  }

  .price-and-button {
    padding: 20px 5px 0 0;

    .rate-container {
      border: 1px solid #efefef;
      padding: 20px;
      margin: 5px;

      .rate-details {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .name {
          font-weight: bold;
          font-size: 14px;
        }

        .price {
          font-size: 1.1em;
          font-weight: bold;
          text-align: end;
        }
      }

      .desc {
        padding-left: 10px;
        margin-top: 5px;
        font-size: 14px;
      }

      .book-button {
        margin-top: 10px;
      }
    }
  }
}

@include media("<=tablet") {
  .event-item {
    flex-direction: column;
    margin-top: 40px !important;

    .img {
      img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
      }
    }

    .text-container {
      padding: 20px;
    }

    .price-and-button {
      width: calc(100vw - 60px);
      text-align: end;
      margin: 10px 15px 0;

      .book-button {
        margin-top: 10px;
      }
    }
  }
}
</style>
