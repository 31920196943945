<template>
  <div class="hk-dropdown-wrapper" :class="{'is-focus': !!visible}" @mouseleave="setVisibility(false)">
    <div class="activator" @click="setVisibility(!visible)">
      <slot name="activator">
        <input readonly ref="dropdown_input" @focus="setVisibility(true)" type="text" autocomplete="off"
               :placeholder="placeholder" :name="name" :value="activatorValue"
               class="hk-input__inner el-dropdown-link form-control">
        <i class="el-icon-arrow-down el-icon--right" :class="{'is-reverse': visible}"></i>
      </slot>
    </div>
    <div class="dropdown-list" v-show="visible">
      <div class="popper__arrow"></div>
      <div class="list-scroll">
        <slot name="dropdown">
          <li v-for="(option, index) in items" :key="index"
              @click="setVisibility(false)"
              :class="{'active': key ? model[key] === option[key] : model === option}">
            {{typeof option === 'String' ? option : option['valKey']}}
          </li>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['model', 'name', 'placeholder', 'items', 'valKey', 'activatorValue'],
    data: () => ({
      visible: false,
      keyboard_activated: false,
    }),
    methods: {
      setVisibility(value) {
        this.visible = value;
      },
    },
  }

</script>
<style scoped lang="scss">
  @import '~mixins';
  @import '~css_vars';

  .hk-dropdown__item.selected {
    color: #409EFF;
    font-weight: 700;
  }

  .list-scroll {
    position: relative;
    overflow: hidden;
  }

  .hk-dropdown-wrapper {
    flex: 1;
    position: relative;

    &.is-focus {
      .activator input {
        border-color: #409EFF;
      }
    }

    .activator {
      position: relative;

      i {
        position: absolute;
        right: 10px;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        color: #c0c4cc;
        font-size: 14px;
        -webkit-transition: -webkit-transform .3s;
        transition: -webkit-transform .3s;
        transition: transform .3s;
        transition: transform .3s, -webkit-transform .3s;
        -webkit-transform: rotateZ(180deg);
        transform: rotateZ(180deg);
        line-height: 16px;
        cursor: pointer;

        &.is-reverse {
          -webkit-transform: rotateZ(0);
          transform: rotateZ(0);
        }
      }

      input {
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
        transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
        width: 100%;
        cursor: pointer;
        user-select: none;
      }

      &:after {
        content: '';
        height: 20px;
        width: 100%;
        background-color: transparent;
        top: 100%;
        display: block;
        position: absolute;
      }
    }

    .dropdown-list {
      position: absolute;
      min-width: 100%;
      list-style: none;
      padding: 6px 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      z-index: 1001;
      border: 1px solid #e4e7ed;
      border-radius: 4px;
      background-color: #fff;
      -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 5px 0;
      @include media('<=phone') {
        right: 0;
        top: 100%;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 5px 20px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #606266;
      min-height: 34px;
      line-height: 34px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      cursor: pointer;
    }
  }

  .activator-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }

  element.style {
    left: 35px;
  }

  .popper__arrow {
    top: -6px;
    left: 50%;
    margin-right: 3px;
    border-top-width: 0;
    border-bottom-color: #ebeef5;
  }

  .popper__arrow {
    border-width: 6px;
    -webkit-filter: drop-shadow(0 2px 12px rgba(0, 0, 0, .03));
    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, .03));
  }

  .popper__arrow,
  .popper__arrow::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

</style>
