<template>
  <div class="guest-form card-border m-t-20">
    <div class="flexed justify-center align-center" style="min-height: 530px" v-if="loading">
      <loading ></loading>
    </div>
    <div class="w-100 p-20 m-b-10 form-controls" v-else>
      <span class="label-no-height title" style="">{{ $t('lbl_event_booking_name') }}</span>
      <fd-input
        key="event_name"
        v-model="guest.event_name"
        :placeholder="$t('lbl_event_booking_name_placeholder')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="event_name"
        class=" street"
        v-validate="{ required: true}"
        :showAsterisk="true"
      />
      <span class="label-no-height title" style="">{{ $t("lbl_contact_details") }}</span>
      <fd-input
        key="first_name"
        v-model="guest.first_name"
        :placeholder="$t('lbl_first_name')"
        border-radius-bottom-right="0"
        border-radius-bottom-left="0"
        :autocomplete="false"
        control-name="first_name"
        v-validate="{ required: isFieldRequired('first_name'), regex: rules.first_name_regex || ''}"
        :showAsterisk="isFieldRequired('first_name')"
      />
      <fd-input
        key="last_name"
        v-model="guest.last_name"
        :placeholder="$t('lbl_last_name')"
        border-radius-bottom-right="0"
        border-radius-bottom-left="0"
        :autocomplete="false"
        control-name="last_name"
        v-validate="{ required: isFieldRequired('last_name'), regex: rules.last_name_regex || ''}"
        :showAsterisk="isFieldRequired('last_name')"
      />
      <fd-input
        key="email"
        v-model="guest.email"
        :placeholder="$t('lbl_email')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="email"
        v-validate="{ required: true}"
        :showAsterisk="true"
      />
      <hk-phone-input :model.sync="guest.phone"
                      ref="hkPhoneInput"
                      :placeholder="$t('lbl_phone')"
                      key="phone"
                      control-name="phone"
                      autocomplete="new-password"
                      :defaultCountryCode="dynamicCountryCode"
                      classes="primary-phone"
                      v-validate="{ required: true}"
                      :showAsterisk="true"
      />

      <span class="label-no-height title" style="">{{ $t("lbl_address") }}</span>
      <fd-input
        key="street"
        v-model="guest.street"
        :placeholder="$t('lbl_street')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="street"
        class=" street"
      />
      <fd-input
        key="city"
        v-model="guest.city"
        :placeholder="$t('lbl_city')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="city"
      />
      <fd-input
        key="state_required"
        v-model="guest.state"
        data-vv-validate-on="input|blur"
        border-radius-bottom-right="0"
        border-radius-top-right="0"
        border-radius-top-left="0"
        control-name="state_required"
        justify-content="space-between"
        :placeholder="$t('lbl_state')"
        v-validate="{ required: !rules.state_optional}"
        :showAsterisk="isFieldRequired('state')"
      >
    <span v-if="rules && rules.property_eligible_states && rules.property_eligible_states.length > 0" @click="$refs.selectState.focus()" class="drop-down-label">
      <span v-if="rules.state_required" class="asterisk">*</span> {{$t('lbl_state')}}
    </span>
        <el-select
          v-if="rules && rules.property_eligible_states && rules.property_eligible_states.length > 0"
          v-model="guest.state"
          filterable
          :placeholder="$t('lbl_select')"
          class="border-0 text-right"
          size="mini"
          ref='selectState'
        >
          <el-option
            v-for="state in rules.property_eligible_states"
            :key="state.id"
            :label="state.state_code"
            :value="state.state_code"
          />
          <template slot="prefix" class="el-error" v-if="errors.has('state_required')"> <span class="el-error">{{ $t("lbl_required") }}</span> </template>
        </el-select>
      </fd-input>
      <fd-input
        key="zipcode_required"
        v-model="guest.zip_code"
        :placeholder="$t('lbl_postal')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="zipcode_required"
        v-validate="{ required: !rules.zipcode_optional, regex: rules.zipcode_regex || ''}"
        :showAsterisk="!rules.zipcode_optional"
      />
      <fd-input
        key="country"
        v-model="guest.country"
        v-validate="{ required: isFieldRequired('country')}"
        :placeholder="$t('lbl_country')"
        :showAsterisk="isFieldRequired('country')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="country"
        justify-content="space-between"
      >
      <span @click="$refs.selectCountry.focus()" class="drop-down-label">
        <span v-if="isFieldRequired('country')" class="asterisk">*</span> {{$t('lbl_country')}}
    </span>
        <el-select
          v-model="guest.country"
          filterable
          :placeholder="$t('lbl_select')"
          class="border-0 text-right"
          size="mini"
          ref='selectCountry'
          @change="getAddressRules(guest.country)"
        >
          <el-option
            :key="_country.value"
            :value="_country.value"
            :label="_country.name"
            v-for="_country in countries">
          </el-option>
          <template slot="prefix" class="el-error" v-if="false"> <span class="el-error">{{ $t("lbl_required") }}</span> </template>
        </el-select>
      </fd-input>
      <!-- <fd-input
        key="remarks"
        v-model="guest.remarks"
        placeholder="Special Remarks (if any)"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="remarks"
        class="remarks"
        isComponent="textarea"

      /> -->
      <el-input
        type="textarea"
        :rows="2"
        placeholder="Special Remarks (if any)"
        control-name="remarks"
        v-model="guest.remarks">
    </el-input>
      <fd-input
        key="po_number"
        v-model="guest.po_number"
        :placeholder="$t('lbl_cost_centre')"
        border-radius-bottom-right="0"
        border-radius-bottom-left="0"
        :autocomplete="false"
        control-name="po_number"
        class="cost-center"
        v-validate="{ required: true}"
        :showAsterisk="true"

      />

    </div>
  </div>

</template>

<script>
import HkPhoneInput from '@/components/hk-tel-input/HkPhoneInput.vue'
import FdInput from '@/components/FdInput.vue'
import {mapState} from "vuex";
import {Countries} from "../../../booking-v2/information/countries";
import apiCallsEventsService from "../../../../services/events/api-calls-events.service";
import loading from '@/components/common/loading.vue';

export default {
  name: "GuestForm",
  components: {HkPhoneInput, FdInput, loading},
  props:{
    loadingParent: Boolean
  },
  data() {
    return {
      guest: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        street: null,
        city: null,
        state: null,
        zip_code: null,
        country: null,
        event_name: null,
        po_number: null,
        remarks: null
      },
      loading: false,
      countries: Countries,
      rules: null
    }
  },
  mounted() {

    this.guest.country =  this.property.country
    this.getAddressRules()

    if(this.guest_data) {
      this.guest.first_name = this.guest_data.first_name
      this.guest.last_name = this.guest_data.last_name
      this.guest.email = this.guest_data.email
      this.guest.phone = this.guest_data.phone
      this.guest.street = this.guest_data.street
      this.guest.city = this.guest_data.city
      this.guest.state = this.guest_data.state
      this.guest.zip_code = this.guest_data.zip_code
      this.guest.event_name = this.guest_data.event_name
      this.guest.po_number = this.guest_data.po_number
      this.guest.remarks = this.guest_data.remarks
    }
    console.log('guest tes tes', this.guest)
  },
  methods:{
    isFieldRequired(field_name){
      let valid = this.rules.required_guest_fields.includes(field_name)

      return valid
    },
    async validateForm() {
      let valid = await this.$validator.validateAll()
       if(valid)
         return this.guest
    },
    async getAddressRules(){
      this.loading = true
      try{
        let params = {
          country_code: this.guest.country
        }
        // TODO: make country_code dynamic
        let result = await apiCallsEventsService.get(`${this.property.id}/address-rules?country_code=${params.country_code}&booking_flow=true`, null, 'baseAppUrlProperties')

        this.rules = result.data
        this.guest.state = ''
        this.loading = false
      }
      catch (e) {
        this.$notify.error({
          title: this.$t('lbl_error'),
          message: this.$t('lbl_something_went_wrong')
        })
      }
    }
  },
  computed:{
    ...mapState({
      property: state => state.events.propertyDetails,
      guest_data: state => state.events.guest_data
    }),
    dynamicCountryCode() {
      return this.guest.country;
    }
  },
  beforeMount() {
    // this.getAddressRules()
  }
}
</script>

<style scoped lang="scss">
@import '~css_vars';
@import "~mixins";
$gray: #b6b6b6;

.form-controls{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 10px;
  .label-no-height{
    color: black;
  }
  .street, .title{
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .remarks {
    resize: vertical; // Allow vertical resizing of the textarea
    height: 70px;
  }
  .cost-center {
    height: 46px;
  }
  .title{
    margin-bottom: -10px;
  }
  .border-0 {
    ::v-deep  input {
      border: none  !important;
      text-align: right;
      font-size: 16px;
    }
  }
  .el-error {
    height: 100%;
    display: flex;
    align-items: center;
    color: #F56C6C !important;
    font-size: 10px;
    margin-right: 5px;
  }
  .drop-down-label{
    color: $gray;
  }

}
@include media('<=600px') {
  .form-controls{
    grid-template-columns: 1fr;
  }
}
.asterisk{
  color: $hk-danger;
}
</style>
