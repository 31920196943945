<template>
  <component
    :is="element" ref="element"
    title="Stay Details"
    :visible="true"
    :before-close="closeSummarySection"
    direction="ttb"
    size="100%"
    class="main text-grey"
    @close="closeSummarySection"
    :class="[mobileView ? '' : 'p-20', mobileView ? '' : 'background-color']"
  >
    <div class="font-large bold m-b-10" v-if="!mobileView">{{ $t("lbl_stay_details") }}</div>
    <div>
      <information-summary v-if="rateContent"/>
    </div>
  </component>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import InformationSummary from './Summary.vue'

export default {
  components: {InformationSummary},
  name: 'SummarySection',
  computed: {
    ...mapState({
      search_data: state => state.search_data,
      mobileView: state => state.mobileView,
      current_step: state => state.current_step,
      rateDetails: state => state.rate_details,
      rateContent: state => state.rate_content,
    }),
    ...mapGetters({
      // rateContent: 'rate_content'
    }),
    element() {
      return this.mobileView ? 'el-drawer' : 'div';
    },
  },
  methods: {
    closeSummarySection() {
      this.$emit('closeSummarySection')
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/config/variables";
@import "../../../assets/sass/config/mixins";

@include media('<=phone') {
  .main {
    font-size: 15px;
    border-radius: 5px;

  }

  .background-color {
    background-color: whitesmoke;
  }
}

@include media('>=phone') {
  .main {
    font-size: 14px;
    border-radius: 5px;

    ::v-deep .el-drawer__header {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      font-weight: bold;
      color: #2c3e50;
      font-size: 20px;
      background-color: whitesmoke;

      .el-drawer__close-btn {
        //
      }
    }

    ::v-deep .el-drawer__body {

      display: flex;
      flex-direction: column;
      overflow: auto;
      padding: 50px 20px 20px;
      background-color: whitesmoke;

    }

    .container {
      padding: 0 10px;
      flex: 1;
      overflow: auto;
    }

    .footer {
      display: flex;
      padding: 5px 10px;
      justify-content: flex-end;
      background-color: white;
      margin-top: 2px;
      box-shadow: 0 0 9px 5px rgba(152, 186, 194, 0.75);
    }
  }

  .background-color {
    background-color: whitesmoke;
  }
}

@include media('<=phone') {
  ::v-deep .vhd__datepicker__month {
    margin-top: -20px;
  }
  ::v-deep .el-drawer__header {
    font-weight: bold;
    color: #606266;
    font-size: 20px;
    border-bottom: 1px solid #d4d4d4;
    padding: 10px 10px 10px 20px;
    margin: 0 !important;
    margin-bottom: 0 !important;

    .el-drawer__close-btn {
      min-width: auto !important;
    }
  }
  ::v-deep .el-drawer__body {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 20px;
  }
}

::v-deep .vhd__datepicker__fullview {
  height: auto;
}

</style>
