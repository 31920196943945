const steps = {
  search: {
    name: 'lbl_select_dates',
    route_name: 'booking-search',
    error: 'Please fill in the required fields in order to proceed.',
  },
  // search_v2: {
  //   name: 'Select',
  //   route_name: 'booking-brand',
  //   error: 'Please fill in the required fields in order to proceed.',
  // },
  rooms: {
    name: 'lbl_select_rooms',
    route_name: 'booking-select-rooms',
    error: 'Pick one or more of the rooms to proceed to the next step.',
  },
  addons: {
    name: 'lbl_select_add_ons',
    route_name: 'booking-select-addons',
    error: 'Pick one or more of the rooms to proceed to the next step.',
  },
  pointsRedemption:{
    name: 'lbl_points_redemption',
    route_name: 'booking-points-redemption',
    error: ''
  },
  information: {
    name: 'lbl_guest_information',
    route_name: 'booking-guest-information',
    error: 'Please fill in the required fields in order to proceed.',
  },
  payment: {
    name: 'lbl_payments',
    route_name: 'booking-payments',
    error: 'Please fill in the required fields in order to proceed.',
  },
  confirmation: {
    name: 'lbl_confirmation_step',
    route_name: 'booking-confirmation',
    error: 'See whether your reservation has been confirmed from the hotel.',
  }
};

export default {
  steps,
};
