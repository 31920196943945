<template>
    <div class="policy-box m-b-15" >
      <span class="label-no-height">{{ $t("lbl_cancellation_policy") }} - {{ translateValues(objectCode(room.rate), room.rate.name, objectType(room.rate), objectId(room.rate), 'name' )  }}</span>
      <div class="text-grey  m-t-10 ">{{getCancelationPolicy(room.room_type_id, room.rate.id)}}</div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import { translateDynamicContent } from "@/services/utilities.service";

export default {
  name: 'CancellationPolicy',
  props: ['room'],
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      rwa_data: state => state.rwa_data,
      multi_lingual_dynamic_fields: state => state.multi_lingual_dynamic_fields,
      property_language_code: state => state.property.details.language
    }),

  },
  methods: {
    selectedRateDetails(room_type_id,rateId){
      return this.rwa_data.rate_details.find(rd=>rd.room_type_id === room_type_id).rate_codes.find(r=> r.id === rateId)
    },
    getCancelationPolicy(room_type_id,rateId){
      // let cancPolicy =  this.rwa_data.cancellation_policies?.find(c=>c.id === this.selectedRateDetails(room_type_id,rateId).cancellation_policy_id)?.description
      let cancPolicy =  this.rwa_data.cancellation_policies?.find(c=>c.id === this.selectedRateDetails(room_type_id,rateId).cancellation_policy_id)
      console.log('canc policy', cancPolicy)
      console.log('room rate', this.room.rate)
      return this.translateValues(cancPolicy.code, cancPolicy.description, 'CANCELLATION_POLICY', cancPolicy.id, 'description' ) 
    },
    objectCode(rate) {
      if (rate.package_code) {
        return rate.package_code
      }
      return rate?.code
    },
    objectType(rate) {
      if (rate.package_code) {
        return 'PACKAGE_RATES'
      }
      return 'RATE_CODES'
    },
    objectId(rate) {
      if (rate?.package_code) {
        return rate?.package_id
      }
      return rate?.id
    },
    translateValues(object_code, value, object_type, object_id, field_name) {
      return translateDynamicContent(this.multi_lingual_dynamic_fields, object_code, value, object_type, this.property_language_code, object_id, field_name)
    }

  },
}
</script>

<style lang="scss" scoped>
.policy-box {
  font-size: 14px;
    border: solid #dadada 1px;
    padding: 10px;
    border-radius: 5px;
}
</style>
