<template>
  <div id="app">
    <div class="loader loading_whole" style="height: 100vh"
         v-if="loadingApi">
      <div class="modified-loader">
        <loading></loading>
      </div>
    </div>
    <router-view v-else></router-view>
    <error-popup v-if="failed === true || !!failed"></error-popup>
  </div>
</template>
<script>
import errorPopup from '@/components/error-popup'
import {mapMutations, mapState} from 'vuex'
import loading from '@/components/common/loading.vue';

export default {
  data() {
    return {
      loadingApi : false
    }
  },
  components: {
    errorPopup,
    loading
  },
  computed: {
    ...mapState({
      failed: state => state.api_fail,
      mobileView: state => state.mobileView,
    }),
  },
  methods: {
    ...mapMutations({
      SET_ERROR: 'SET_ERROR',
      SET_MOBILE_VIEW: 'SET_MOBILE_VIEW',
    }),
    handleResize() {
      //  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      if (window.innerWidth <= 768) {
        this.SET_MOBILE_VIEW(true)
      } else {
        this.SET_MOBILE_VIEW(false)
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>


