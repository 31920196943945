<template>
    <div>
        <div class="m-t-20 m-b-5 bold">{{ $t('lbl_tavel_transport_information') }}</div>
        <el-form :model="model" :rules="rules" ref="transportForm">
            <div class="transport-information">
                <div class="guest-pick-up border-right p-r-10">

                    <div class="flexed justify-between">
                        <div>{{ $t('lbl_guest_pick_up') }}</div>
                        <el-form-item prop="guest_pick_up.isPicked">
                            <el-switch v-model="model.guest_pick_up.isPicked" @change="handleTransportToggle('guest_pick_up')">
                            </el-switch>
                        </el-form-item>
                    </div>

                    <div class="pick-date-time flexed justify-between m-t-10">

                        <div class="transport-date">
                            <el-form-item prop="guest_pick_up.start_date">
                                <el-date-picker v-model="model.guest_pick_up.start_date" type="date"  :picker-options="datePickerOptions"
                                    :placeholder="$t('lbl_pick_a_date')" :disabled="disableGuestPickUp">
                                </el-date-picker>
                            </el-form-item>
                        </div>

                        <div class="transport-time">
                            <el-form-item prop="guest_pick_up.time">
                                <el-time-select v-model="model.guest_pick_up.time" :picker-options="{
                                    start: '08:30',
                                    step: '00:15',
                                    end: '18:30'
                                }" :placeholder="$t('lbl_pick_a_time')" :disabled="disableGuestPickUp">
                                </el-time-select>
                            </el-form-item>
                        </div>

                    </div>

                    <div class="m-t-10">
                        <div>{{ $t('lbl_additional_details') }}</div>
                        <el-form-item prop="guest_pick_up.body">

                            <el-input type="textarea" autosize
                                :placeholder="$t('lbl_please_share_details_of_your_request_here')"
                                v-model="model.guest_pick_up.body" :disabled="disableGuestPickUp">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="guest-drop-off p-l-10">
                    <div class="flexed justify-between">
                        <div>{{ $t('lbl_guest_drop_off') }}</div>
                        <el-form-item prop="guest_drop_off.isPicked">

                            <el-switch v-model="model.guest_drop_off.isPicked" @change="handleTransportToggle('guest-drop-off')">
                            </el-switch>
                        </el-form-item>
                    </div>
                    <div class="pick-date-time flexed justify-between m-t-10">
                        <div class="transport-date">
                            <el-form-item prop="guest_drop_off.start_date">

                                <el-date-picker v-model="model.guest_drop_off.start_date" type="date"
                                    :placeholder="$t('lbl_pick_a_date')" :picker-options="datePickerOptions"
                                    :disabled="disableGuestDropOff">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="transport-time">
                            <el-form-item prop="guest_drop_off.time">

                                <el-time-select v-model="model.guest_drop_off.time" :picker-options="{
                                    start: '08:30',
                                    step: '00:15',
                                    end: '18:30'
                                }" :placeholder="$t('lbl_pick_a_time')" :disabled="disableGuestDropOff">
                                </el-time-select>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="m-t-10">
                        <div>{{ $t('lbl_additional_details') }}</div>
                        <el-form-item prop="guest_drop_off.body">

                            <el-input type="textarea" autosize
                                :placeholder="$t('lbl_please_share_details_of_your_request_here')"
                                v-model="model.guest_drop_off.body" :disabled="disableGuestDropOff">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>

            </div>
        </el-form>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { formatDate } from "@/services/utilities.service.js"
import dayjs from 'dayjs';


export default {
    name: 'TransportInformation',
    data() {
        return {
            transportations: [],
            model: {

                guest_pick_up: {
                    title: 'Guest Pick-Up',
                    body: null,
                    start_date: '',
                    time: '',
                    isPicked: false
                },
                guest_drop_off: {
                    title: 'Guest Drop-Off',
                    body: null,
                    start_date: '',
                    time: '',
                    isPicked: false
                },
            },


        }
    },
    computed: {
        ...mapState({
            transportation_informations: state => state.transportation_informations,
            selected_room_details: state => state.selected_room_details,
            disableGuestPickUp() {
                return !this.model.guest_pick_up.isPicked
            },
            disableGuestDropOff() {
                return !this.model.guest_drop_off.isPicked
            },
            rules() {
                return {
                    'guest_pick_up.start_date': [
                        { required: this.model.guest_pick_up.isPicked, message: this.$t('lbl_please_pick_a_date'), trigger: ['change', 'blur'] }
                    ],
                    'guest_pick_up.time': [
                        { required: this.model.guest_pick_up.isPicked, message: this.$t('lbl_please_pick_a_time'), trigger: ['change', 'blur'] }
                    ],
                    'guest_drop_off.start_date': [
                        { required: this.model.guest_drop_off.isPicked, message: this.$t('lbl_please_pick_a_date'), trigger: ['change', 'blur'] }
                    ],
                    'guest_drop_off.time': [
                        { required: this.model.guest_drop_off.isPicked, message: this.$t('lbl_please_pick_a_time'), trigger: ['change', 'blur'] }
                    ]
                }
            },
            datePickerOptions() {
                let guestPickUpStartDate = dayjs(formatDate(String(this.selected_room_details?.[0]?.check_in_date), 'YYYY-MM-DD'));
                let guestPickUpEndDate = dayjs(formatDate(String(this.selected_room_details?.[0]?.check_out_date), 'YYYY-MM-DD'));
                return {
                    disabledDate(date) {
                        return (date < guestPickUpStartDate || date > guestPickUpEndDate );
                    }
                }
            },

        })
    },
    methods: {
        ...mapMutations({
            SET_TRANSPORTATION_INFORMATIONS: 'SET_TRANSPORTATION_INFORMATIONS'
        }),
        updateTransportations() {
            this.transportations = [];
            if (this.model.guest_pick_up.isPicked) {
                this.transportations.push({
                    title: this.model.guest_pick_up.title,
                    body: this.model.guest_pick_up.body,
                    start_date: formatDate(String(this.model.guest_pick_up.start_date), 'YYYY-MM-DD'),
                    time: this.model.guest_pick_up.time,
                });
            }

            if (this.model.guest_drop_off.isPicked) {
                this.transportations.push({
                    title: this.model.guest_drop_off.title,
                    body: this.model.guest_drop_off.body,
                    start_date: formatDate(String(this.model.guest_drop_off.start_date), 'YYYY-MM-DD'),
                    time: this.model.guest_drop_off.time,
                });
            }
            this.SET_TRANSPORTATION_INFORMATIONS(this.transportations);
        },
        async validateForm() {
            if (this.model.guest_pick_up.isPicked || this.model.guest_drop_off.isPicked) {
                this.SET_TRANSPORTATION_INFORMATIONS(true);
                this.$refs.transportForm.validate((valid) => {
                    if (valid) {
                        this.updateTransportations()
                        this.$emit('is-valid', true);
                    } else {
                        this.$emit('is-valid', false);
                    }
                });
            }
        },
        handleTransportToggle(arg) {
            if(!this.model.guest_pick_up.isPicked && arg === 'guest_pick_up') {
                this.model.guest_pick_up.start_date = '';
                this.model.guest_pick_up.time = '';
                this.model.guest_pick_up.body = null;
            }
            if(!this.model.guest_drop_off.isPicked && arg === 'guest-drop-off') {
                this.model.guest_drop_off.start_date = '';
                this.model.guest_drop_off.time = '';
                this.model.guest_drop_off.body = null;
            }
        }

    },
    beforeMount() {
        let guestPickUp = this.transportation_informations?.find(item => item.title === 'Guest Pick-Up')
        let guestDropOff = this.transportation_informations?.find(item => item.title === 'Guest Drop-Off')

        if (guestPickUp) {
            this.model.guest_pick_up.isPicked = !!guestPickUp.start_date || !!guestPickUp.time;
            this.model.guest_pick_up.start_date = guestPickUp.start_date;
            this.model.guest_pick_up.time = guestPickUp.time;
            this.model.guest_pick_up.body = guestPickUp.body;
        }

        if (guestDropOff) {
            this.model.guest_drop_off.isPicked = !!guestDropOff.start_date || !!guestDropOff.time
            this.model.guest_drop_off.start_date = guestDropOff.start_date;
            this.model.guest_drop_off.time = guestDropOff.time;
            this.model.guest_drop_off.body = guestDropOff.body;
        }
    }
}

</script>

<style scoped>
.transport-information {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
}
</style>