<template>
  <div>
    <div class="header-info" @click="showRatesModel = true">
      <div>
        <div>{{ $t("lbl_check_in") }}</div>
        <div class="label-no-height">{{ search_data.rooms[activeRoomIndex || 0].checkIn | formatDate('MMM DD, YYYY') }}</div>
      </div>
      <div>
        <div>{{ $t("lbl_check_out") }}</div>
        <div class="label-no-height">{{ search_data.rooms[activeRoomIndex || 0].checkOut | formatDate('MMM DD, YYYY') }}</div>
      </div>
      <div v-if="current_step.key === 'rooms'">
        <div>{{ $t("lbl_guests") }}</div>
        <div class="flexed align-center ">
          <svg class="adult-icon">
            <use xlink:href="@/assets/adult_icon.svg#adultIcon"></use>
          </svg>
          <span class="m-r-5 label-no-height"> {{ adultsCount }} </span> |
          <svg class="child-icon">
            <use xlink:href="@/assets/child-02.svg#childIcon"></use>
          </svg>
          <span class="label-no-height">{{ childrenCount }}</span>
        </div>
      </div>
      <div v-if="current_step.key === 'information' || current_step.key === 'payment'" class="justify-self-end">
        <div>{{ $t("lbl_amount") }}</div>
        <div class="label-no-height">{{ grandTotal | currency }}</div>
      </div>
      <div class="icon m-l-10"><i class="el-icon-caret-bottom"></i></div>
    </div>
    <select-dates
      v-if="showRatesModel && current_step.key === 'rooms'"
      @close="showRatesModel = false"
      :roomIndex="activeRoomIndex"
      @getProfile="$emit('getProfile', $event)"
    />
    <summary-section
      v-if="showRatesModel && (current_step.key === 'information' || current_step.key === 'payment')"
      @closeSummarySection="showRatesModel = false"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import SelectDates from './SelectDates.vue'
import SummarySection from "../summary-section/SummaryDetails";

export default {
  components: {SummarySection, SelectDates},
  name: 'HeaderInfo',
  props:["activeRoomIndex"],
  computed: {
    ...mapState({
      search_data: state => state.search_data,
      mobileView: state => state.mobileView,
      current_step: state => state.current_step,
      selected_room_details: state => state.selected_room_details,
    }),
    adultsCount() {
      return this.search_data.rooms[this.activeRoomIndex || 0].persons.adults
      // return this.search_data.rooms.reduce((b, a) => b + a.persons.adults, 0)
    },
    childrenCount() {
      return this.search_data.rooms[this.activeRoomIndex || 0].persons.children
      // return this.search_data.rooms.reduce((b, a) => b + a.persons.children, 0)
    },
    grandTotal() {
      let result = 0;
      this.selected_room_details.forEach(element => {
        result += element.profileTaxes.charges.reduce((b, a) => b + a.net_value, 0)
      });

      return result
    },
  },
  data() {
    return {
      showRatesModel: false
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~css_vars';
.header-info {
  width: 100%;
  font-size: 14px;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 0.3fr;
  padding: 5px 6%;
  border-bottom: 1px solid #d4d4d4;

  svg.adult-icon, svg.child-icon {
    --color: red;
    height: 14px;
    width:21px;
    margin-right: 3px;
    fill: $hk-primary-color !important;
  }
  .icon {
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 1.7em;
    color: $hk-primary-color !important;
  }
}
</style>
