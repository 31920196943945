
const SET_MINIMUM_DATE = (state, payload) => {
  state.minimum_date = payload;
};

const SET_DISCOUNT_ERRORS = (state, payload) => {
  state.discount_errors = payload
};

export default {
  SET_MINIMUM_DATE,
  SET_DISCOUNT_ERRORS
}
