<template>
  <component :is="element" ref="element"
             title="Filters"
             :visible="showFilters"
             :before-close="closeFilters"
             direction="ttb"
             size="100%"
             :show-close="false"
             class="all-filters"
  >
    <div :class="{'flexed': !mobileView}" style="width: 100%;">
      <div class="filter-box">
        <div>{{ $t("lbl_display") }}</div>
        <div>
          <el-radio-group @change="onDisplayByChange()" v-model="displayBy" class="flexed-column m-t-10">
            <el-radio class="m-b-10" label="byRooms">{{ $t("lbl_view_by_rooms") }}</el-radio>
            <el-radio class="m-b-10" label="byRates">{{ $t("lbl_view_by_rates") }}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <el-divider v-if="!mobileView && getRoomBedTypes && getRoomBedTypes.length" direction="vertical"
                  class="vertical-divider"/>
      <div class="filter-box" v-if="getRoomBedTypes && getRoomBedTypes.length">
        <div>{{ $t("lbl_bed_type") }}</div>
        <div>
          <el-checkbox-group
            v-model="bedType"
            class="flexed-column m-t-10"
            @change="!mobileView ? onBedTypeChange() : ''">
            <el-checkbox
              class="m-b-10"
              v-for="(bType, index) in getRoomBedTypes"
              :label="bType.object_id"
              :value="bType.object_id"
              :key="index">{{translateValues(bType.object_type, bType.object_value, 'BED_TYPES', bType.object_id, 'object_value')}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <el-divider v-if="!mobileView" class="divider"/>
      <div :class="{'filter-grid': !mobileView}">
        <div class="filter-box grid-box" v-if="getRoomFeatures && getRoomFeatures.length">
          <div>{{ $t("lbl_features") }}</div>
          <div>
            <el-checkbox-group
              v-model="features"
              class="flexed-column m-t-10"
              @change="!mobileView ? onFeaturesChange() : ''">
              <el-checkbox
                class="m-b-10"
                v-for="(feature, index) in getRoomFeatures"
                :label="feature.object_id"
                :value="features.object_id"
                :key="index">{{ translateValues(feature.object_type, feature.object_value, 'AMENITY', feature.object_id, 'object_value')}}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <el-divider  direction="vertical" v-if="!mobileView && getRoomFeatures && getRoomFeatures.length"
                  class="vertical-divider"/>

        <div class="filter-box flexed-column grid-box" >
        <div>{{ $t("lbl_filter_by") }}</div>
        <div class="flexed justify-between align-center" style="min-height:41px;">
          <div class="flexed gap-10 align-center" >
            <el-checkbox v-model="bedCountChecked" @change="onBedCountCheckedChange(false, true)"> {{ $t('lbl_bed_count') }} </el-checkbox>
            </div>
            <el-input-number size="mini" v-if="bedCountChecked" v-model="bedCount" :min="1" :max="4" @change="!mobileView ? onBedCountChange() : ''">
            </el-input-number>
        </div>
         <el-checkbox class="m-b-10" v-model="smoking" @change="!mobileView ? onSmokingCange() : ''">{{ $t('lbl_smoking') }}</el-checkbox>
         <el-checkbox class="m-b-10" v-model="accessible" @change="!mobileView ? onAccessibleChange() : ''"> {{ $t('lbl_accessible') }} </el-checkbox>

      </div>

        <el-divider  direction="vertical" v-if="!mobileView && getUniqueRoomTypes?.length && !(getRoomFeatures && getRoomFeatures.length)"
                     class="vertical-divider"/>
        <div class="filter-box flexed-column grid-box" v-if="getUniqueRoomTypes?.length">
          <div class="m-b-10">{{ $t("lbl_room_types") }}</div>
          <el-checkbox-group
            v-model="roomTypes"
            @change="!mobileView ? onRoomTypeChange() : ''"
            class="flexed-column"
          >
            <el-checkbox
              v-for="room_type in getUniqueRoomTypes" class="m-b-10"
              :label="room_type.code"
              :value="room_type.code"
              :key="room_type.id"
            >
            {{ translateValues(room_type.code, room_type.name, 'ROOM_TYPE_CATEGORIES', room_type.id, 'name')  }}</el-checkbox>
          </el-checkbox-group>
        </div>
    </div>
    <el-divider class="divider" v-if="!mobileView"/>
    <div class="btn-section">
      <div class="flexed " :class="{'justify-end': !mobileView}" style="width: 100%;">
        <el-button v-if="mobileView" style="width: 100%" @click="closeFilters">Close</el-button>
        <el-button :style="mobileView? {width: '100%'}: ''" type="danger" @click="clear">{{ $t("lbl_clear") }}</el-button>
        <el-button :style="mobileView? {width: '100%'}: ''" type="primary" @click="apply">{{ $t("lbl_apply") }}</el-button>
      </div>
    </div>
  </component>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import deepClone from 'clone'
import { translateDynamicContent } from "@/services/utilities.service";


export default {
  name: "Filters",
  props: [
    'rateType',
    'showFilters',
    'roomIndex'
  ],
  beforeMount() {
    this.displayBy = this.allFilters[this.roomIndex]?.displayBy
    this.bedType = this.allFilters[this.roomIndex]?.bedType || []
    this.features = this.allFilters[this.roomIndex]?.features || []
    this.bedCount = this.allFilters[this.roomIndex]?.bedCount || 1
    this.smoking = this.allFilters[this.roomIndex]?.smoking || false
    this.accessible = this.allFilters[this.roomIndex]?.accessible || false
    this.bedCountChecked = this.allFilters[this.roomIndex]?.bedCountChecked || false
    this.roomTypes = this.allFilters[this.roomIndex]?.roomTypes || []
  },
  data() {
    return {
      drawerAttribute: {},
      displayBy: '',
      bedType: [],
      features: [],
      bedCountChecked: false,
      bedCount: 1,
      smoking: false,
      accessible: false,
      roomTypes: []
    }
  },
  watch: {
    getDisplayBy(value) {
      this.displayBy = value
    }
  },
  computed: {
    ...mapState({
      mobileView: state => state.mobileView,
      room_type_amenity_groups: state => state.room_type_amenity_groups,
      bed_type_groups: state => state.bed_type_groups,
      allFilters: state => state.allFilters,
      room_types: state => state.room_types,
      multi_lingual_dynamic_fields: state => state.multi_lingual_dynamic_fields,
      property_language_code: state => state.property.details.language
    }),
    element() {
      return this.mobileView ? 'el-drawer' : 'div';
    },
    getRoomFeatures() {
      return this.room_type_amenity_groups[this.roomIndex]
    },
    getRoomBedTypes() {
      return this.bed_type_groups[this.roomIndex]
    },
    getDisplayBy() {
      return this.allFilters[this.roomIndex].displayBy
    },
    getUniqueRoomTypes(){
      let roomTypes = deepClone(this.room_types[this.roomIndex])
      let unique = []
      roomTypes.forEach(rt => {
        if(rt.room_type_category && !unique.find(i => i.id === rt.room_type_category.id)){
          unique.push(rt.room_type_category)
        }
      })
      return unique
    }
  },
  methods: {
    ...mapMutations({
      SET_ALL_FILTERS: 'SET_ALL_FILTERS',
    }),
    apply() {
      this.applyClearFilters()
      this.$emit('applyFilters')
    },
    clear() {
      this.applyClearFilters(true)
      this.$emit('applyFilters')
    },
    applyClearFilters(clear){
      this.onBedTypeChange(clear)
      this.onFeaturesChange(clear)
      this.onBedCountCheckedChange(clear)
      this.onAccessibleChange(clear)
      this.onSmokingCange(clear)
      this.onRoomTypeChange(clear)
    },
    onDisplayByChange() {
      this.$emit('onDisplayByChange')
    },
    closeFilters() {
      this.$emit('closeFilters')
    },
    initializeAllFiltersObject() {
      let allFilters = deepClone(this.allFilters)
      if (!allFilters[this.roomIndex]) {
        allFilters[this.roomIndex] = {}
      }
      return allFilters
    },
    onBedTypeChange(clear) {
      let allFilters = this.initializeAllFiltersObject()
      if (clear) this.bedType = []
      allFilters[this.roomIndex].bedType = this.bedType
      this.SET_ALL_FILTERS(allFilters)
    },
    onFeaturesChange(clear) {
      let allFilters = this.initializeAllFiltersObject()
      if (clear) this.features = []
      allFilters[this.roomIndex].features = this.features
      this.SET_ALL_FILTERS(allFilters)
    },
    onBedCountCheckedChange(clear, clearBedCount) {
      let allFilters = this.initializeAllFiltersObject()
      if (clear) this.bedCountChecked = false
      allFilters[this.roomIndex].bedCountChecked = this.bedCountChecked
      this.SET_ALL_FILTERS(allFilters)
      this.onBedCountChange(clearBedCount)
    },
    onBedCountChange(clear) {
      let allFilters = this.initializeAllFiltersObject()
      if (clear) this.bedCount = 1
      allFilters[this.roomIndex].bedCount = this.bedCount
      this.SET_ALL_FILTERS(allFilters)
    },
    onAccessibleChange(clear) {
      let allFilters = this.initializeAllFiltersObject()
      if (clear) this.accessible = false
      allFilters[this.roomIndex].accessible = this.accessible
      this.SET_ALL_FILTERS(allFilters)
    },
    onSmokingCange(clear) {
      let allFilters = this.initializeAllFiltersObject()
      if (clear) this.smoking = false
      allFilters[this.roomIndex].smoking = this.smoking
      this.SET_ALL_FILTERS(allFilters)
    },
    onRoomTypeChange(clear){
      let allFilters = this.initializeAllFiltersObject()
      if (clear) this.roomTypes = []
      allFilters[this.roomIndex].roomTypes = this.roomTypes
      this.SET_ALL_FILTERS(allFilters)
    },
    translateValues(object_code, value, object_type, object_id, field_name) {
        return translateDynamicContent(this.multi_lingual_dynamic_fields, object_code, value, object_type, this.property_language_code, object_id, field_name)
    }

  },
}
</script>

<style scoped lang="scss">
@import "../../../../../assets/sass/config/mixins";
@import "../../../../../assets/sass/config/variables";

@include media('>=tablet') {
  .filters-wrapper {
    .filters-display {
      border: solid 1px #efeded;
      border-radius: 5px;
      // width: 70%;
      margin-left: 5%;
      padding: 7px;
      margin-top: 10px;
      background-color: whitesmoke;
      font-weight: 400;
      font-size: 14px;
      justify-content: end;
      align-items: center;

      .filter {
        font-weight: 300;

      }
    }
  }

  .all-filters {
    border: solid 1px #efeded;
    border-radius: 5px;

    padding: 10px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;


    .filter-grid{
      display: grid;
      grid-template-columns: 49% 2% 49%;
      row-gap: 15px;
    }
    .grid-box{
      width: 100% !important;
    }
    .filter-box {
      padding: 10px;
      background-color: whitesmoke;
      width: 49%;
      span{
        color: #606266;
        font-size: 14px;
      }
    }

  }

  .vertical-divider {
    height: unset !important;
  }

  .divider {
    margin: 15px 0;
  }
}

@include media('<=tablet') {
  ::v-deep .el-radio__label {
    font-size: 16px;
  }
  .filters-wrapper {
    .filters-display {
      border: solid 1px #efeded;
      border-radius: 5px;
      margin: 10px 6% 0;
      padding: 7px;
      background-color: whitesmoke;
      font-weight: 400;
      font-size: 14px;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: space-around;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .filter {
        font-weight: 300;

      }
    }
  }

  .all-filters {
    font-weight: 400;
    font-size: 18px;
    align-items: center;

    .filter-box {
      padding: 10px 20px;
      margin: 0 10px 10px;
      background-color: whitesmoke;
      width: 95%;
      border-radius: 5px;
    }
  }

  .btn-section {
    display: flex;
    padding: 15px;
    justify-content: flex-end;
    background-color: white;
    margin-top: 2px;
    box-shadow: 0 0 9px 5px #efeded;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}

</style>
