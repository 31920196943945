import Vue from 'vue'
import Axios from 'axios'
import store from './store'
import {i18n} from "./utils/i18n";
import api_to_handle_400 from "./plugins/api_to_handle_400";
import router from './routes/router';

let kickOut = setTimeout(() => {
  store.commit('SET_ERROR', {message: {data: i18n.t('lbl_session_expired_message')}, status: '900'})
}, parseFloat(process.env.VUE_APP_TIMEOUT) * 60000);

document.addEventListener("click", (evnt) => {
  clearTimeout(kickOut);
  kickOut = setTimeout(() => {
    store.commit('SET_ERROR', {message: {data: i18n.t('lbl_session_expired_message')}, status: '900'})
  }, parseFloat(process.env.VUE_APP_TIMEOUT) * 60000);
});

Axios.defaults.baseURL = `${process.env.VUE_APP_SERVER_ENV}${process.env.VUE_APP_BASE_URL}`;
Axios.defaults.headers.common.Accept = 'application/json';

Axios.interceptors.response.use((response) => {
    return response;
  },
  (error) => {
    const response = error.response;
    if (((response.status === 400 || response.status >= 500) && !api_to_handle_400.apis.some(item => response.config.url.includes(item)))
      && !router?.history?.current?.name?.includes('search-reservation')) {
      store.commit('SET_ERROR', {
        message: "Something went wrong. Please try again later",
        status: error.response.status
      });
    }
    return Promise.reject(error);
  });

// Bind Axios to Vue.
Vue.$http = Axios;
Object.defineProperty(Vue.prototype, '$http', {
  get() {
    return Axios;
  },
});
