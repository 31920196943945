<template>
  <div class="drawer-class">
    <el-drawer
      title="Select Dates"
      :visible="true"
      :before-close="close"
      direction="ttb"
      size="100%"
      :show-close="false">

      <div class="container">
        <!-- <DateFilter  style="height:25.62" :alwaysVisible='true' :searchData="searchData" :dontChangeState="true" @onDateSelected="onDateSelected"/>-->
        <DateFilter
          :alwaysVisible='true'
          :searchData="searchData"
          :dontChangeState="true"
          @onDateSelected="onDateSelected"/>
        <guests-filter
          style="margin-top: -1rem"
          :hideDropDown="true"
          :searchData="search_data"
          :roomIndex="roomIndex || 0"
          @guestsChanged="guestsChanged($event)"/>
      </div>
      <div class="footer">
        <div
          @click="close"
          class="flex_1 flexed align-center justify-center text-danger label-no-height">Close</div>
        <el-button
          type="primary"
          style="width:150px;"
          class="flex_1"
          @click="search">{{ $t("lbl_search") }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import DateFilter from "../component/filters/date-filter";
import GuestsFilter from '../component/filters/GuestsFilter.vue';
import deepClone from 'clone'

export default {
  components: {DateFilter, GuestsFilter},
  name: 'SelectDates',
  props:['roomIndex'],
  computed: {
    ...mapState({
      search_data: state => state.search_data,
      mobileView: state => state.mobileView
    }),
  },
  data() {
    return {
      searchData: {},
      guestPaylad: {}
    }
  },
  beforeMount() {
    this.searchData = deepClone(this.search_data.rooms[this.roomIndex || 0])
  },
  methods: {
    ...mapMutations({
      SET_SEARCH_DATA: 'SET_SEARCH_DATA',
    }),
    close() {
      this.$emit('close')
    },
    onDateSelected(payload) {
      this.searchData.checkIn = payload.checkInDate
      this.searchData.checkOut = payload.checkOutDate

    },
    guestsChanged(payload) {
      this.guestPaylad = payload
    },
    search() {
      let search_data = deepClone(this.search_data)
      search_data.rooms[this.roomIndex || 0].checkIn = this.searchData.checkIn
      search_data.rooms[this.roomIndex || 0].checkOut = this.searchData.checkOut
      if(this.guestPaylad?.guests?.persons)
      search_data.rooms[this.roomIndex || 0].persons = this.guestPaylad?.guests?.persons

      this.SET_SEARCH_DATA(search_data)
      let selectDates = {checkInDate: this.searchData.checkIn,
                          checkOutDate: this.searchData.checkOut}
      this.$emit('getProfile', selectDates)
      this.close()
    }
  },

}
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/config/variables";
@import "../../../assets/sass/config/mixins";

.drawer-class {

  ::v-deep .el-drawer__header {
    margin-bottom: 15px !important;
    margin-top: 0px !important;
    font-weight: bold;
    color: #2c3e50;
    font-size: 20px;

  }

  ::v-deep .el-drawer__body {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .container {
    padding: 0 10px;
    flex: 1;
    overflow: auto;
  }

  .footer {
    display: flex;
    padding: 0 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: flex-end;
    background-color: white;
    margin-top: 2px;
    box-shadow: 0 0 9px 5px rgba(152, 186, 194, 0.75);
  }

}

@include media('<=phone') {
  ::v-deep .vhd__datepicker__month {
    margin-top: -20px;
  }
}

::v-deep .vhd__datepicker__fullview {
  // height: auto;
}

</style>
