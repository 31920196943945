<template>
    <div class="payment">
        <div class="flexed align-center gap-10 m-b-10 pointer" style="max-width: 238px" @click="goTo('search-reservation-result')">
            <arrow-left-icon size="1.5x" class="custom-class"></arrow-left-icon>
            <span class="bold500">{{ $t("lbl_add_new_payment_method") }}</span>
        </div>
        <div class="flexed-wrap-between align-center bakground-grey bordered border-radius-5 p-20 m-b-10">
           <div>{{ $t("lbl_confirmation_number") }}</div>
           <span class="grey">{{searchReservationResult.conf_num}}</span>
        </div>
        <div class="payment-tab" v-loading="loading">
            <credit-card-component v-if="externalTokenizer" @updateCC="cardDetailsAdded($event)"
                        iFrameWidth="100%" iFrameMargin="0" :hideCardNumber="true"/>
            <span class="help is-danger" style="font-size: 15px; margin-top: 10px" v-if="creditCardRequired">{{ $t('lbl_credit_card_required') }}</span>
        </div>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import CreditCardComponent from '@/components/credit-card-component'
import { ArrowLeftIcon} from 'vue-feather-icons'
import ApiCalls from '@/services/api-calls.service.js'

export default {
  components: { CreditCardComponent, ArrowLeftIcon},
   async beforeMount () {
      if(!this.bookingProfile){
          this.loading = true
        try {
           await this.getBookingProfile({count: 1})
        } catch (error) {
            console.log(error)
        }
        this.loading = false
      }
  },
  data() {
    return {
        loading: false,
        loadingFirstTime: false,
        cards: [
        {
            logo_url: '@/assets/static/supported_cards/american_express.svg',
            name: 'American Express',
        }, {
            logo_url: '@/assets/static/supported_cards/visa.svg',
            name: 'Visa',
        }, {
            logo_url: '@/assets/static/supported_cards/diners_club.svg',
            name: 'Diners Club',
        }, {
            logo_url: '@/assets/static/supported_cards/jcb.svg',
            name: 'JCB',
        }, {
            logo_url: '@/assets/static/supported_cards/discover.svg',
            name: 'Discover',
        }, {
            logo_url: '@/assets/static/supported_cards/mastercard.svg',
            name: 'Mastercard',
        }],
        card: {
        card_number: null,
        expiration_month: null,
        expiration_year: null,
        card_type: null,
        },
        tokenizedCardDetails: null,
        creditCardRequired: false
    }
  },
  computed: {
    ...mapState({
      hotel: state => state.property.details,
      bookingProfile: state => state.profile,
      searchReservationResult: state => state.searchReservationResult
    }),
    externalTokenizer() {
      return process.env.VUE_APP_TOKENIZER_ENABLED === 'true' && this.hotel.website_config.app_id
    },
    card_required() {
      return false //this.bookingProfile?.restrictions?.booking_profile?.guarantee_required_to_book
    }
  },
  methods: {
    ...mapActions({
      getBookingProfile: 'getBookingProfile'
    }),
    ...mapMutations({
        SET_SEARCH_RESERVATION_RESULT: 'SET_SEARCH_RESERVATION_RESULT'
        // SET_BOOKING_PROFILE: 'SET_BOOKING_PROFILE'
    }),
    async makePayment() {
      if (this.tokenizedCardDetails) {

        let payload = {
            payment_type_code: this.bookingProfile.restrictions.booking_profile.booking_profiles_guarantee_types.find(b=>b.code == 'CC').payment_type.code,
            custom_field_1: this.tokenizedCardDetails.masked_card_number,
            custom_field_2: this.tokenizedCardDetails.card_brand,
            custom_field_4: this.tokenizedCardDetails.card_expire_month + "/" + this.tokenizedCardDetails.card_expire_year,
            custom_field_5: this.tokenizedCardDetails.token,
        }

        let url = 'web/add-guarantee',
            params = {request_id: this.searchReservationResult.request_token}

        this.loading = true
        try {
            await ApiCalls.post(url, payload, params, 'enterpriseUrl')
            params = {
                conf_num: this.searchReservationResult.conf_num,
                last_name: this.searchReservationResult?.reservation_details?.primary_guest?.last_name,
                property_code: this.hotel.code
            }
            let searchResult = (await ApiCalls.get('web/manage-booking', params, 'enterpriseUrl'))?.data
            this.SET_SEARCH_RESERVATION_RESULT({...searchResult, conf_num: params.conf_num});
            this.goTo('search-reservation-result')

        } catch (error) {
            console.log(error)
        }
        this.loading = false

      }
    },
    cardDetailsAdded(event) {
      if (event.status.toLowerCase() === 'success') {
        this.creditCardRequired = false;
        this.tokenizedCardDetails = event.ccDetails
        this.makePayment()
      }
    },
    goTo(name){
        this.$router.push({name: name}).catch(()=>{})
    }
  },
};

</script>

<style lang="scss" scoped>
@import '~mixins';
@import '~css_vars';

.payment{
    max-width: 500px;
}
.payment-tab {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  .label {
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin-bottom: 15px;
  }
}

.shared-card {
  font-size: 15px;
  width: 90%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #dcdfe6;
  display: flex;
  justify-content: space-between;
  margin-left: 4%;

  .card-type {
    display: flex;
    align-items: center;
    margin: 3px;
    padding: 8px;
    border-right: 1px solid #dcdfe6;

    img {
      width: 40px;
    }
  }

  .card-numbers {
    padding: 8px;
    display: flex;
    align-self: center;
  }

  .exp-date {
    padding: 8px;
    display: flex;
    align-self: center;
    border-left: 1px solid #dcdfe6;
  }

}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.pay-method {
  margin: 0 20px;

  p {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Lato';
    margin: 0;
  }

  .main-item {
    display: flex;
    justify-content: space-between;

    .pay-item {
      flex: 1;
      min-width: 150px;
      height: 100px;
      border: 1px solid #dedede;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;

      span {
        font-size: 1rem;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .check-icon {
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: $blue;
      }
    }

    .guest {
      margin: 10px 10px 20px 0;
    }

    .company {
      margin: 10px 0 20px 10px;
    }
  }
}

.company-message {
  font-size: 1rem;
  font-weight: 400;
  max-width: 480px;
  margin: 10px 20px;
}

</style>
