import { render, staticRenderFns } from "./EventsApp.vue?vue&type=template&id=7271b74a&scoped=true"
import script from "./EventsApp.vue?vue&type=script&lang=js"
export * from "./EventsApp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7271b74a",
  null
  
)

export default component.exports