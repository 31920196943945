<template>
  <div class="w-100 border-radius-5 p-20 m-b-10 form-controls" v-loading="loading">
    <span class="label-no-height title" style="">{{ $t("lbl_contact_details") }}</span>
    <fd-input
        v-if="displayField('first_name')"
        key="first_name"
        v-model="guest.first_name"
        v-validate="{ required: fieldRequired('first_name'), regex: addressRules.first_name_regex || notValidInput || ''}"
        :placeholder="$t('lbl_first_name')"
        :showAsterisk="fieldRequired('first_name')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="first_name"
        :error="vValidateErrorHandler('first_name')"
        class=""
        :disabled="disableFields('first_name')"
    />
    <fd-input
        v-if="displayField('last_name')"
        key="last_name"
        v-model="guest.last_name"
        v-validate="{ required: fieldRequired('last_name'), regex: addressRules.last_name_regex || notValidInput || ''}"
        :placeholder="$t('lbl_last_name')"
        :showAsterisk="fieldRequired('last_name')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="last_name"
        :error="vValidateErrorHandler('last_name')"
        class=""
        :disabled="disableFields('last_name')"
    />
    <fd-input
        v-if="displayField('middle_name')"
        key="middle_name"
        v-model="guest.middle_name"
        v-validate="{ required: fieldRequired('middle_name'), regex: notValidInput || ''}"
        :placeholder="$t('lbl_middle_name')"
        :showAsterisk="fieldRequired('middle_name')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="middle_name"
        :error="vValidateErrorHandler('middle_name')"
        class=""
        :disabled="disableFields('middle_name')"
    />
      <fd-input
        v-if="displayField('email')"
        key="email"
        v-model="guest.email"
        v-validate="{ required: fieldRequired('email'), regex: addressRules.email_regex || ''}"
        :placeholder="$t('lbl_email')"
        :showAsterisk="fieldRequired('email')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="email"
        :error="vValidateErrorHandler('email')"
        class=""
        :disabled="disableFields('email')"
    />
    <hk-phone-input :model.sync="guest.phone"
        v-if="displayField('phone')"
        ref="hkPhoneInput"
        :placeholder="$t('lbl_phone')"
        :showAsterisk="fieldRequired('phone')"
        key="phone"
        control-name="phone"
        autocomplete="new-password"
        v-validate="{ required: fieldRequired('phone') }"
        :error="errors.first('phone') && $t('lbl_required')"
        @blur="$validator.validate('phone')"
        @input="$validator.validate('phone')"
        :defaultCountryCode="dynamicCountryCode"
        :disabled="disableFields('phone')"
        classes="primary-phone" />

    <span class="label-no-height title" style="">{{ $t("lbl_address") }}</span>
      <fd-input
        v-if="displayField('street')"
        key="street"
        v-model="guest.street"
        v-validate="{ required: fieldRequired('street'), regex: addressRules.address_regex || notValidInput || ''}"
        :placeholder="$t('lbl_street')"
        :showAsterisk="fieldRequired('street')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="street"
        :error="vValidateErrorHandler('street')"
        class=" street"
        :disabled="disableFields('street')"
    />
    <fd-input
        v-if="displayField('street_2')"
        key="street2"
        v-model="guest.street_2"
        v-validate="{ required: fieldRequired('street_2'), regex: addressRules.street_2_regex || notValidInput || ''}"
        :placeholder="$t('lbl_street_2')"
        :showAsterisk="fieldRequired('street_2')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="street_2"
        :error="vValidateErrorHandler('street_2')"
        class=" street"
        :disabled="disableFields('street_2')"
    />
    <fd-input
        v-if="displayField('city')"
        key="city"
        v-model="guest.city"
        v-validate="{ required: fieldRequired('city'), regex: notValidInput || ''}"
        :placeholder="$t('lbl_city')"
        :showAsterisk="fieldRequired('city')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="city"
        :error="vValidateErrorHandler('city')"
        :disabled="disableFields('city')"
    />
    <fd-input
        v-if="displayField('state')"
        key="state_required"
        v-model="guest.state"
        v-validate="{ required: addressRulesFieldRequired('state_required'), regex: notValidInput || ''}"
        :placeholder="addressRules && addressRules.property_eligible_states && addressRules.property_eligible_states.length > 0 ? '' : $t('lbl_state')"
        :showAsterisk="addressRulesFieldRequired('state_required')"
        data-vv-validate-on="input|blur"
        border-radius-bottom-right="0"
        border-radius-top-right="0"
        border-radius-top-left="0"
        control-name="state_required"
        justify-content="space-between"
        :error="vValidateErrorHandler('state_required')"
        :disabled="disableFields('state')"
    >
    <span v-if="addressRules && addressRules.property_eligible_states && addressRules.property_eligible_states.length > 0" @click="$refs.selectState.focus()" class="drop-down-label">
        <span v-if="addressRulesFieldRequired('state_required')" class="asterisk">*</span> {{$t('lbl_state')}}
    </span>
    <el-select
      v-if="addressRules && addressRules.property_eligible_states && addressRules.property_eligible_states.length > 0"
      v-model="guest.state"
      filterable
      :placeholder="$t('lbl_select_state')"
      class="border-0 text-right"
      size="mini"
      ref='selectState'
      :disabled="disableFields('state')"
    >
      <el-option
        v-for="state in addressRules.property_eligible_states"
        :key="state.id"
        :label="state.state_code"
        :value="state.state_code"
      />
      <template slot="prefix" class="el-error" v-if="errors.has('state_required')"> <span class="el-error">{{ $t("lbl_required") }}</span> </template>
    </el-select>
  </fd-input>

  <fd-input
        v-if="displayField('zip_code')"
        key="zipcode_required"
        v-model="guest.zip_code"
        v-validate="{ required: addressRulesFieldRequired('zipcode_required'), regex: addressRules.zipcode_regex || notValidInput || ''}"
        :placeholder="$t('lbl_postal')"
        :showAsterisk="addressRulesFieldRequired('zipcode_required')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="zipcode_required"
        :error="vValidateErrorHandler('zipcode_required')"
        :disabled="disableFields('zip_code')"
        :loading="zip_loading"
        :show-button="true"
        @buttonClicked="callPostalApi()"
    >
  </fd-input>

    <fd-input
        v-if="displayField('country')"
        key="country"
        v-model="guest.country"
        v-validate="{ required: fieldRequired('country')}"
        :placeholder="$t('lbl_country')"
        :showAsterisk="fieldRequired('country')"
        border-radius-bottom-right="0"
        border-radius-bototm-left="0"
        :autocomplete="false"
        control-name="country"
        :error="vValidateErrorHandler('country')"
        :disabled="disableFields('country')"
        justify-content="space-between"
    >
    <span @click="$refs.selectCountry.focus()" class="drop-down-label">
        <span v-if="fieldRequired('country')" class="asterisk">*</span> {{$t('lbl_country')}}
    </span>
    <el-select
      v-model="guest.country"
      filterable
      :placeholder="'Select'"
      class="border-0 text-right"
      size="mini"
      ref='selectCountry'
      :disabled="disableFields('country')"
      @change="getAddressRules(guest.country)"
    >
      <el-option
            :key="_country.value"
            :value="_country.value"
            :label="_country.name"
            v-for="_country in countries">
          </el-option>
      <template slot="prefix" class="el-error" v-if="errors.has('country')"> <span class="el-error">{{ $t("lbl_required") }}</span> </template>
    </el-select>
    </fd-input>
    <template>
      <fd-text-area
          key="remarks"
          v-model="guest.remarks"
          v-validate="{ required: fieldRequired('remarks')}"
          :placeholder="$t('lbl_special_remarks_if_any')"
          :showAsterisk="fieldRequired('remarks')"
          border-radius-bottom-right="0"
          border-radius-bototm-left="0"
          :autocomplete="false"
          control-name="remarks"
          :error="vValidateErrorHandler('remarks')"
          class="street"
          :disabled="disableFields('remarks')"
          :maxlength="'1000'"
      >
    </fd-text-area>
    <span v-if="showMaxLengthMsg" class="max-remark-length-msg">{{ $t("lbl_max_char_allowed_are_1000") }}</span>
  </template>

  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {Countries} from "./countries";
import CommonServices from "@/services/common.services"
import deepClone from 'clone'
import HkPhoneInput from '@/components/hk-tel-input/HkPhoneInput.vue'
import FdInput from '@/components/FdInput.vue'
import FdTextArea from '@/components/FdTextArea.vue'

export default {
  name: "GuestForm",
  components: {HkPhoneInput, FdInput, FdTextArea},
  props: ['loyaltyUser'],
  mounted() {
    // this.guest.country = this.countries.find(c => c.value === this.property.country)?.name
    this.guest.country =  this.property.country
    this.getAddressRules()
  },
  created() {
    const storedForm = this.openStorage()
    if (storedForm) {
      this.guest = {
        ...this.guest,
        ...storedForm
      }
    }
    else if (this.editStayDetails && this.searchReservationResult?.reservation_details?.primary_guest?.last_name){
      this.guest = {
        ...this.guest,
        ...this.searchReservationResult.reservation_details.primary_guest
      }
      this.saveStorage(this.guest)
    }
  },
  data() {
    return {
      guests: [],
      guest: {
        first_name: '',
        last_name: '',
        middle_name: '',
        email: '',
        phone: '',
        street: '',
        street_2: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
        remarks: '',
      },
      remarks: '',
      stay_remarks: '',
      isValidPhoneNumber: false,
      countries: Countries,
      statesOptions: [],
      addressRules: {},
      disabledFields: [],
      loading: false,
      zip_loading: false,
      showMaxLengthMsg: false
    }
  },
  computed: {
    ...mapState({
      accessibility_mode: state => state.access_data,
      profile: state => state.profile,
      property: state => state.property.details,
      booking_data: state => state.booking_data,
      guest_data: state => state.guest_data,
      logged_in_user_details: state => state.logged_in_user_details,
      editStayDetails: state => state.editStayDetails,
      searchReservationResult:  state => state.searchReservationResult,
    }),
    mandatoryFields() {
      return this.profile.restrictions.booking_profile.required_guest_fields
    },

    dynamicCountryCode() {
      return this.guest?.country
    },
    notValidInput() {
      return '^([\\w -]){1,150}$'
    }

  },
  methods: {
    ...mapMutations({
      SET_GUEST_INFO: 'SET_GUEST_INFO',
      SET_BOOKING_DATA: 'SET_BOOKING_DATA',
      SET_GUEST_DATA: 'SET_GUEST_DATA',
    }),
    openStorage() {
      return deepClone(this.guest_data)
    },
    saveStorage(form) {
      form.loyaltyUser = this.loyaltyUser
      this.SET_GUEST_DATA(deepClone(form))
    },
    fieldRequired(field) {
      return this.mandatoryFields && this.mandatoryFields.includes(field)
    },
    displayField(field) {
      return this.addressRules?.display_fields?.includes(field)
    },
    disableFields(field) {
      return this.disabledFields.includes(field)
    },
    addressRulesFieldRequired(field) {
      return this.addressRules.hasOwnProperty(field) && this.addressRules[field]
    },
    fillUserData() {
      this.guest.first_name = this.logged_in_user_details.first_name  || null
      this.guest.last_name = this.logged_in_user_details.last_name  || null
      this.guest.middle_name = this.logged_in_user_details.middle_name  || null
      this.guest.email = this.logged_in_user_details.loyalty_guest_email[0].email  || null
      if(this.logged_in_user_details.loyalty_guest_phone[0].phone)
        this.guest.phone = this.logged_in_user_details.loyalty_guest_phone[0].phone
      this.guest.street = this.logged_in_user_details.loyalty_guest_address[0].address
      this.guest.city = this.logged_in_user_details.loyalty_guest_address[0].city  || null
      this.guest.state = this.logged_in_user_details.loyalty_guest_address[0].state || null
      this.guest.zip_code = this.logged_in_user_details.loyalty_guest_address[0].zip_code  || null
      this.guest.country = this.logged_in_user_details.loyalty_guest_address[0].country  || null
      this.guest.loyalty_guest_id = this.logged_in_user_details.account_number || null,
        this.guest.loyalty_guest_number = this.logged_in_user_details.account_number || null,
        this.guest.loyalty_program_id = this.logged_in_user_details.loyalty_program_id || null

      for (const guestKey in this.guest) {
        if (this.guest[guestKey]) {
          this.disabledFields.push(guestKey)
        }
      }
    },
    clearUserData() {
      this.guest.first_name = ''
      this.guest.last_name = ''
      this.guest.middle_name = ''
      this.guest.email = ''
      this.guest.phone = ''
      this.guest.street = ''
      this.guest.city = ''
      this.guest.state = ''
      this.guest.zip_code = ''
      this.guest.country = ''
      this.guest.loyalty_guest_id = null
      this.guest.loyalty_guest_number = null
      this.guest.loyalty_program_id = null
      this.guest.remarks = ''
      this.disabledFields = []
    },
    async validateForm() {
      let isPhoneValid = true
      if(this.guest.phone) if(this.$refs.hkPhoneInput) isPhoneValid = await this.$refs.hkPhoneInput.buildResults()
      if(isPhoneValid && (await this.$validator.validateAll())){
          this.$emit("is-valid", true);
          this.setBookingData()
      }
      else{
        this.$emit("is-valid", false);
          return false;
      }
    },
    setBookingData() {
      let data = deepClone(this.booking_data);
      data.sessions = []

      this.profile.sessions.forEach((item, index) => {
        let guest_data = {
          session_id: this.profile.sessions[index],
          guest_data: [this.prepareGuestPayloadData()],
          stay_remarks: this.remarks,
          remarks: this.guest.remarks,
        }
        data.sessions.push(guest_data);
      })

      this.SET_BOOKING_DATA(data);
      this.SET_GUEST_INFO(this.guest)
    },
    prepareGuestPayloadData(){
      return {
            first_name : this.guest.first_name || null,
            last_name: this.guest.last_name  || null,
            middle_name: this.guest.middle_name  || null,
            email: this.guest.email || null,
            phone: this.guest.phone || null,
            street: this.guest.street || null,
            street_2: this.guest.street_2 || null,
            city: this.guest.city  || null,
            state: this.guest.state  || null,
            zip_code: this.guest.zip_code  || null,
            country: this.guest.country  || null,
            loyalty_guest_id: this.guest.loyalty_guest_id || null,
            loyalty_guest_number: this.guest.loyalty_guest_number  || null,
            loyalty_program_id: this.guest.loyalty_program_id || null
      }
    },
    getAddressRules() {
      // this.statesOptions = []
      this.addressRules.zipcode_regex = ''
      this.addressRules.address_regex = ''

      const countryCode = this.countries.find(c => c.name === this.guest.country)?.value || this.guest.country

      let params = {
        country_code: countryCode,
        booking_flow: true
      }
      this.loading = true
      CommonServices.addressRules(params)
        .then((res) => {
          if (res.data) {
            // this.statesOptions = res.data.property_eligible_states;
            this.addressRules = res.data
          }
        })
        .catch((err) => {
          this.addressRules.property_eligible_states = []
          console.log(err);
        }).finally(()=> { this.loading = false})
    },
    vValidateErrorHandler(fieldName) {
        let message = null;
        if (this.errors?.has(fieldName)) {
            let feldRule = this.errors.items.find(item => item.field === fieldName);
            if (feldRule?.rule === 'required') message = this.$t('lbl_required');
            else if (feldRule?.rule === 'regex') message = this.$t('lbl_not_valid');

        }
        return message;
    },
    async callPostalApi() {
      if (!this.guest.zip_code) {
        this.$notify.info({
          title: this.$t('lbl_info'),
          message: this.$t('lbl_please_input_zip_code_before_searching'),
        });
        return;
      }

      try {
        this.zip_loading = true;
        const response = await CommonServices.searchZipCode({ zip_code: this.guest.zip_code, country: this.guest.country });
        let  parsedRes  = JSON.parse(response.data)
        let data = parsedRes?.[0]


        if (data.city && data.state) {
          this.guest.city = data.city
          this.guest.state = data.state
        }
        this.zip_loading = false;
      }
      catch (error) {
        this.zip_loading = false
        console.log(error)
      }
    }
  },
  beforeDestroy () {
    this.saveStorage(this.guest)
  },
  watch: {
    'guest.remarks'(newVal, oldVal) {
      if (newVal.length == 1000) {
        this.showMaxLengthMsg = true;
      } else {
        this.showMaxLengthMsg = false;
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '~css_vars';
@import "~mixins";
$gray: #b6b6b6;


.form-controls{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 10px;
  .street, .title{
      grid-column-start: 1;
      grid-column-end: -1;
  }
  .title{
    margin-bottom: -10px;
  }
  .border-0 {
      ::v-deep  input {
          border: none  !important;
          text-align: right;
          font-size: 16px;
      }
  }
    .el-error {
      height: 100%;
      display: flex;
      align-items: center;
      color: #F56C6C !important;
      font-size: 10px;
      margin-right: 5px;
  }
  .drop-down-label{
    color: $gray;
  }

}
@include media('<=600px') {
    .form-controls{
      grid-template-columns: 1fr;
    }
}
.asterisk{
    color: $hk-danger;
  }
.max-remark-length-msg {
  font-size: 14px;
  color: red;
  margin-top: -15px !important;
}
// .invalid-field {
//   border: 1px #F56C6C solid;
// }
</style>
