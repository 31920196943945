export default {
  propertyDetails: {},
  eventConfig: {},
  selectedEvent: {},
  eventTime: {},
  selected_packages: [],
  guest_data: null,
  summary_details: {},
  eventDetails: null
}
