<template>
    <div class="container">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </div>
</template>

<script>
    export default {
        name: "loading"
    }
</script>

<style lang="scss" scoped>
    @import '~css_vars';

    .container {
        position: absolute;
        top: 55%;
        left: 4 0%;
        transform: translate(-50%, -50%);
    }

    .dot {
        width: 10px;
        height: 10px;
        border: 2px solid $blue;
        border-radius: 50%;
        float: left;
        margin: 0 5px;
        transform: scale(0);
        animation: fx 1000ms ease infinite 0ms;
    }

    .dot:nth-child(2) {
        animation: fx 1000ms ease infinite 200ms;
    }

    .dot:nth-child(3) {
        animation: fx 1000ms ease infinite 400ms;
    }

    .dot:nth-child(4) {
        animation: fx 1000ms ease infinite 600ms;
    }

    @keyframes fx {
        50% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

</style>
