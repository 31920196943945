<template>
    <div>
        <div v-if="!mobileView && rateModalData.additional_details" style="padding-top: 6px; font-weight: 400;" class="description m-t-20">
            <strong>{{ $t("lbl_rate_plan_description") }}</strong>
            <div class="m-t-0 list-desc" >
                <div v-html="renderMarkdown(rateModalData.additional_details)"></div>
            </div>
        </div>

        <div v-if="!mobileView && rateModalData.package_description" style="padding-top: 6px; font-weight: 400;" class="description m-t-20">
            <strong>{{ $t("lbl_package_description") }}</strong>
            <div class="m-t-0 list-desc" >
              <div v-html="renderMarkdown(rateModalData.package_description)"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import {marked} from 'marked'

export default {
    name: 'RateDescriptionPopup',
    props: ['rateModalData'],
    computed: {
        ...mapState({
            mobileView: state => state.mobileView,
        })
    }, 
    methods: {
        renderMarkdown(markedText) {
            if (markedText) {
                return marked(markedText, { sanitize: true })
            }
            return '';
        },
    }

}
</script>

<style scoped>

:deep(.list-desc ul) {
    margin-bottom: 20px;
    margin-left: 30px !important;
    list-style: disc !important;
}

:deep(.description p) {
    margin-top: 0px;
}
</style>