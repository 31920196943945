import router from '@/routes/router'
export default {
  SET_SELECT_ROOM_LOADER(state, payload) {
    state.select_room_loading = payload;
  },
  SET_ERROR(state, payload) {
    state.api_fail = payload;
  },

  SET_MOBILE_VIEW(state, payload) {
    state.mobileView = payload
  },

  SET_APPLICABLE_PACKAGE(state, payload) {
    state.applicablePackage = payload;
  },

  SET_RATE_DETAILS(state, payload) {
    state.rate_details = payload;
  },

  SET_PACKAGES(state, payload) {
    state.packages = payload;
  },
  SET_ROOM_TYPES(state, payload) {
    state.room_types = payload;
  },

  SET_RATE_CODES(state, payload) {
    state.rate_codes = payload;
  },
  SET_ROOM_TYPE_AMENITY_GROUPS(state, payload) {
    state.room_type_amenity_groups = payload;
  },
  SET_BED_TYPE_GROUPS(state, payload) {
    state.bed_type_groups = payload;
  },

  SET_PACKAGE_CHARGE_TYPES(state, payload) {
    state.package_charge_types = payload;
  },

  SET_RWA_DATA(state, payload) {
    state.rwa_data = payload;
  },

  SET_RATE_CONTENT(state, payload) {
    state.rate_content = payload;
  },

  SET_STAY_DETAILS(state, payload) {
    state.stay_details = payload;
  },

  SET_SEARCH_DATA(state, payload) {
    state.search_data = payload;
  },

  SET_DISCOUNT(state, payload) {
    state.discount_applied = payload;
  },

  SET_GROUP_NO(state, payload) {
    state.group_no = payload;
  },

  SET_COMPANY_CODE(state, payload) {
    state.company_code = payload;
  },

  SELECTED_ROOM_DETAILS(state, payload) {
    state.selected_room_details = payload
  },

  SET_ALL_FILTERS(state, payload) {
    state.allFilters = payload
  },


  SET_LOGGED_IN_USER_DETAILS(state, payload) {
    state.logged_in_user_details = payload
  },

  SET_GUEST_DATA(state, payload) {
    state.guest_data = payload
  },

  SET_CONFIRMED_ROOMS_DATA(state, payload) {
    state.confirmed_rooms_data = payload
  },

  SET_SKIP_SEARCH(state, payload) {
    state.skip_search = payload
  },

  SET_SEARCH_RESERVATION_RESULT(state, payload) {
    state.searchReservationResult = payload
  },
  SET_EDIT_STAY_DETAILS(state, payload) {
    state.editStayDetails = payload
  },

  SET_STEPS(state, payload) {
    let _payload = JSON.parse(JSON.stringify(payload));
    let i = 0;
    for (let key in _payload) {
      _payload[key]['index'] = i;
      _payload[key]['key'] = key;
      i++;
    }
    state.steps = _payload;
  },

  SET_CURRENT_STEP(state, payload) {
    let currentStep = {...state.steps[payload]}
    state.steps[payload].visited = true;
    state.current_step = currentStep;
  },

  SET_CLICKED_RATES(state, payload) {
    state.clicked_rates = payload
  },
  SET_CLICKED_ROOMS(state, payload) {
    state.clicked_rooms = payload
  },
  SET_QUERY_PARAMS_INFO_STATE(state, payload) {
    state.queryParamsInfoState = payload
  },

  async CHANGE_STEP(state, payload) {
    if (state.booking_page_layout === 'HORIZONTAL') {
      window.scrollTo(0, 0);
    }
    let currentStep = {...state.steps[payload]}
    await router.push({name: currentStep.route_name}).catch(()=>{})
    state.steps[payload].visited = true;
    state.current_step = currentStep;
  },

  SET_RESERVATION_ADDONS(state, payload) {
    state.chosenAddon = payload;
  },

  SET_GUEST_INFO(state, payload) {
    state.guest_information = payload;
  },

  SET_BOOKING_DATA(state, payload) {
    state.booking_data = payload;
  },


  WIZARD_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_BOOKING_COLOR(state, payload) {
    state.booking_color = payload
  },

  SET_BOOKING_PROFILE(state, payload) {
    state.profile = payload;
  },

  SET_KEY_FROM_URL(state, payload) {
    state.keyFromUrl = payload;
  },

  SET_TOKEN_FROM_URL(state, payload) {
    state.tokenFromUrl = payload;
  },
  SET_CHILD_COMPONENT_KEY(state, payload) {
    state.childComponentKey++;
  },
  SET_MOP(state, payload) {
    state.mop = payload
  },

  SET_ENABLE_BOOK_WITH_POINTS(state, payload){
    state.enable_book_with_points = payload
  },
  SET_DISABLE_SEARCH_BUTTON(state, payload) {
    state.disableSearchButton = payload
  },

  RESET_STATE(state) {
    state.rate_content = []
    state.stay_details = null
    state.confirmed_rooms_data = null
    state.rate_details = []
    state.room_types = []
    state.rate_codes = []
    state.room_type_amenity_groups = []
    state.bed_type_groups = []
    state.rwa_data = null
    state.selected_room_details = []
    state.allFilters = []
    state.guest_data = null,
    state.booking_data = {sessions: []},
    state.group_no = '',
    state.booking_data = {sessions: []}
    state.group_no = ''
    state.company_code = ''
    state.mop = null
    state.enable_book_with_points = false
    state.clicked_rates = []
    state.clicked_rooms = []
    state.queryParamsInfoState = null

    },
  SHOW_PAYMENT_FRAME(state, payload){
    state.show_payment_frame = payload
  },
  SET_CHILDREN_AGES(state, payload) {
    state.children_ages = payload
  },
  SET_NUMBER_OF_ROOMS(state, payload) {
    state.number_of_rooms = payload
  },
  SET_COUNTRY_CODE(state, payload) {
    state.country_code = payload
  },
  SET_MULTI_LINGUAL_DYNAMIC_FIELDS(state, payload) {
    state.multi_lingual_dynamic_fields = payload
  },
  SET_LOCALIZATION_LANGUAGE(state, payload) {
    state.localization_language = payload
  },
  SET_LOCALIZATION_CURRENCY(state, payload) {
    state.localization_currency = payload
  },
  SET_TRANSPORTATION_INFORMATIONS(state, payload) {
    state.transportation_informations = payload
  }
}
