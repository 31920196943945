import * as CryptoJS from 'crypto-js'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import CurrencyData from '@/assets/currencies/currencies';

export const getCookie = (name) => {

    return document.cookie.split('; ').reduce((accumulator, currentValue) => {
        const parts = currentValue.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : accumulator
    }, null)
};

// only float numbers   
export const isNumber = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
    } else {
        return true;
    }
};

// only int numbers
export const isInt = (evt) => {
	evt = (evt) ? evt : window.event;

	const keyCode = [0, 8, 9, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
	const charCode = evt.which;

	if (!keyCode.includes(charCode)) {
		evt.preventDefault();
	} else {
		return true;
	}
};

// only float numbers with minus
export const isNumWithMinus = (evt) => {
	evt = (evt) ? evt : window.event;

	const keyCode = [0, 8, 9, 46, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
	const charCode = evt.which;

	if (!keyCode.includes(charCode)) {
		evt.preventDefault();
	} else {
		return true;
	}
};

// export const decimalNumbers = ($event, value) => {
//     let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
//
//     // only allow number and one dot
//
//     if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || value.indexOf('.') != -1)) {
//         $event.preventDefault();
//     }
//
//     // restrict to 2 decimal places
//     if (value != null && (String(value).split('.').length > 1) && (value.split('.')[1].length > 1)) {
//         $event.preventDefault();
//     }
// };
export const decimalNumbers = ($event, value, max, min) => {
    const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
    const newValue = value + $event.key;
  
    if (!isNaN(Number(max)) && newValue > Number(max)) {
      $event.preventDefault();
    }
  
    if (!isNaN(Number(max)) && newValue < Number(min)) {
      $event.preventDefault();
    }
  
    // only allow number and one dot
    if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || value?.indexOf('.') != -1)) {
      $event.preventDefault();
    }
  
    // restrict to 2 decimal places
    if (value != null && (String(value)
      .split('.').length > 1) && (value && value.split('.')[1].length > 1)) {
      $event.preventDefault();
    }
  };

export const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const generateShortUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-y'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const sortFunction = (arrayToSort, sortType, sortBy) => {

    if (!sortBy) {
        return arrayToSort.sort(function (a, b) {
            if (a < b) {
                return sortType === 'asc' ? -1 : 1;
            }
            if (a > b) {
                return sortType === 'asc' ? 1 : -1;
            }
            return 0;

        })
    }

    return arrayToSort.sort(function (a, b) {
        if (a[sortBy] < b[sortBy]) {
            return sortType === 'asc' ? -1 : 1;
        }
        if (a[sortBy] > b[sortBy]) {
            return sortType === 'asc' ? 1 : -1;
        }
        return 0;

    })
}

// export const getBase64FromRawImage = (blob) => {
//     return new Promise((resolve, reject) => {
//         let reader = new FileReader();
//         reader.readAsDataURL(blob);
//         reader.onloadend = function () {
//             resolve(reader.result);
//         };
//     });
// };
export const sortedObject = (array, key) => {
    return array.sort((a, b) => {
        let nameA = a[key].toUpperCase();
        let nameB = b[key].toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
};

export const sortedObjectDescending = (array, key) => {
    return array.sort((a, b) => {
        let nameA = a[key].toUpperCase();
        let nameB = b[key].toUpperCase();
        if (nameA > nameB) {
            return -1;
        }
        if (nameA < nameB) {
            return 1;
        }
        return 0;
    });
};
export const sortedAscending = (array, key) => {
    
    return array.sort((a, b) => {
        let nameA = a[key];
        let nameB = b[key];
        
        if (nameA < nameB) {
            return -1;
        }
        if (nameA < nameB) {
            return 1;
        }
        return 0;
    });
};


export const encodeBase64 = (code) => {
    try {
        var textString = code; // Utf8-encoded string
    var words = CryptoJS.enc.Utf8.parse(textString); // WordArray object
    var base64 = CryptoJS.enc.Base64.stringify(words); // string: 'SGVsbG8gd29ybGQ='
    // const encodedCode = code ? Base64.stringify(Utf8.parse(code)) : null;
    // return encodedCode;
    return base64
    } catch (error) {
        return code
    }
}
export const decodeBase64 = (code) => {
    try {
        var base64 = code;
        var words = CryptoJS.enc.Base64.parse(base64);
        var textString = CryptoJS.enc.Utf8.stringify(words); // 'Hello world'
        // const decodedCode =  code ? Utf8.stringify(Base64.parse(code)): null;
        // return decodedCode;
        return textString
    } catch (error) {
        return code
    }
    
}

export const formatDate = (value, arg1, withTime) => {
    dayjs.extend(utc)
    if (value) {
        let formater = arg1 ? arg1 : 'MMM DD, YYYY';
        if(withTime) {
            formater = `${formater} hh:mm a`
        }
        if(value.toUpperCase().includes('Z'))
            return dayjs(String(value)).utc().format(formater);
        return dayjs(String(value)).format(formater);
    }
}
export const  convertMinutesToTime = (num) =>
    { 
      var hours = Math.floor(num / 60);  
      var minutes = num % 60;
      return dayjs().year() + '-' + dayjs().month() + '-' + dayjs().day() + ' ' +  hours + ":" + minutes;         
    }
export const  convertTimeToMinutes = (time) =>
    { 
      return (dayjs(time).hour() * 60) + dayjs(time).minute();         
    }
 
    //convert Minutes to Date
export const convertMinutesToDate = (minutes) => {
    let hours = minutes / 60;
    let remainingMinutes = minutes % 60;
    let time = new Date();
    time.setHours(hours);
    time.setMinutes(remainingMinutes);
    return time;
}

// sort Month Date Year (eg. Jan 11, 2020, Jun 12, 2020 etc)
export const sortByMonthDateYear = (objArray) => {
  return objArray.sort((a, b) => {
    let as = a.date.split('T'),
    bs = b.date.split('T'),
    ad = new Date(as[0]),
    bd = new Date(bs[0]);
  return bd.getTime() - ad.getTime() ;
  })
}

export const convertFancyInt = (value) => {
    const num = +value;
    if(num - Math.floor(num) >= 0.01) {
        return Number(num.toFixed(2));
    }else {
      return Number(Math.floor(num));
    }
  }
export const currencySymbol = (code) =>{
    const  symbol = CurrencyData.find(item => item.code === code).symbol
    return symbol || code
}

export const sortFieldsWithActive = (objArray, field1, field2) => {
    let sortedGroup = objArray.sort((n1, n2) => {

        if (n1[field1].toLowerCase() < n2[field1].toLowerCase()) { return -1; }
        if (n1[field1].toLowerCase() > n2[field1].toLowerCase()) { return 1; }
      });
      objArray = sortedGroup;
  
       sortedGroup = objArray.sort((n1, n2) => {
  
        if (n1[field2]) { return -1; }
        if (n2[field2]) { return 1; }
      });
      objArray = sortedGroup;
  
      return objArray;
}

export const getBatches = (items, countPerBatch) => {
    const count = items.length / countPerBatch
    let batches = Math.ceil(count);
    return batches;
}

export const batchHandler = (data, batch, dataPerBatch) => {
    const startItem = (batch - 1) * dataPerBatch;
    const endItem = batch * dataPerBatch;
    const batchItems = data.slice(startItem, endItem);
    return batchItems;
}

export const arraySum = (items, prop) => {
    return items.reduce( function(a, b){
        return a + b[prop];
    }, 0);
}

export const isSpecialChar = (event) => {
    let regex = new RegExp('^[a-zA-Z0-9 ]+$');
    let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  export const translateDynamicContent = (data, object_code, value, object_type ,language_code, objectId, field_name, optional) => {
   
    let filteredRoomObject = data?.filter(obj => obj.language_code === language_code)?.find(obj =>  {
        if(object_code === "NO_OBJECT_CODE" && optional) {
            return  obj.object_type === object_type;
        }
        else if (optional) {
            return obj.object_code === object_code && obj.object_type === object_type;
        } else {
            return obj.object_code === object_code && obj.object_id === objectId && obj.object_type === object_type;
        }
    })


    let matchedFieldDetails = filteredRoomObject?.multilingual_dynamic_field_details?.find(trt => trt?.field_name === field_name)

    if(matchedFieldDetails && matchedFieldDetails.field_value !== '' && matchedFieldDetails.field_value !== null){
        return matchedFieldDetails.field_value?.trim();
    }

    return value?.trim();
};




