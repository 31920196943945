import axios from 'axios'
import store from '@/store'
export default {
	get(apiUrl, params, baseUrl) {
		let url = baseUrl === 'baseAppUrl' ? `${this.getBaseUrl(baseUrl)}${apiUrl}` : `${this.getBaseUrl(baseUrl)}/${apiUrl}`;
		return axios.get(url, {params});
	},
    post(apiUrl, payload, params, baseUrl) {
		let url = `${this.getBaseUrl(baseUrl)}/${apiUrl}`;
        return axios.post(url, payload || {}, {params});
    },
    put(apiUrl, payload, params, baseUrl) {
        let url = `${this.getBaseUrl(baseUrl)}/${apiUrl}`;
        return axios.put(url, payload || {}, {params});
    },
    getBaseUrl(baseUrl){
        const base_url = store.state.events?.propertyDetails?.region?.url

        const enterpriseUrl = `${base_url}${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}`;
        const propertyUrl = `${enterpriseUrl}/properties`;
        const base_core_url = `${base_url}${process.env.VUE_APP_CORE_API}/${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}`;
        const base_app_url = `${base_url}${process.env.VUE_APP_BASE_URL}`;
        const base_app_url_properties = `${base_url}${process.env.VUE_APP_BASE_URL}properties`;
        const event_management_url = `${base_url}event-management/${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}/properties`
        let url = ''
        switch (baseUrl) {
            case 'enterpriseUrl':
                url = enterpriseUrl
                break;
            case 'baseCoreUrl':
                url = base_core_url
                break;
            case 'baseAppUrl':
                url = base_app_url
                break;
          case 'baseAppUrlProperties':
                url = base_app_url_properties
                break;
          case 'eventManagementApi':
                url = event_management_url
                break;
          default:
              url = propertyUrl
              break;
        }
        return url
    },

    getHotelDetails(property_id) {
        let params= {}
        let base_url = process.env.VUE_APP_SERVER_ENV
        let url =  `${base_url}${process.env.VUE_APP_BASE_URL}properties/${property_id}/property-website-info`

        return axios.get(url, {params})
    },

    saveEventTest(payload){
      let url = `https://43ead1ba-d6f0-4f28-bf08-496190b0d09d.mock.pstmn.io/sub-events-setup`

      return axios.post(url, payload, {cache:false})
    },

    postTest(url, payload){
      return axios.post(url, payload, {cache:false})
    },
    getTest(url){
    return axios.get(url)
    },
     getTestWithBaseUrl(url){
      const base_url = store.state.events?.propertyDetails?.region?.url

      return axios.get(base_url+url, null)
    }

}
