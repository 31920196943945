<template>
    <div>
        <div class="modified-loader loader loading_whole" v-if="loading" style="height: 100vh">
            <loading></loading>
        </div>
        <router-view v-else></router-view>
    </div>
</template>

<script>
import CommonUtils from "@/utils";
import localizationKeys from "../../../localization_keys.json";
import { mapActions } from 'vuex';
import loading from '@/components/common/loading.vue';
    export default {
        name: 'ActivitiesApp',
        components: {
            loading,
        },
        methods: {
            ...mapActions({
                'getActivityDetails': 'activities/getActivityDetails',
                GET_LOCALIZATION_KEYS: 'configs/getLocalizationKeys',
            }),
            setLocalization(){
                let user_language = CommonUtils.getCookie('hk_language').substring(0, 2) || 'en'
                this.GET_LOCALIZATION_KEYS().then(locRes => {
                this.$i18n.locale = user_language
                this.$i18n.setLocaleMessage(user_language, locRes?.data);
                }).catch(() => {
                this.SET_ERROR(false)
                this.$i18n.locale = user_language
                this.$i18n.setLocaleMessage(user_language, localizationKeys);
                }).finally(() => {
                  this.loading = false
                })
            }
        },
        async beforeMount () {
          const routeQueries = this.$route.query;
          this.loading = true
          try {
            await this.getActivityDetails({property_id: routeQueries.property_id})
          } catch (error) {
            console.log({error})
            this.loading = false
          }

          try{
            this.setLocalization()
          }
          catch(error) {
            this.loading = false
          }
        },
        data() {
            return {
                loading: false
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
