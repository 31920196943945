<template>
  <div>
    <div v-for="(activity, index) in activitiesWithTemplates" :key="index">
      <ActivityItem
        :name="activity.name"
        :primary_description="activity.description"
        :description="activity.template.description"
        :price="activity.activity_price.base_price"
        :image="activity.template?.images[0]?.image_url"
        :images="activity.template?.images"
        :id="activity?.id"
        :capacity_left="activity?.capacity_left"
        :from_time="activity?.from_time"
        :to_time="activity?.to_time"
        :selectedDate="selectedDate"
        @bookActivity="book"
      ></ActivityItem>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import CommonUtils from "@/utils";
import ActivityItem from './ActivityItem.vue';
export default {
  name:'ActivityList',
  props:['activities', 'selectedDate'],
  components:{
    ActivityItem
  },
  computed:{
    receivedActivities() {
      const activities = this.activities?.dates?.find(dateObj =>
         dateObj.date.split("T")[0] === this.selectedDate
      )
        ?.activities?.filter(activity => activity.capacity_left > 0) || [];
      return CommonUtils.sortFunction(activities,'asc','from_time')
    },
    activitiesWithTemplates() {
      return this.receivedActivities.map(activity => {
        const template = this.activities?.activity_templates?.find(
          t => t.id === activity.activity_template_id
        );
        return {
          ...activity,
          template
        };
      });
    }
  },
  methods:{
    formattedDate(date){
      return dayjs(date).format("MMM dd, YYYY")
    },
    book(details) {
      this.$emit('bookActivity', details)
    }
  }
}
</script>

<style>

</style>
