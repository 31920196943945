export default {
  SET_PROPERTY_DETAILS(state, payload) {
    state.propertyDetails = payload;
  },
  SET_CURRENCY(state, payload){
    state.propertyDetails.currency = payload
  },
  SET_ACTIVITIES(state, payload) {
    state.activities = payload;
  },
  SET_ACTIVITY_DETAILS(state, payload) {
    state.activityDetails = payload;
  },
  SET_PRICE_DETAILS(state, payload) {
    state.priceDetails = payload
  },
  SET_START_ACTIVITY_MAP(state, payload) {
    state.startActivityMap = payload
  },
  SET_PAYMENT_TYPES(state, payload) {
    state.paymentTypes = payload
  },
  SET_CONFIRMATION_DATA(state, payload) {
    state.confirmationData = payload
  },
  SET_GUEST_DATA(state, payload) {
    state.guestData = payload
  }
}