
const SET_HOTEL_DETAILS = (state, payload) => {
  state.details = payload;
};

const SET_AMENITIES = (state, payload) => {
  state.amenities = payload;
};

const SET_PROPERTY_CURRENCY = (state, payload) => {
  state.property_currency = payload
}
export default {
  SET_HOTEL_DETAILS,
  SET_AMENITIES,
  SET_PROPERTY_CURRENCY
}
