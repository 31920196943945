
import actions from './actions'
import mutations from './mutations'

const state = {
  app_config: null,
  origin: null,
  minimum_date: null,
  discount_errors: [],
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters: {
  }
};
