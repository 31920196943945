import {Validator} from 'vee-validate';
import {parsePhoneNumberFromString} from 'libphonenumber-js'
import dayjs from "dayjs";

Validator.extend('phone', {
    getMessage: () => '',
    validate: value => {
        let number = parsePhoneNumberFromString(value)

        return !!number && number.isValid()
    }
});
