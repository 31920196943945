<template>
  <div class="fd-form-group" v-bind="$attrs" :style="{
    'border-top-right-radius': borderRadiusTopRight,
    'border-bottom-right-radius':borderRadiusBottomRight,
    'border-top-left-radius':borderRadiusTopLeft,
    'border-bottom-left-radius':borderRadiusBottomLeft,
    'border-right-width':borderRightWidth,
    'border-left-width':borderLeftWidth,
    'border-top-width':borderTopWidth,
    'border-bottom-width':borderBottomWidth,
    'justify-content': justifyContent,
    'border-color': borderColor,
    'padding': padding

  }" :class="{'is-disabled':disabled}">
    <slot>
      <!--        Dummy input in order to disable autocomplete-->
      <input tabindex="-1" style="position: absolute; height: 0; width: 0; z-index: -1; border: 0"
             :value="value"/>

      <textarea :type="type"
             v-disabled="disabled"
             class="form-field"
             :disabled="disabled"
             autocomplete="new-password"
             :placeholder="placeholder"
             :name="controlName"
             :id="controlName"
             :value="value"
             :style="inputStyle"
             :maxlength="maxlength"
             :class="{'is-danger': error}"
             v-on="inputListeners"
             @keypress.enter="click"
             @keydown.tab="$emit('tabPress')"/>

      <!--v-on:input="updateValue($event.target.value)"-->
      <label :for="controlName" class="form-label" :class="{'is-disabled':disabled}">
        <span v-if="showAsterisk" class="asterisk">*</span> {{ placeholder }}
      </label>
      <span v-if="error" class="required" :style="requiredStyle">{{ error }}</span>
      <el-button v-if="showButton" type="default" class="postfix" :style="buttonStyle"
                 @click="click" size="mini" id="search-button">
        <i class="el-icon-loading" v-if="loading"></i>
        <span v-if="buttonText && !loading">
            {{ buttonText }}
          </span>
        <i :class="buttonClassName" v-if="!buttonText && !loading"></i>
      </el-button>
    </slot>

  </div>
</template>

<script>
export default {
  name: "FdInput",
  $_veeValidate: {
    // must not be arrow functions.
    // the name getter
    name() {
      return this.controlName;
    },
    // the value getter
    value() {
      return this.value; // `this` is the component instance, `value` is the prop we added.
    }
  },
  props: {
    value: {
      type: String
    },
    error: {
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: String,
    },
    required: {
      type: Boolean,
      required: false,
      default: true
    },
    type: {
      type: String,
      default: 'text',
      validate: (val) => {
        // we only cover these types in our input
        return ['text', 'url', 'email', 'password', 'search'].indexOf(val) !== -1;
      }
    },
    isComponent: {
      type: String,
      default: 'input'
    },
    autocomplete: {
      required: false,
      default: false
    },
    inputStyle: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    buttonStyle: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    placeholder: {
      required: false,
      type: String,
      default: 'Write something'
    },
    controlName: {
      required: false,
      type: String,
      default: 'controlName'
    },
    borderRadiusTopRight: {
      required: false,
      type: String,
      default: '3px'
    },
    borderRadiusBottomRight: {
      required: false,
      type: String,
      default: '3px'
    },
    borderRadiusTopLeft: {
      required: false,
      type: String,
      default: '3px'
    },
    borderRadiusBottomLeft: {
      required: false,
      type: String,
      default: '3px'
    },
    borderRightWidth: {
      required: false,
      type: String,
      default: '1px'
    },
    borderLeftWidth: {
      required: false,
      type: String,
      default: '1px'
    },
    borderTopWidth: {
      required: false,
      type: String,
      default: '1px'
    },
    borderBottomWidth: {
      required: false,
      type: String,
      default: '1px'
    },
    justifyContent: {
      required: false,
      type: String,
      default: 'flex-start'
    },
    showButton: {
      required: false,
      type: Boolean,
      default: false
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    },
    buttonText: {
      required: false,
      default: null
    },
    borderColor: {
      required: false,
      default: '#e6e6e6'
    },
    padding: {
      required: false,
      default: ''
    },
    buttonClassName: {
      required: false,
      default: 'el-icon-search'
    },
    showAsterisk: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputListeners() {
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          blur: event => {
            this.$emit('blur', event);
          },
          input: event => {
            //vm.$emit('input', event.target.value)
            this.$emit('input', event.target.value)
          },
          onfocus: event => {
            this.$emit('focus', event)
          },
          keyup: event => {
            this.$emit('keyup', event)
          }
        }
      )
    },
    unique() {
      return this.autocomplete ? "on" : new Date().getTime();
    },
    requiredStyle() {
      return {
        marginRight: this.showButton ? '43px' : 'unset'
      }
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    },
    click() {
      this.$emit('buttonClicked')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~css_vars";

$primary: var(--primary-color);
$white: #fff;
$gray: #b6b6b6;
$default_input_color: #212121;

.is-disabled {
  cursor: not-allowed !important;
  color: lightgray !important;
}

.fd-form-group {
  font-family: "Lato", sans-serif;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: #e6e6e6;
  border-radius: 3px;
  padding: 7px 10px;
  display: flex;
  align-items: center;

  &:focus-within {
    border-color: #e6e6e6;

    .form-field {
      color: $default_input_color
    }
  }

  .required {
    color: $hk-danger;
    font-size: x-small;
    white-space: nowrap;
  }

  .postfix {
    position: absolute;
    right: 0;
    bottom: 1px;
    top: 0;
    height: 42px;
    border: none;
    border-radius: 0;
    border-left: 1px solid #e6e6e6;
    background-color: #e6e6e6;
    color: #409EFF;
  }
}

.form-field {
  font-family: inherit;
  width: 100%;
  border: 0;
  /*border-bottom: 2px solid $gray;*/
  outline: 0;
  font-size: 1.0rem;
  color: $default_input_color;
  padding: 0;
  margin-top: 10px;

  background: transparent;
  /*transition: border-color 0.2s;*/

  &:focus {
    color: $primary;
  }

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form-label {
    font-size: 1rem;
    cursor: text;
    top: 8px;
    padding-bottom: 5px;

    @media (max-width: 1165px) {
      font-size: .7rem;
      top: 12px;
    }

    @media (min-width: 1165px) and (max-width: 1315px) {
      font-size: .8rem;
      top: 10px;
    }
  }
}

.form-label {
  position: absolute;
  pointer-events: none;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 0.8rem;
  color: $gray;
  padding: 0 0 5px 0;
  .asterisk{
    color: $hk-danger;
  }
}

.form-field:focus {
  ~ .form-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 0.8rem;
    color: $primary;
    font-weight: 400;
    padding: 0 0 5px 0;
    
  }

  font-weight: 400;
}



</style>
