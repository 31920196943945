<template>
  <div>
    <header-info v-if="mobileView"/>
    <div class="guest-information">
      <div class="title m-t-10 m-b-10">{{ $t("lbl_guest_information") }}</div>
      <!-- <div class="loyalty-user" v-if="showLoyaltyCheckBox">
        <el-checkbox :label="$t('lbl_use_loyalty_guest_profile')" @change="loyaltyUserChange" v-model="loyaltyUser"></el-checkbox>
      </div> -->
      <div class="guest-form">
        <GuestForm
          ref="guestForm"
          :loyaltyUser="loyaltyUser"
          @is-valid="formValidation = $event"/>
      </div>
      <transport-information v-if="showTravelTransport"  ref="transportInformation" @is-valid="transportValidation = $event"></transport-information>
      <div class="m-t-20 title">{{ $t('lbl_terms_and_conditions') }} </div>
      <el-tabs v-model="activeRoomIndex" @tab-click="handleTermsAndConditionsClick" v-if="selected_room_details.length >1">
          <el-tab-pane :label="`${$t('lbl_room')}` + (index+1)" :name="index.toString()" v-for="(room,index) in selected_room_details" :key="index">
            <policy-box :room="selected_room_details[index]"/>
            <cancellation-policy :room="selected_room_details[index]" />
          <deposit-policy :room="selected_room_details[index]"/>
          </el-tab-pane>
      </el-tabs>
      <div v-else class="m-t-10">
          <policy-box :room="selected_room_details[0]"/>
          <cancellation-policy :room="selected_room_details[0]"/>
          <deposit-policy :room="selected_room_details[0]"/>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import GuestForm from "../../booking-v2/information/GuestForm";

import HeaderInfo from '../mobile/HeaderInfo.vue';
import PolicyBox from './PolicyBox.vue';
import CancellationPolicy from "./CancellationPolicy";
import DepositPolicy from './DepositPolicy.vue';
import TransportInformation from './TransportInformation.vue';
import dayJs from "dayjs";


export default {
  name: "Information",
  components: {CancellationPolicy, GuestForm, HeaderInfo, PolicyBox, DepositPolicy, TransportInformation},
  data() {
    return {
      formValidation: false,
      transportValidation: false,
      loyaltyUser: '',
      activeRoomIndex: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.showLoyaltyCheckBox && this.guest_data && this.guest_data.hasOwnProperty('loyaltyUser')) this.loyaltyUser = this.guest_data.loyaltyUser
      else if (this.showLoyaltyCheckBox) {
        this.loyaltyUser = true
        this.$refs.guestForm.fillUserData()
      }
    });

    // if(window.dataLayer && typeof window.gtag === 'function') {
    //   let roomItems = []
    //   this.selected_room_details?.forEach((room, index) => {
    //     roomItems.push({
    //         item_id: room?.room_type_code, // Room type code 
    //         item_name: room?.room_type_name, // Room type name 
    //         affiliation: "", // empty 
    //         coupon: this.discount_applied?.name ? this.discount_applied?.name : "",// Promo code if applied 
    //         discount: 0, // This can be 0
    //         index: index, // Order of room type
    //         item_brand: "", // empty 
    //         item_category: "", // empty 
    //         item_category2: "", // empty 
    //         item_category3: "", // empty 
    //         item_category4: "", // empty 
    //         item_category5: "", // empty 
    //         item_list_id: "", // empty 
    //         item_list_name: "", // empty
    //         item_variant: "", // Rate code code 
    //         location_id: null, // null
    //         price: room?.rate_details?.[0]?.amount,// total room rate for that room type and rate code  
    //         quantity: this.getNights // number of nights 
    //     })
    //   })

    //   let gtagPayload =  {
    //     item_list_id: this.property?.code, // property code 
    //     item_list_name: this.property?.name, // Property name 
    //     items: roomItems
    //   }

    //   gtag("event", "begin_checkout", gtagPayload);
    //   console.log('Event: begin_checkout', gtagPayload)
    // }

  },
  beforeMount () {
    this.activeRoomIndex = "0"
  },
  methods: {
    validateGuestForm() {
      return this.$refs.guestForm.validateForm()
    },
    validateTransportForm() {
      return this.$refs.transportInformation.validateForm()
    },
    loyaltyUserChange() {
      if (this.loyaltyUser) {
        this.$refs.guestForm.fillUserData()
      } else {
        this.$refs.guestForm.clearUserData()
      }
    },
    handleTermsAndConditionsClick(){

    }
  },
  computed: {
    ...mapState({
      mobileView: state => state.mobileView,
      logged_in_user_details: state => state.logged_in_user_details,
      guest_data: state => state.guest_data,
      selected_room_details: state => state.selected_room_details,
      enable_travel_transport_flow: state => state.property.details.website_config?.enable_travel_transport_flow,
      discount_applied: state => state.discount_applied,
      search_data: state => state.search_data,
      property: state => state.property.details
    }),
    showLoyaltyCheckBox() {
      return this.logged_in_user_details && this.logged_in_user_details.first_name
    },
    showTravelTransport() {
      return this.enable_travel_transport_flow !== false && this.enable_travel_transport_flow !== "false"
    },
    getNights(){
        return dayJs(this.search_data.checkOut).utc().diff(dayJs(this.search_data.checkIn).utc(), 'days')
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../../assets/sass/config/mixins";
@import "../../../assets/sass/config/variables";

.guest-information {
  .loyalty-user {
    border: solid #dadada 1px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  margin: 15px;
  margin-top: 0 !important;

  .title {
    font-size: 16px;
    font-weight: bold;
    color: #4E4E4E;
  }

  .guest-form {
    border: solid #dadada 1px;
    padding: 10px;
    display: flex;
    border-radius: 5px;
  }
}

</style>
